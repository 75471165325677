import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { View, Platform } from 'react-native';
import { IMfCollapsible } from './i_mf_collapsible';




class MfCollapsible extends Component<IMfCollapsible> {
    render() {
        return (
            <View>
                {!this.props.collapsed ?
                this.props.children : null
                }
            </View>
            
        )
    }
}

export default MfCollapsible

