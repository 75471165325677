import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { Animated } from 'react-native';

interface IAnimatedScale {
    from: number,
    to: number,
    end: number
    duration: number,
    delay: number,
    onAnimationEnd: Function
}

class AnimatedScale extends Component<IAnimatedScale> {
    state = {
        scaleAnim: new Animated.Value(this.props.from)
    }
    componentDidMount() {
        this.animate()
    }

    animate() {
        setTimeout(() => {
            this.animateScale()
        }, this.props.delay)
        
    }

    animateScale() {
        const {to, end, duration} = this.props
        Animated.sequence([
            Animated.timing(this.state.scaleAnim,{
                toValue: to,
                duration: duration
            }),
            Animated.timing(this.state.scaleAnim,{
                toValue: end,
                duration: duration
            }),
        ]).start( () => this.props.onAnimationEnd())
    }

    render() {
        return(
            <Animated.View style={{
                transform: [
                    {scale: this.state.scaleAnim}
                ]
            }}>
                {this.props.children}
            </Animated.View>
        )
    }
}

export default AnimatedScale
