const GREEN = '#93ba1f'
const RED = '#ff5e36'
const BLUE = '#00b9e2'
const ORANGE = '#ee7000'
const PINK = '#e6007e'
const GREY = '#d1d1d1'
const LIGHT_GREY = '#f2f2f2'
const YELLOW = 'yellow'

export const PRIMARY_THEME_COLORS = {
    background: GREEN,
    inventory: '#06a4e0',
    favorites: '#dc6400',
    shoppingList: '#cc4132',
    navBar: GREEN,
    recipes: '#8cb414',
    border: '#747269',
    shadowColor: 'grey',
    backgroundLight: 'white',
    variation: '#c80078',
    grey: GREY,
    lightGrey: '#e6e6e6',
    tips: '#ffd700',
    red: '#c62f14',
    green: '#546e26',
    ligthRed: '#D16A5F',
    lightBlue: '#48B8E3',
    lighterGreen: '#90C983'

}

export const SECONDARY_THEME_COLORS = {
    lightGreen: '#e2f5ef'
}

export const TEXT_COLORS = {
    header: 'black',
    normal: 'black',
    light: 'white',
    grey: 'grey'
}




 