import React, { useState, useEffect } from 'react'
import {Component} from 'react'
import styles from './styles'
import { IState, ICategoryState, IRecipeState, IIngredientState } from '../../model/state'
import { Dispatch } from 'redux'
import { setPreloadedRecipeImages, setPreloadedCategoryImages, setShowDownloadQuestion } from '../../redux/actions'
import { connect } from 'react-redux'
import { View } from 'react-native'
import DownloadModal from './download_modal'
import { preLoadAssetBatch } from '../../helpers/assets'
//import * as Network from 'expo-network'

interface ReduxProps {
    categories: ICategoryState,
    recipes: IRecipeState,
    ingredients: IIngredientState,
    hasCategories: boolean,
    hasRecipes: boolean,
    hasIngredients: boolean,
    hasNutrition: boolean,
    hasMenu: boolean
}

interface DispatchProps {
    setPreloadedRecipeImages: (b: boolean) => void,
    setPreloadedCategoryImages: (b: boolean) => void
}

interface ReactProps {
    visible: boolean,
    onClose: () => void,
    onDownloadSuccess: () => void,
    onDownloadError?: () => void,
    onDeny: () => void
}

type Props = ReduxProps & DispatchProps & ReactProps

const DownloadContainer = ({
    categories, 
    recipes,
    ingredients,
    setPreloadedCategoryImages, 
    setPreloadedRecipeImages,
    visible,
    onDeny,
    onDownloadError,
    onDownloadSuccess,
    onClose,
    hasCategories,
    hasRecipes,
    hasIngredients,
    hasNutrition,
    hasMenu
    }:Props) => {

    // const [isDownloaded, setIsDownloaded] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [downloadFinished, setDownloadFinished] = useState(false)
    const [errorDownload, setErrorDownload] = useState(false)

    // const checkDownloadStatus = () => {
    //     let isDownloaded = true
    //     if (categories.categories.length === 0) isDownloaded = false
    //     if (recipes.recipes.length === 0) isDownloaded = false
    //     if (ingredients.ingredients.length === 0) isDownloaded = false
    //     if (!categories.preLoadedImages) isDownloaded = false
    //     if (!recipes.preLoadedImages) isDownloaded = false
    //     return isDownloaded
    // }

    const preLoadCategoryAssets = async (categories: ICategoryState) => {
            const categoryImages : string[] = []
            const {featuredCategories} = categories
            featuredCategories.forEach(cat => {
                categoryImages.push(cat.categoryImage)
            })
            const preCat = await preLoadAssetBatch(categoryImages)
            if (preCat.length > 0) {
                setPreloadedCategoryImages(true)
                return true
            } else {
                return false
            }
    }

    const preLoadRecipeAssets = async (recipeState: IRecipeState) => {
            const {recipes} = recipeState
            const recipeImages : string[] = []
            recipes.forEach(rec => {
                if (rec.smallImage != "" && rec.smallImage != undefined && rec.smallImage != null) recipeImages.push(rec.smallImage)
                if (rec.largeImage != "" && rec.largeImage != undefined && rec.largeImage != null) recipeImages.push(rec.largeImage)
            })
            const preRec = await preLoadAssetBatch(recipeImages)
            if (preRec.length > 0) {
                setPreloadedRecipeImages(true)
                return true
            } else {
                return false
            }
    }

    // const checkInternetConnection = async () => {
    //     const networkState = await Network.getNetworkStateAsync()
    //     console.log('is internet reachable: '+networkState.isInternetReachable)
    //     console.log(networkState)
    //     return networkState.isInternetReachable
    // }

    useEffect(() => {
        tryDownload()
    }, [hasCategories, hasRecipes, hasIngredients, hasNutrition, hasMenu, downloading])

    const onDownload = () => {
        setErrorDownload(false)
        setDownloading(true)
    }

    const tryDownload = async () => {
        if (!hasRecipes || !hasCategories || !hasIngredients || !hasNutrition || !hasMenu || !downloading) return
        const preCat = await preLoadCategoryAssets(categories)
        const preRec = await preLoadRecipeAssets(recipes)
        if (preCat && preRec) {
            setDownloadFinished(true)
            onDownloadSuccess()
        } else {
            setErrorDownload(true)
            if (onDownloadError) onDownloadError()
        }
        setDownloading(false)

    }

    const onDownloadFail = () => {
        setErrorDownload(true)
    }

    const onDenyDownload = () => {
        onDeny()
    }

    const onCloseDownloadModal = () => {
        onClose()
    }

    return (
        <View>
            <DownloadModal
            onAccept={() => onDownload()}
            onDeny={() => onDenyDownload()}
            downloading={downloading} 
            error={errorDownload}
            downloadFinished={downloadFinished}
            onClose={() => onCloseDownloadModal()}
            visible={visible}/>

        </View>
    )
}

const mapStateToProps = (state: IState) => {
    return { 
        categories: state.categoryState, 
        recipes: state.recipeState,
        ingredients: state.ingredientState,
        hasCategories: state.categoryState.dataLoaded,
        hasRecipes: state.recipeState.dataLoaded,
        hasIngredients: state.ingredientState.dataLoaded,
        hasNutrition: state.nutritionState.dataLoaded,
        hasMenu: state.menuState.dataLoaded
    } as ReduxProps
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPreloadedRecipeImages: (b: boolean) => dispatch(setPreloadedRecipeImages(b)),
        setPreloadedCategoryImages: (b: boolean) => dispatch(setPreloadedCategoryImages(b)),
    }
}

export default connect<ReduxProps, DispatchProps, {}, IState>(mapStateToProps, mapDispatchToProps)(DownloadContainer)
