/* eslint-disable react/prop-types */
import React from 'react';
import { Ingredient, Recipe } from '../../model';
import QuantityHandler from '../../helpers/quantity_handler';
import ListItem from '../list_item';

interface IRecipeIngredientList {
  recipe: Recipe;
  amount: number;
  showAlteredItem: boolean;
  ingredients?: Ingredient[];
  alteredIngredients: number[];
}

const RecipeIngredientList: React.FC<IRecipeIngredientList> = ({
  recipe,
  amount,
  showAlteredItem,
  ingredients,
  alteredIngredients,
}) => {
  const isAlteredIngredient = (id: number) =>
    alteredIngredients.some((ingId) => ingId === id);

  return (
    <>
      {ingredients?.map((ingredient) => {
        const displayQuantity = QuantityHandler.convertQuarters(
          ingredient.quantity
        );
        const displayMeasure = QuantityHandler.shouldUsePlural(
          ingredient,
          amount,
          recipe.minServings
        )
          ? ingredient.measurePlural
          : ingredient.measure;
        return (
          <ListItem
            key={ingredient.name}
            primaryContent={ingredient.name}
            accent={showAlteredItem && isAlteredIngredient(ingredient.id)}
            secondaryContent={`${displayQuantity} ${displayMeasure}`}
          />
        );
      })}
    </>
  );
};

export default RecipeIngredientList;
