import React from 'react'
import {Component} from 'react'
import {View, Image, ImageSourcePropType} from 'react-native'
import styles from './styles'
import Touchable from '../touchable';
import MfText, { TEXT_STYLES } from '../mf_text';
import checkIcon from '../../../assets/mf_ui/bockav-ikon.png'

interface ICheckedButton {
    checked: boolean,
    title: string,
    onPress: Function
}

class CheckButton extends Component<ICheckedButton> {
    render() {
        if (this.props.checked) {
            return (
                <View style={[styles.container, styles.checked]}>
                    <View style={styles.content}>
                        <MfText style={[styles.text, styles.largeText]} textStyle={TEXT_STYLES.PLAIN_TEXT}>{this.props.title}</MfText>
                        <Image style={styles.check} source={checkIcon as ImageSourcePropType} />
                    </View>
                </View>
            )
        } else {
            return (
                <Touchable onPress={() => this.props.onPress()}>
                    <View style={[styles.container, styles.notChecked]}>    
                        <View style={styles.content}>
                            <MfText style={[styles.text, styles.largeText]} textStyle={TEXT_STYLES.PLAIN_TEXT}>{this.props.title}</MfText>
                            <View style={styles.unCheck} />
                        </View>
                    </View>
                </Touchable>
            )
        }
        
    }
}

export default CheckButton
