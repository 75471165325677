import { Action, ActionCreator, Dispatch } from 'redux'
import fetch from 'cross-fetch'
import { RecipeCategory } from '../../model'
import { ThunkAction } from 'redux-thunk'
import { Types } from './types';
import { IState } from '../../model/state'
import * as config from '../../config';

interface ICheckAction extends Action {
    type: Types.CHECK_CATEGORIES
}

interface IRequestAction extends Action {
    type: Types.REQUEST_CATEGORIES
}

interface IReceiveAction extends Action {
    type: Types.RECEIVE_CATEGORIES
    categories: RecipeCategory[]
}

interface IErrorAction extends Action {
    type: Types.ERROR_CATEGORIES
    error: Error
}

interface ISetCurrentCategory extends Action {
    type: Types.SET_CURRENT_CATEGORY,
    id: number
}

interface ISetPreloadedImages extends Action {
    type: Types.SET_CAT_PRELOADED_IMAGES,
    didPreLoad: boolean
}

export type ICategoryAction =  IRequestAction | IReceiveAction | IErrorAction | ICheckAction | ISetCurrentCategory | ISetPreloadedImages

export const setPreloadedCategoryImages: ActionCreator<ISetPreloadedImages> = (didPreLoad: boolean) => {
    return {
        type: Types.SET_CAT_PRELOADED_IMAGES,
        didPreLoad: didPreLoad
    }
}

const checkCategories: ICheckAction = {
    type: Types.CHECK_CATEGORIES
}

const requestCategories: IRequestAction = {
    type: Types.REQUEST_CATEGORIES
}

const receiveCategories: ActionCreator<IReceiveAction> = (categories: RecipeCategory[]) => {
    return {
        type: Types.RECEIVE_CATEGORIES,
        categories: categories
    }
}

const errorCategories: ActionCreator<IErrorAction> = (error: Error) => {
    return {
        type: Types.ERROR_CATEGORIES,
        error: error
    }
}

const setCurrentCategory: ActionCreator<ISetCurrentCategory> = (id: number) => {
    return {
        type: Types.SET_CURRENT_CATEGORY,
        id: id
    }
}

// WARNING: Är det ok att göra så här?
export function setCategory(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(setCurrentCategory(id))
    }
}

export function fetchCategories(): ThunkAction<Promise<ICategoryAction>, IState, undefined, ICategoryAction> {
    // console.log("Fetching")
    return async (dispatch) => {
        dispatch(requestCategories)
        try {
            const response = await fetch(config.CATEGORY_URL, { mode: 'cors' });
            const json = await response.json()
            return dispatch(receiveCategories(json))
        } catch(error) {
            return dispatch(errorCategories(error))
        }
    }
}

export function getCategories(): ThunkAction<Promise<ICategoryAction>, IState, undefined, ICategoryAction> {
    // console.log("Getting categories")
    return async (dispatch, getState) => {
        const check = dispatch(checkCategories)
        if ((!getState().categoryState.isFetching && !getState().categoryState.dataLoaded) || config.TESTING) {
            return dispatch(fetchCategories())
        } else {
            return check
        }
    }
}

