import { Ingredient, Recipe, Section } from '../model';
import { IAlteredSections } from '../model/state';
import QuantityHandler from './quantity_handler';

export const upsertIngredient = (
  result: { [key: string]: Ingredient },
  ingredient: Ingredient,
  sectionSortOrder: number
) => {
  const id = ingredient.id;
  if (id in result) {
    const i2 = Object.assign({}, result[id]);
    const comb = QuantityHandler.combineMeasurements(
      i2.quantity,
      i2.measure,
      ingredient.quantity,
      ingredient.measure
    );
    if (comb) {
      result[id].quantity = comb.q;
      result[id].quantityShop = comb.q;
      result[id].weight = result[id].weight + ingredient.weight;

      if (i2.measure != comb.m) {
        result[id].measure = ingredient.measure;
        result[id].measureFull = ingredient.measureFull;
        result[id].measurePlural = ingredient.measurePlural;
        result[id].measureFullPlural = ingredient.measureFullPlural;
        result[id].measureShop = ingredient.measureShop;
      }
    }
  } else {
    result[id] = {
      ...ingredient,
      ...QuantityHandler.getQuantityAndMeasurement(
        ingredient.measure,
        ingredient.quantity,
        true
      ),
    };
    result[id].sortOrder = getSortOrder(result[id].sortOrder, sectionSortOrder);
  }
};

export const getPortionsText = (min: boolean, currentRecipe: Recipe) => {
  if (min) {
    const por =
      currentRecipe.minServings > 1
        ? currentRecipe.servingsLabelPlural
        : currentRecipe.servingsLabelSingular;
    return `${QuantityHandler.convertQuarters(
      currentRecipe.minServings
    )} ${por}`;
  } else {
    const por =
      currentRecipe.maxServings > 1
        ? currentRecipe.servingsLabelPlural
        : currentRecipe.servingsLabelSingular;
    return `${QuantityHandler.convertQuarters(
      currentRecipe.maxServings
    )} ${por}`;
  }
};

export const getUnitDisplayText = (unit: string) =>
  unit !== '' ? `(${unit})` : '';

export const getSortOrder = (
  ingredientSortOrder: number,
  sectionSortOrder: number
): number => {
  const ingSortOrder =
    ingredientSortOrder > 9 ? ingredientSortOrder : `0${ingredientSortOrder}`;
  return parseInt(`${sectionSortOrder}${ingSortOrder}`);
};

export const getSortedIngredients = (ingredients: {
  [key: string]: Ingredient;
}) => [...Object.values(ingredients)].sort((a, b) => a.sortOrder - b.sortOrder);

export const getIngredients = (
  sections: Section[],
  alteredSections: IAlteredSections
): {
  ingredients: { [key: string]: Ingredient };
  alteredIngredients: number[];
} => {
  const result: { [key: string]: Ingredient } = {};
  const ai: number[] = [];
  sections.forEach((section) => {
    if (!(section.id in alteredSections) && !section.isAlternative) {
      section.ingredients.forEach((ingredient) => {
        upsertIngredient(result, ingredient, section.sortOrder);
      });
    } else {
      const alteredSection = sections.find(
        (a) => a.id === alteredSections[section.id]
      );
      if (
        typeof alteredSection === typeof section &&
        alteredSection !== undefined
      ) {
        alteredSection.ingredients.forEach((ingredient) => {
          upsertIngredient(result, ingredient, section.sortOrder);
          ai.push(ingredient.id);
        });
      }
    }
  });
  return { ingredients: result, alteredIngredients: ai };
};
