import { StyleSheet } from 'react-native';
import {
  NAVBAR_SIZE,
  PRIMARY_THEME_COLORS,
  TEXT_COLORS,
} from '../../../app/config/style';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: NAVBAR_SIZE.height,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    zIndex: 10,
    backgroundColor: PRIMARY_THEME_COLORS.navBar,
    position: 'fixed',
    top: 0,
    maxWidth: 1440,
  },
  headerLeft: {
    flex: 1,
    paddingLeft: 10,
    alignItems: 'flex-start',
  },
  headerCenter: {
    flex: 4,
  },
  headerLeftImage: {
    width: 30,
    height: 30,
  },
  headerRight: {
    flex: 1,
    paddingRight: 10,
    alignItems: 'flex-end',
  },
  text: {
    textAlign: 'center',
    color: TEXT_COLORS.light,
    fontSize: 25,
  },
});
