import React from 'react';
import { Screen } from '../../../interface';
import { Platform } from 'react-native';
import RecipeListContainer from './recipe_list_container';
import { NavigationScreenProps } from 'react-navigation';
import routes from '../../../navigation/routes';

const route = routes.recipeOverview.name;
// If override componentDidMount call super.componentDidMount() to disable icon in web navBar
class RecipeList extends Screen {
  state = {
    navTitle: '',
  };

  static navigationOptions = ({
    navigation,
  }: NavigationScreenProps) => {
    return {
      headerTitle: navigation.getParam('title'),
    };
  };

  navigate() {
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, this.state.navTitle);
    } else {
      this.props.navigation.navigate(route, {
        title: this.state.navTitle,
      });
    }
  }

  setNavTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  render() {
    return (
      <RecipeListContainer
        setTitle={this.setNavTitle}
        onNavigate={() => this.navigate()}
      />
    );
  }
}

export default RecipeList;
