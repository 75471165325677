import React from 'react';
import { Component } from 'react';
import { FlatList } from 'react-native';
import { RecipeStub, RecipeCategory } from '../../../../model';
import { IContainer } from '../../../../interface';
import { IState } from '../../../../model/state';
import { connect } from 'react-redux';
import Card from '../../../../components/card';
import styles from './styles';
import { setRecipe, setRecipeAmount } from '../../../../redux/actions/recipes';
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import { firstCapital, getText } from '../../../../helpers/text/translation';
import EmptyCard from '../../../../components/empty_card';
import Loader from '../../../../components/loader';
import { getNumOfColumns } from '../../../../helpers/flatListCardHelper';
import CardSeparator from '../../../../components/card_separator';

//From mapStateToProps
interface ReduxProps {
  currentCategory: RecipeCategory;
  preLoadedImages: boolean;
}

interface DispatchProps {
  setCurrentRecipe: Function;
  setAmount: Function;
}

//Passed from JSX component
export interface ReactProps extends IContainer {}

type Props = ReduxProps & ReactProps & DispatchProps;
// & DispatchProps

//Internal state
interface State {}

class RecipeListContainer extends Component<Props, State> {
  componentDidMount() {}

  getRecipeName(id: number) {
    const { currentCategory } = this.props;
    if (currentCategory.recipes) {
      const r = currentCategory.recipes.find((re) => re.id === id);
      if (r) {
        return r.name;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  async cardPressed(id: number) {
    await this.props.setCurrentRecipe(id);
    this.props.setTitle(this.getRecipeName(id));
    this.props.onNavigate();
  }
  addEmptyCards() {
    const recipes = this.props.currentCategory.recipes;
    const emptyCards = [];
    if (recipes !== undefined) {
      let amount = recipes.length;
      while (amount % 3 !== 0) {
        amount++;
      }
      const result = amount - recipes.length;

      for (let i = 0; i < result; i++) {
        emptyCards.push(<EmptyCard key={i + 'x'} />);
      }
    }
    return emptyCards;
  }

  sortByName(recipes: RecipeStub[] | undefined) {
    recipes?.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return recipes;
  }

  renderList() {
    if (this.props.currentCategory.recipes) {
    }
  }

  render() {
    const { preLoadedImages } = this.props;
    return (
      <MfContainer backgroundImage={backgrounds.RECIPE}>
        {!preLoadedImages ? (
          <Loader text={getText('Detta kan ta en liten stund')} />
        ) : (
          <FlatList
            contentContainerStyle={styles.container}
            keyExtractor={(item) => item.id.toString()}
            numColumns={getNumOfColumns()}
            data={this.sortByName(this.props.currentCategory.recipes)}
            renderItem={({ item }: { item: RecipeStub }) => (
              <Card
                title={firstCapital(item.name)}
                key={item.id}
                image={{ uri: item.image }}
                onPress={() => this.cardPressed(item.id)}
              />
            )}
            ItemSeparatorComponent={CardSeparator}
          />
        )}
      </MfContainer>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    currentCategory: state.categoryState.currentCategory,
    preLoadedImages: state.recipeState.preLoadedImages,
  } as ReduxProps;
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
    setAmount: (amount: number) => dispatch(setRecipeAmount(amount)),
  };
};

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(RecipeListContainer);
