import { StyleSheet, Platform } from 'react-native';
import {
  CARD_SIZE,
  CARD_IMAGE_SIZE,
  PRIMARY_THEME_COLORS,
} from '../../config/style';
import { IS_TABLET } from '../../helpers/device';

export default StyleSheet.create({
  container: {
    backgroundColor: PRIMARY_THEME_COLORS.backgroundLight,
    ...Platform.select({
      web: {
        boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.3)',
      },
      android: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.6,
        shadowRadius: 1,
        shadowOffset: { width: 0, height: 2 },
        elevation: 3,
      },
      default: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.6,
        shadowRadius: 1,
        shadowOffset: { width: 0, height: 2 },
      },
    }),
  },
  containerStandard: {
    width: CARD_SIZE.width,
    minHeight: CARD_SIZE.height,
    maxWidth: CARD_SIZE.maxWidth,
  },
  containerSmall: {
    height: CARD_SIZE.smallHeight,
    width: CARD_SIZE.smallWidth,
    maxWidth: CARD_SIZE.maxWidth,
    flexDirection: 'row',
  },
  margin: {
    marginHorizontal: IS_TABLET ? 3 : undefined,
  },
  imageContainer: {
    width: CARD_IMAGE_SIZE.width,
    height: CARD_IMAGE_SIZE.height,
    maxWidth: CARD_IMAGE_SIZE.maxWidth,
  },
  imageContainerSMall: {
    width: CARD_IMAGE_SIZE.smallWidth,
    height: CARD_IMAGE_SIZE.smallHeight,
  },
  headerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  headerText: {
    textAlign: 'center',
  },
  headerTextSmall: {
    fontSize: 13,
  },
  image: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: undefined,
    height: undefined,
    resizeMode: 'cover',
  },
});
