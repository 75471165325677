import React from 'react'
import {Component} from 'react'
import {ImageSourcePropType} from 'react-native';
import styles from './styles'
import {INavButton} from '../nav_button/i_nav_button'
import icon from '../../../../assets/ui/more.png'
import NavButton from '../nav_button'

class InformationButton extends Component<INavButton> {
    render() {
        return <NavButton onPress={() => (this.props.onPress as Function)()} icon={icon as ImageSourcePropType} styles={styles}/>
    }
}

export default InformationButton