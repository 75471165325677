import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS, PRIMARY_FONT, TEXT_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        borderBottomColor: PRIMARY_THEME_COLORS.border,
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 5,
        margin: 5
    },
    text: {
        color: TEXT_COLORS.normal,
        flex: 1,
        // margin: 5,
    },
    accentContainer: {
        flex: 1
    },
    textStyle: {
        fontSize: 17,
        fontFamily: 'clan-pro-narr-book'
    },
    animContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    }
})