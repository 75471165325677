import {
  EType,
  PantryItem,
  SearchResult,
  Type,
} from '../components/search/types';
import { Recipe, RecipeCategory } from '../model';

const combineIds = (
  recipeId: number,
  objectId: number,
  type: Type,
  name: string
): string => `${recipeId}-${objectId}-${type}-${name.toLocaleLowerCase()}`;

export const splitIds = (id: string): SearchResult => {
  const parts = id.split('-');
  return {
    recipeId: parseInt(parts[0]),
    objectId: parseInt(parts[1]),
    type: parts[2] as Type,
    name: parts[3],
  };
};

export const getItemsToFlexSearchIndex = (
  exclude: EType[],
  recipes?: Recipe[],
  categories?: RecipeCategory[]
): { id: string; name: string }[] => {
  const returnArr: { id: string; name: string }[] = [];
  if (!!recipes) {
    recipes.forEach((recipe) => {
      if (!exclude.includes(EType.RECIPE)) {
        returnArr.push({
          id: combineIds(recipe.id, recipe.id, EType.RECIPE, recipe.name),
          name: recipe.name,
        });
      }
      recipe.sections.forEach((section) => {
        if (!exclude.includes(EType.SECTION)) {
          returnArr.push({
            id: combineIds(recipe.id, section.id, EType.SECTION, section.title),
            name: section.title,
          });
        }
        section.ingredients.forEach((ingredient) => {
          if (!exclude.includes(EType.INGREDIENT)) {
            returnArr.push({
              id: combineIds(
                recipe.id,
                ingredient.id,
                EType.INGREDIENT,
                ingredient.name
              ),
              name: ingredient.name,
            });
          }
        });
      });
    });
  }
  if (!!categories) {
    categories.forEach((category) => {
      if (category.recipes) {
        category.recipes.forEach((recipe) => {
          returnArr.push({
            id: combineIds(recipe.id, category.id, EType.TAG, category.name),
            name: category.name,
          });
        });
      }
    });
  }
  return returnArr;
};

export const getPantryItems = (searchResult: SearchResult[]): PantryItem[] => {
  const searchResultDistinct: PantryItem[] = [];
  const unique: { [key: string]: boolean } = {};
  searchResult.forEach((result) => {
    if (!unique[result.objectId]) {
      unique[result.objectId] = true;
      searchResultDistinct.push({
        id: result.objectId,
        type: result.type,
        name: capitalizeFirstLetter(result.name),
      });
    }
  });
  if (!searchResultDistinct) return [];
  return searchResultDistinct.sort(sortAlphabetically);
};

export const getAllPantryItems = (
  categories: RecipeCategory[]
): PantryItem[] => {
  return categories
    .map((category) => ({
      id: category.id,
      name: capitalizeFirstLetter(category.name),
      type: EType.TAG,
    }))
    .sort(sortAlphabetically);
};

export const itemIsSame = (itemA: PantryItem, itemB: PantryItem): boolean => {
  if (itemA && itemB) {
    return (
      itemA.id === itemB.id &&
      itemA.name === itemB.name &&
      itemA.type === itemB.type
    );
  }
  return false;
};

const capitalizeFirstLetter = (string: string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

const sortAlphabetically = (a: PantryItem, b: PantryItem) => {
  if (a.name < b.name) return -1;
  else if (a.name > b.name) return 1;
  return 0;
};
