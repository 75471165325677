import React from 'react';
import { Component } from 'react';
import {
  View,
  SafeAreaView,
  TextInput,
  Image,
  ImageSourcePropType,
  Picker,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import styles from './styles';
import MfModal, { animationTypes } from '../../../../../components/mf_modal/';
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Button, {
  BUTTON_VARIANTS,
} from '../../../../../components/button/button';
import close from '../../../../../../assets/ui/close_black.png';
import Touchable from '../../../../../components/touchable';
import { ShoppingList } from '../../../../../model';
import { getDate } from '../../../../../helpers/date';
import ShoppingAnimation from '../../../../../components/shopping_animation';

interface IAddShoppingListModal {
  visible: boolean;
  onClose: Function;
  onCreate: Function;
  onAdd: Function;
  shoppingLists: ShoppingList[];
}

class AddShoppingListModal extends Component<IAddShoppingListModal> {
  state = {
    text: getDate(),
    selectedList:
      this.props.shoppingLists.length > 0 ? this.props.shoppingLists[0].id : 0,
    showCreateView: false,
    showAddToListView: false,
    startView: true,
    header: getText('Inköpslista'),
    showAnimation: false,
  };

  hasShoppingLists() {
    return this.props.shoppingLists.length > 0;
  }

  initialPlaceHolder() {
    return getText('Namn på inköpslista');
  }
  onCreate() {
    this.props.onCreate(this.state.text);
    this.setState({ showAnimation: true });
  }
  onAdd() {
    this.props.onAdd(this.state.selectedList);
    this.setState({ showAnimation: true });
  }
  onCreateViewPressed() {
    this.setState({
      showAddToListView: false,
      header: getText('Skapa ny inköpslista'),
      startView: false,
    });
  }
  onAddListViewPressed() {
    this.setState({
      showAddToListView: true,
      startView: false,
      header: getText('Välj inköpslista'),
    });
  }

  onClose() {
    this.setState({
      showCreateView: false,
      showAddToListView: false,
      startView: true,
      showAnimation: false,
    });
    this.props.onClose();
  }
  render() {
    return (
      <MfModal
        visibile={this.props.visible}
        animationType={animationTypes.FADE}
        transparent={true}
        onClose={() => this.onClose()}
      >
        {this.state.showAnimation ? (
          <ShoppingAnimation onAnimationEnd={() => this.onClose()} />
        ) : null}
        <SafeAreaView style={styles.container}>
          <KeyboardAvoidingView
            enabled
            behavior={Platform.OS == 'android' ? undefined : 'padding'}
            keyboardVerticalOffset={200}
          >
            <View style={styles.contentContainer}>
              <View style={styles.headerContainer}>
                <Touchable onPress={() => this.onClose()}>
                  <View>
                    <Image
                      style={styles.image}
                      source={close as ImageSourcePropType}
                    />
                  </View>
                </Touchable>
                <View style={styles.header}>
                  <MfText textStyle={TEXT_STYLES.HEADER}>
                    {this.state.header}
                  </MfText>
                </View>

                <View style={styles.headerRight}></View>
              </View>
              {this.state.startView ? (
                <View>
                  <Button
                    variant={BUTTON_VARIANTS.NO_RADIUS}
                    borderRadiusStyle={styles.buttonOuter}
                    style={[styles.button, styles.buttonRed]}
                    title={getText('Skapa ny lista')}
                    onPress={() => this.onCreateViewPressed()}
                  />
                  {this.hasShoppingLists() ? (
                    <Button
                      variant={BUTTON_VARIANTS.NO_RADIUS}
                      borderRadiusStyle={styles.buttonOuter}
                      style={[styles.button, styles.buttonGren]}
                      title={getText('Lägg till i befintlig lista')}
                      onPress={() => this.onAddListViewPressed()}
                    />
                  ) : null}
                  {/* <Button variant={BUTTON_VARIANTS.NO_RADIUS} style={[styles.button, styles.buttonGren]} title={getText('Lägg till i befintlig lista')} onPress={() => this.onAdd()} />  */}
                </View>
              ) : (
                <View>
                  {this.state.showAddToListView ? (
                    <View style={styles.addContainer}>
                      <MfText textStyle={TEXT_STYLES.SUB_HEADER}>
                        {getText('Lägg till i befintlig inköpslista:')}
                      </MfText>
                      <Picker
                        style={styles.picker}
                        selectedValue={this.state.selectedList}
                        // WARNING: Konstigt att val blir string här.
                        onValueChange={(val, i) =>
                          this.setState({ selectedList: parseInt(val) })
                        }
                      >
                        {this.props.shoppingLists.map((s) => {
                          return (
                            <Picker.Item
                              key={s.id}
                              label={s.title}
                              value={s.id}
                            />
                          );
                        })}
                      </Picker>
                      <View style={styles.createButtonContainer}>
                        <Button
                          style={styles.buttonGren}
                          title={getText('Lägg till')}
                          onPress={() => this.onAdd()}
                        />
                      </View>
                    </View>
                  ) : (
                    <View>
                      <TextInput
                        style={styles.input}
                        placeholder={this.initialPlaceHolder()}
                        onChangeText={(text: string) =>
                          this.setState({ text: text })
                        }
                      />
                      <View style={styles.createButtonContainer}>
                        <Button
                          title={getText('Skapa')}
                          onPress={() => this.onCreate()}
                        />
                      </View>
                    </View>
                  )}
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </SafeAreaView>
      </MfModal>
    );
  }
}

export default AddShoppingListModal;
