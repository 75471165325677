import React from 'react';
import { Component } from 'react';
import { Platform, View } from 'react-native';
import { NavigationScreenProp } from 'react-navigation';
import { ERoutes } from '../navigation/routes';

export interface IScreen {
  navigation: NavigationScreenProp<any, any>;
  webNavigate: WebNavigate;
  webNavigation: {
    setHeaderRight: Function;
    actionCallback: Function;
    title: string;
    setNavBarBackgroundColor: Function;
    goBack: () => void;
  };
}

interface WebNavigate {
  (route: ERoutes, nextTitle: string): void;
}

export class Screen extends Component<IScreen> {
  componentDidMount() {
    if (Platform.OS === 'web') {
      this.props.webNavigation.setHeaderRight(
        <View></View>
      );
    }
  }
}
