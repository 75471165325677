import React, { useEffect, useState } from 'react';
import {
  View,
  SafeAreaView,
  TextInput,
  Image,
  ImageSourcePropType,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import styles from './styles';
import MfModal, { animationTypes } from '../../../../../components/mf_modal';
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Button from '../../../../../components/button/button';
import close from '../../../../../../assets/ui/close_black.png';
import Touchable from '../../../../../components/touchable';

type props = {
  visible: boolean;
  currentName: string;
  onCancel: () => void;
  onChangeName: (name: string) => void;
};
const ChangeNameModal = ({
  visible,
  onCancel,
  onChangeName,
  currentName,
}: props) => {
  const [newName, setNewName] = useState('');

  return (
    <MfModal
      visibile={visible}
      onClose={onCancel}
      transparent
      animationType={animationTypes.FADE}
    >
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
          enabled
          behavior={Platform.OS == 'android' ? undefined : 'padding'}
          keyboardVerticalOffset={200}
        >
          <View style={styles.contentContainer}>
            <View style={styles.headerContainer}>
              <Touchable onPress={onCancel}>
                <View>
                  <Image
                    style={styles.image}
                    source={close as ImageSourcePropType}
                  />
                </View>
              </Touchable>
              <MfText textStyle={TEXT_STYLES.HEADER}>
                {getText('Ändra namn')}
              </MfText>
              <View style={styles.headerRight}></View>
            </View>
            <View>
              <TextInput
                style={styles.input}
                placeholder={currentName}
                value={newName}
                onChangeText={(text: string) => setNewName(text)}
              />
              <View style={styles.buttonContainer}>
                <Button
                  style={styles.addButton}
                  title={getText('Ändra')}
                  onPress={() => {
                    onChangeName(newName !== '' ? newName : currentName);
                    setNewName('');
                  }}
                />
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </MfModal>
  );
};

export default ChangeNameModal;
