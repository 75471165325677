import React, {Component} from 'react'
import {View, Text, Image, TouchableHighlight} from 'react-native'
import styles from './styles'
import back from '../../../assets/ui/material-back.png'
import menu from '../../../assets/ui/material-menu.png'
import home from '../../../assets/ui/home.png'

interface INavBar {
    title: string,
    onPress: Function,
    isRoot: boolean,
    headerRight?: JSX.Element,
    showHome: boolean,
    backgroundColor?: string
}
class NavBar extends Component<INavBar> {

    onIconPress() {
        this.props.onPress()
    }

    render() {
        const {backgroundColor} = this.props
        return (
            <div>
                <View style={[styles.container, backgroundColor ? {backgroundColor: backgroundColor} : null]}>
                    <View style={styles.headerLeft}>
                        <TouchableHighlight onPress={() => this.onIconPress()}>
                            <Image style={styles.headerLeftImage} source={{uri: this.props.showHome ? home : this.props.isRoot ? menu : back}} />
                        </TouchableHighlight>
                    </View>

                    <View style={styles.headerCenter}>
                        <Text style={styles.text}>{this.props.title}</Text>
                    </View>

                    <View style={styles.headerRight}>
                        {this.props.headerRight ?
                        this.props.headerRight : null
                        }
                    </View>

                </View>
            </div>
        )
        
    }
}

export default NavBar
