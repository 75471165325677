import React from 'react';
import { View } from 'react-native';
import styles from './styles';
import MfModal from '../../mf_modal';
import Loader from '../../loader';
import MfText, { TEXT_STYLES } from '../../mf_text';
import { animationTypes } from '../../mf_modal';
import { getText } from '../../../helpers/text/translation';
import Button, { BUTTON_VARIANTS } from '../../button/button';

interface props {
  visible: boolean;
  downloading: boolean;
  downloadFinished: boolean;
  onAccept: () => void;
  onDeny: () => void;
  onClose: () => void;
  error: boolean;
}

const DownloadModal = ({
  visible,
  downloading,
  onAccept,
  onDeny,
  downloadFinished,
  onClose,
  error,
}: props) => {
  const getDone = (error?: boolean) => {
    const text = error
      ? 'Något gick fel. Kolla så att du har tillång till internet och försök igen genom att gå in i inställningar'
      : 'Nerladdningen är klar. Du kan nu använda appen utan internet';
    return (
      <View style={styles.modalContent}>
        <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText(text)}
        </MfText>
        <View style={styles.button}>
          <Button
            style={styles.buttonYes}
            variant={BUTTON_VARIANTS.STANDARD}
            onPress={() => onClose()}
            title={getText('Ok')}
          />
        </View>
      </View>
    );
  };

  const getDownloading = () => {
    return (
      <View style={styles.modalContent}>
        <Loader small={true} />
        <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText('Laddar ner recept och bilder')}
        </MfText>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText('Det kan ibland ta några minuter men är strax färdigt...')}
        </MfText>
      </View>
    );
  };

  const getQuestion = () => {
    return (
      <View style={styles.modalContent}>
        <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText(
            'Om du inte alltid har tillgång till Internet kan du ladda ner bilder/recept.'
          )}
        </MfText>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText('Vill du ladda ner nu?')}
        </MfText>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              style={styles.buttonNo}
              variant={BUTTON_VARIANTS.STANDARD}
              onPress={() => onDeny()}
              title={getText('Nej')}
            />
          </View>
          <View style={styles.button}>
            <Button
              style={styles.buttonYes}
              variant={BUTTON_VARIANTS.STANDARD}
              onPress={() => onAccept()}
              title={getText('Ja')}
            />
          </View>
        </View>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText('Du kan göra detta senare i appens Inställningar.')}
        </MfText>
      </View>
    );
  };

  const getError = () => {
    return getDone(true);
  };

  return (
    <MfModal
      visibile={visible}
      animationType={animationTypes.FADE}
      transparent={true}
      onClose={() => {}}
    >
      <View style={styles.modalContainer}>
        {error
          ? getError()
          : downloadFinished
          ? getDone()
          : downloading
          ? getDownloading()
          : getQuestion()}
      </View>
    </MfModal>
  );
};

export default DownloadModal;
