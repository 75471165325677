import React from 'react';
import StartContainer from './start_container/';
import { Screen } from '../../interface';
import { Platform, View, Image, ImageSourcePropType } from 'react-native';
import { NavigationScreenProps } from 'react-navigation';
import MenuButton from '../../components/menu_button/';
import logo from '../../../assets/logos/icon_clean.png';
import { PRIMARY_THEME_COLORS } from '../../config/style';
import SplashScreen from 'react-native-splash-screen';
import { ERoutes, ERoutesTitle } from '../../navigation/routes';

const imageStyle = {
  width: undefined,
  height: undefined,
  flex: 1,
  alignSelf: 'stretch' as 'stretch',
};

// Bryt ut den här till en baskomponent för navigering
class Start extends Screen {
  static navigationOptions = ({ navigation }: NavigationScreenProps) => {
    return {
      headerLeft: <MenuButton onPress={() => navigation.toggleDrawer()} />,
      headerRight: (
        <View style={{ height: 30, width: 30, marginRight: 10 }}>
          <Image style={imageStyle} source={logo as ImageSourcePropType} />
        </View>
      ),
      drawerIcon: () => <Image source={logo as ImageSourcePropType} />,
    };
  };

  componentDidMount() {
    if (Platform.OS !== 'web') {
      SplashScreen.hide();
      // this.props.navigation.setParams({buttonOnPress: this.toogleModal})
    } else {
      this.props.webNavigation.setHeaderRight(
        <Image
          style={{ width: 40, height: 40 }}
          source={{ uri: logo } as ImageSourcePropType}
        />
      );
      this.props.webNavigation.setNavBarBackgroundColor(
        PRIMARY_THEME_COLORS.recipes
      );
      this.props.webNavigation.title = ERoutesTitle.Start;
    }
  }

  navigate(route: ERoutes) {
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, route);
    } else {
      this.props.navigation.navigate(route);
    }
  }
  render() {
    return (
      <StartContainer
        setTitle={() => {}}
        onNavigate={(route: ERoutes) => this.navigate(route)}
      />
    );
  }
}

export default Start;
