import { Action, ActionCreator, Dispatch } from 'redux'
import fetch from 'cross-fetch'
import { MenuItem } from '../../model'
import { ThunkAction } from 'redux-thunk'
import { Types } from './types';
import { IState } from '../../model/state'
import * as config from '../../config';

interface ICheckAction extends Action {
    type: Types.CHECK_MENU
}

interface IRequestAction extends Action {
    type: Types.REQUEST_MENU
}

interface IReceiveAction extends Action {
    type: Types.RECIEVE_MENU
    menu: MenuItem[]
}

interface IErrorAction extends Action {
    type: Types.ERROR_MENU
    error: Error
}


export type IMenuAction =  IRequestAction | IReceiveAction | IErrorAction | ICheckAction


const checkMenu: ICheckAction = {
    type: Types.CHECK_MENU
}

const requestMenu: IRequestAction = {
    type: Types.REQUEST_MENU
}

const receiveMenu: ActionCreator<IReceiveAction> = (menu: MenuItem[]) => {
    return {
        type: Types.RECIEVE_MENU,
        menu: menu
    }
}

const errorMenu: ActionCreator<IErrorAction> = (error: Error) => {
    return {
        type: Types.ERROR_MENU,
        error: error
    }
}

export function fetchMenu(): ThunkAction<Promise<IMenuAction>, IState, undefined, IMenuAction> {
    return async (dispatch) => {
        dispatch(requestMenu)
        try {
            const response = await fetch(config.MENU_URL, { mode: 'cors' });
            const json = await response.json()
            return dispatch(receiveMenu(json))
        } catch(error) {
            return dispatch(errorMenu(error))
        }
    }
}

export function getMenu(): ThunkAction<Promise<IMenuAction>, IState, undefined, IMenuAction> {
    return async (dispatch, getState) => {
        const check = dispatch(checkMenu)
        if ((!getState().menuState.isFetching && !getState().menuState.dataLoaded) || config.TESTING) {
            return dispatch(fetchMenu())
        } else {
            return check
        }
    }
}

