import { CARD_SIZE, WINDOW_SIZE } from '../config/style';
import { IS_WEB } from '../config/style/web_helper';
import { IS_TABLET } from './device';

export const getNumOfColumns = () => {
  if (IS_TABLET || IS_WEB) {
    const val = Math.round(WINDOW_SIZE.width / (CARD_SIZE.maxWidth ?? 290));
    return val > 4 ? 4 : val;
  }
  return 1;
};
