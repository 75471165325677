import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { Image, ImageSourcePropType } from 'react-native';
import { IMfImage } from './i_mf_image';


class MfImage extends Component<IMfImage> {

    render() {
		const uri = this.props.source.replace("http://", "https://")
        return(
            <Image source={uri as ImageSourcePropType} style={this.props.style} />
        )
    }
}

export default MfImage
