import React, { Component, ChangeEvent } from "react";
import {TextInput, View, ImageSourcePropType, Image} from 'react-native'
import {Props} from './types'
import styles from "./styles";
import icon from '../../../../assets/ui/magnify_black.png'

class MfSearchBar extends Component<Props> {
    handleChange = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement
        this.props.onChangeText(target.value)
    }
    handleChangeTest = (text: string) => {
        this.props.onChangeText(text)
    }
    render() {
        return (
            <View style={styles.containerWeb}>
                <Image style={styles.icon} source={icon as ImageSourcePropType} />
                <TextInput style={styles.searchBarWeb} onChangeText={this.handleChangeTest} placeholder={this.props.placeholder} />
            </View>
            
        )
    }
}

export default MfSearchBar