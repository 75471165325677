import { IFavoriteAction } from "../actions"
import { Types } from '../actions/types'
import { IFavoriteState } from '../../model/state'
import { persistReducer } from "redux-persist"
import MFStorage from "./storage"

const initialState: IFavoriteState = {
    favorites: []
}

const config = {
    key: 'favoriteState',
    storage: MFStorage
}

const reducer =  (state = initialState, action: IFavoriteAction): IFavoriteState => {
    switch (action.type) {
        case Types.ADD_TO_FAVORITE:
            return { 
                ...state, 
                favorites: addToFavorites(action.recipeId, state.favorites),
                
            }
        case Types.REMOVE_FROM_FAVORITE:
            return { 
                ...state, 
                favorites: removeFromFavorites(action.recipeId, state.favorites)
            }
        default: return state
    }
}

function addToFavorites(id: number, currentFavorites: number[]) {
    const arr = Array.from(currentFavorites)
    if (arr.indexOf(id) > -1) {
        return arr
    } else {
        arr.push(id)
        return arr
    }
    
}

function removeFromFavorites(id: number, currentFavorites: number[]) {
    const arr = Array.from(currentFavorites)
    let i = arr.indexOf(id)
    if (i > -1) {
        arr.splice(i, 1)
    }
    return arr
}

export default persistReducer(config, reducer)