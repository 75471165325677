import React from 'react'
import {Component} from 'react'
import SettingsContainer from './settings_container'
import { NavigationScreenProps } from 'react-navigation'
import MenuButton from '../../components/menu_button'
import { View, Image, Platform, ImageSourcePropType } from 'react-native'
import logo from '../../../assets/logos/icon_clean.png'
import { Screen } from '../../interface'
import { PRIMARY_THEME_COLORS } from '../../config/style'

const imageStyle = {
    width: undefined,
    height: undefined,
    flex: 1,
    alignSelf: 'stretch' as 'stretch'
}

class Settings extends Screen {

    static navigationOptions = ({navigation}: NavigationScreenProps) => {
        return {
            headerLeft: <MenuButton onPress={() => navigation.toggleDrawer()} />,
            headerRight: <View style={{height: 30, width: 30, marginRight: 10}}><Image style={imageStyle} source={logo as ImageSourcePropType} /></View>,
        }
    }

    componentDidMount() {
        if (Platform.OS !== 'web') {
            // this.props.navigation.setParams({buttonOnPress: this.toogleModal})
        } else {
            this.props.webNavigation.setHeaderRight(
                <Image style={{width: 40, height: 40}} source={{uri: logo} as ImageSourcePropType} />
            )
            this.props.webNavigation.setNavBarBackgroundColor(PRIMARY_THEME_COLORS.recipes)
        }
        
    }

    render() {
        return (
            <SettingsContainer />
        )
    }
}

export default Settings
