import {StyleSheet, Platform} from 'react-native'
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../../../../config/style';
import { IS_TABLET } from '../../../../../helpers/device';

export default StyleSheet.create({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        alignItems: 'flex-end',
    },
    contentContainer: {
        backgroundColor: 'white',
        width: IS_TABLET ? '36%' : '100%',
        height: '100%',
    },
    background: {
        width: '100%',
        flex: 1
    },
    header: {
        minHeight: 60,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 10,
        backgroundColor: PRIMARY_THEME_COLORS.tips
    },
    headerText: {
        color: TEXT_COLORS.normal,
        fontFamily: 'clan-pro-narr-book',
        fontSize: 30
    },
    headerRight: {
        width: 40
    },
    icon: {
        height: 40,
        width: 40
    },
    nutButton: {
        // margin: 10,
        backgroundColor: PRIMARY_THEME_COLORS.tips,
        padding: 8,
        borderRadius: 5,
    },
    nutButtonRadius: {
        borderRadius: 5
    },
    nutButtonContainer: {
        margin: 15
    }

})