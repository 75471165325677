import React from 'react'
import {Component} from 'react'
import styles from './styles'
import loader from '../../../assets/mf_ui/load_food.jpg'
import AnimatedOpacity from '../animated_opacity';
import { Image, ImageSourcePropType } from 'react-native';


class ImageLoader extends Component {
    render() {
        return (
            <AnimatedOpacity duration={1000} delay={0} onAnimationEnd={() => {}} loop={true} from={0.5} to={1}>
                <Image style={styles.image} source={loader as ImageSourcePropType} />
            </AnimatedOpacity>
        )
    }
}

export default ImageLoader
