import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        // TODO: Fix height 
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        flexDirection: 'row',
        zIndex: 20
    },
    isOpen: {
        width: '100%'
    },
    isClosed: {
        width: '0%'
    },
    drawerContainer: {
    },
    drawerContent: {
        height: '100%',
        width: 300,
        position: 'absolute',
        top: 0,
        left: 0
    },
    transContainer: {
        flex: 1,
        transform: [{translateX: 300}],

    },
    header: {
        height: 80,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    links: {
        flex: 1,
        padding: 10,
    },
    footer: {
        height: 200,
        padding: 30
    },
    link: {
        
    },  
    headerTextContainer: {
        alignItems: 'center',
        paddingLeft: 10
    },
    headerLogoContainer: {
        height: 60,
        width: 60,
        paddingRight: 10
    },
    headerText: {
        
        paddingTop: 0
    },
    headerLogo: {
        flex: 1,
        width: undefined,
        height: undefined,
        resizeMode: 'contain'
    },
    logo: {
        flex: 1,
        width: undefined,
        height: undefined,
        resizeMode: 'contain'
    },
    itemStyle: {
        // height: 60,   
        borderBottomWidth: 1,
        borderBottomColor: 'white',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
        marginTop: 30,
        paddingBottom: 20
    },
    itemText: {
        width: '50%',
        color: 'white',
        fontSize: 20
    },
    arrow: {
        height: 20,
        width: 20,
        marginRight: 20
    },
    icon: {
        height: 40,
        width: 40
    },
    imageBackground: {
        flex: 1
    },
})

export const linkStyle = {
    margin: 5
}