import { Action, ActionCreator, Dispatch } from "redux";
import { Types } from "./types";
import { IngredientBase, IngredientMeasures } from "../../model";
import { ThunkAction } from "redux-thunk";
import { IState } from "../../model/state";
import * as config from '../../config';

interface IRequestAction extends Action {
    type: Types.REQUEST_INGREDIENTS
}

interface IReceiveAction extends Action {
    type: Types.RECIEVE_INGREDIENTS
    ingredients: IngredientBase[]
}

interface IErrorAction extends Action {
    type: Types.ERROR_INGREDIENTS
    error: Error
}

interface ICheckAction extends Action {
    type: Types.CHECK_INGREDIENTS
}

interface IAddUserIngredient extends Action {
    type: Types.ADD_USER_INGREDIENT,
    ingredient: IngredientBase
}

interface ISetIngredientMeasures extends Action {
    type: Types.SET_INGREDIENT_MEASURE,
    measures: IngredientMeasures
}

export type IIngredientAction =  IRequestAction | IReceiveAction | IErrorAction | ICheckAction | IAddUserIngredient | ISetIngredientMeasures

const checkIngredients: ICheckAction = {
    type: Types.CHECK_INGREDIENTS
}

const requestIngredients: IRequestAction = {
    type: Types.REQUEST_INGREDIENTS
}

const setIngredientMeasureAction: ActionCreator<ISetIngredientMeasures> = (m: IngredientMeasures) => {
    return {
        type: Types.SET_INGREDIENT_MEASURE,
        measures: m
    }
}

const receiveIngredients: ActionCreator<IReceiveAction> = (ingredients: IngredientBase[]) => {
    return {
        type: Types.RECIEVE_INGREDIENTS,
        ingredients: ingredients
    }
}

const addUserIngredientAction: ActionCreator<IAddUserIngredient> = (ingredient: IngredientBase) => {
    return {
        type: Types.ADD_USER_INGREDIENT,
        ingredient: ingredient
    }
}

export function setIngredientMeasure(m: IngredientMeasures) {
    return async (dispatch: Dispatch) => {
        dispatch(setIngredientMeasureAction(m))
    }
}

export function addUserIngredient(i: IngredientBase) {
    return async (dispatch: Dispatch) => {
        dispatch(addUserIngredientAction(i))
    }
}

const errorIngredients: ActionCreator<IErrorAction> = (error: Error) => {
    return {
        type: Types.ERROR_INGREDIENTS,
        error: error
    }
}

export function fetchIngredients(): ThunkAction<Promise<IIngredientAction>, IState, undefined, IIngredientAction> {
    // console.log("Fetching")
    return async (dispatch) => {
        dispatch(requestIngredients)
        try {
            const response = await fetch(config.INGREDIENT_URL, { mode: 'cors' });
            const json = await response.json()
            return dispatch(receiveIngredients(json))
        } catch(error) {
            return dispatch(errorIngredients(error))
        }
    }
}

export function getIngredients(): ThunkAction<Promise<IIngredientAction>, IState, undefined, IIngredientAction> {
    // console.log("Getting recipes")
    return async (dispatch, getState) => {
        const check = dispatch(checkIngredients)
        if ((!getState().ingredientState.isFetching && !getState().ingredientState.dataLoaded) || config.TESTING) {
            // console.log('Fetching recipes')
            return dispatch(fetchIngredients())
        } else {
            return check
        }
    }
}