import { Recipe, RecipeCategory } from '../../model';
import Search from './search';

export enum EResultType {
  RECIPES = 'RECIPES',
  SEARCHRESULT = 'SEARCHRESULT',
}
export enum EType {
  RECIPE = 'RECIPE',
  SECTION = 'SECTION',
  INGREDIENT = 'INGREDIENT',
  TAG = 'TAG',
}

export type ResultType = EResultType.RECIPES | EResultType.SEARCHRESULT;
export type Type = EType.RECIPE | EType.SECTION | EType.INGREDIENT | EType.TAG;

export interface Props {
  recipes?: Recipe[];
  categories?: RecipeCategory[];
  callback(result: number[] | SearchResult[], searchTerm: string): void;
  resultType?: ResultType;
  placeHolder?: string;
  onCancel: Function;
  excludeTypes?: Type[];
  searchChangedCallback?(searchString: string): void;
  ref?: React.RefObject<Search>;
}

export interface State {
  search: string;
  index: any;
}
export interface SearchResult {
  recipeId: number;
  objectId: number;
  type: Type;
  name: string;
}

export interface PantryItem {
  id: number;
  type: Type;
  name: string;
}
