import {StyleSheet, Platform} from 'react-native'
import { WINDOW_SIZE, PRIMARY_THEME_COLORS } from '../../../../../config/style';

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    contentContainer: {
        height: '100%',
        backgroundColor: 'white',
        alignItems: 'center'
    },
    headerContainer: {
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image: {
        width: 35,
        height: 35
    },
    headerRight: {
        width: 35,
        height: 35
    },
    searchContainer: {
        width: '100%',
        flex: 2,
        backgroundColor: 'white'
    },
    searchItem: {
        minHeight: 40,
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchItemButton: {
        height: 25,

    },
    input: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 10,
        width: '100%'
    },
    changeViewButton: {
        borderColor: 'grey',
        borderWidth: 1,
        backgroundColor: 'green'
    },
    amountContainer: {
        padding: 10,
        width: '100%',
        
        justifyContent: 'center',
        alignItems: 'center'
    },
    amountInput: {
        height: 40,
        width: 60,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 10, 
        margin: 10 
    },
    amountPicker: {
        ...Platform.select({
            web: {
                borderColor: 'grey',
                borderWidth: 1,
                width: 100
            },
            default: {
                height: 150,
                width: '80%'
            }
        })
    },
    footer: {
        
    },
    button: {
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
        height: 70,
        width: WINDOW_SIZE.width
    }
})