import React from 'react';
import { Platform } from 'react-native';
import { Screen } from '../../../interface';
import SearchContainer from './search_container';
import { NavigationScreenProps } from 'react-navigation';
import routes from '../../../navigation/routes';

const route = routes.recipeOverview.name;

class Search extends Screen {
  state = {
    showModal: false,
    navTitle: '',
  };

  static navigationOptions = ({
    navigation,
  }: NavigationScreenProps) => {
    return {
      headerTitle: navigation.getParam('title'),
      // headerRight: <FilterButton onPress={navigation.getParam('filterClicked')} />
    };
  };

  componentDidMount() {
    super.componentDidMount();
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        filterClicked: () => this.filterClicked(),
      });
    } else {
      // this.props.webNavigation.setHeaderRight(
      //     <FilterButton onPress={() => this.filterClicked()} />
      // )
    }
  }

  filterClicked() {
    this.setState({ showModal: true });
  }

  navigate() {
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, this.state.navTitle);
    } else {
      this.props.navigation.navigate(route, {
        title: this.state.navTitle,
      });
    }
  }

  onModalClose = () => {
    this.setState({ showModal: false });
  };

  setTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  render() {
    return (
      <SearchContainer
        setTitle={this.setTitle}
        onNavigate={() => this.navigate()}
        // showModal={this.state.showModal}
        // onModalClose={this.onModalClose}
      />
    );
  }
}

export default Search;
