import React from 'react';
import PantryContainer from './pantry_container';
import { NavigationScreenProps } from 'react-navigation';
import { Screen } from '../../interface';
import { Platform } from 'react-native';
import routes from '../../navigation/routes';

const route = routes.recipeOverview.name;

class Pantry extends Screen {
  state = {
    triggerShare: false,
    navTitle: '',
  };

  static navigationOptions = ({
    navigation,
  }: NavigationScreenProps) => {
    return {
      // headerRight: <ShareButton onPress={navigation.getParam('buttonOnPress')} />,
    };
  };

  navigate() {
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, this.state.navTitle);
    } else {
      this.props.navigation.navigate(route, {
        title: this.state.navTitle,
      });
    }
  }

  setTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  componentDidMount() {
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        buttonOnPress: this.onTriggerShare,
      });
    } else {
      this.props.webNavigation
        .setHeaderRight
        // <ShareButton onPress={() => this.onTriggerShare()} />
        ();
    }
  }

  onTriggerShare = () => {
    this.setState({ triggerShare: true });
  };

  onShareRespond = () => {
    this.setState({ triggerShare: false });
  };

  render() {
    return (
      <PantryContainer
        // triggerShare={this.state.triggerShare}
        // onShareRespond={this.onShareRespond}
        setTitle={this.setTitle}
        onNavigate={() => this.navigate()}
      />
    );
  }
}

export default Pantry;
