import {StyleSheet, Platform} from 'react-native'
import { WINDOW_SIZE, PRIMARY_THEME_COLORS } from '../../../../../config/style';
import { IS_TABLET } from '../../../../../helpers/device';

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    contentContainer: {
        // height: '60%',
        margin: 20,
        maxWidth: 400,
        width: IS_TABLET ? 400 : WINDOW_SIZE.width-20,
        // padding: 20,
        backgroundColor: 'white',
        alignItems: 'center'
    },
    headerContainer: {
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        width: '100%'
    },
    image: {
        width: 35,
        height: 35
    },
    headerRight: {
        width: 35,
        height: 35
    },
    inputContainer: {

    },
    header: {
        // flex: 1
    },
    addContainer: {
        // flex: 1
    },
    input: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 10,
        minWidth: 200,
        marginBottom: 20
    },
    buttonContainer: {

    },
    picker: {
        ...Platform.select({
            web: {
                borderWidth: 1,
                borderColor: 'black',
                margin: 5,
                height: 40,
                padding: 10,
                marginTop: 20,
                marginBottom: 20
            },
            default: {
                height: 200,
                width: 200,
            }
        })        
    },
    button: {
        height: 100,
        width: 300,
    },
    buttonOuter: {
        marginBottom: 20,
        borderRadius: 0

    },
    createButtonContainer: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center'
    },
    buttonGren: {
        backgroundColor: PRIMARY_THEME_COLORS.green
    },
    buttonRed: {
        backgroundColor: PRIMARY_THEME_COLORS.red
    },
    centerText: {
        textAlign: 'center'
    }
})