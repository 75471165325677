import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        marginTop: 20
    },
    title: {
        fontWeight: 'bold'
    },
    sectionDoneColors: {
        color: TEXT_COLORS.grey,
    },
    titleContainer: {
        height: 45,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: 5,
        marginRight: 5,
        borderTopWidth: 1,
    },
    border: {
        borderTopColor: PRIMARY_THEME_COLORS.border
    },
    borderDone: {
        borderTopColor: PRIMARY_THEME_COLORS.lightGrey
    },
    icon: {
        width: 25,
        height: 25
    },
    background: {
        backgroundColor: '#F3FFF9'
    },
    step: {
        // borderBottomWidth: 1,
        // borderBottomColor: '#D1D1D1'
    }
})