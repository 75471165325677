import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { View, Image, ImageSourcePropType } from "react-native";
import MfText, { TEXT_STYLES } from "../mf_text";
import close from '../../../assets/ui/close_black.png'
import Touchable from '../touchable';

interface ChipProps {
    term: string
    onPress: (term: string) => void
}

class Chip extends Component<ChipProps> {
    render() {
        const {term, onPress} = this.props
        return (
            <Touchable onPress={() => onPress(term)}>
                <View style={styles.chip}>
                    <MfText style={styles.chipText} textStyle={TEXT_STYLES.HEADER}>{term}</MfText>
                    <Image style={styles.chipRemoveBtn} source={close as ImageSourcePropType} />
                </View>
            </Touchable>
        )
    }
}

export default Chip
