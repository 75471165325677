import { Dimensions, Platform } from 'react-native';
import { isTablet } from 'react-native-device-info';
const { width } = Dimensions.get('window');

export const IS_LANDSCAPE = window.innerHeight < window.innerWidth;
export const IS_WEB_TABLET = Platform.OS === 'web' && width > 750;
export const IS_SMALL_DEVICE = width < 700;
export const IS_TINY_DEVICE = width < 380;
export const IS_SUPER_TINY_DEVICE = width < 325;
export const IS_TABLET = isTablet() || IS_WEB_TABLET;
