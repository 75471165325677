import React from 'react'
import {Component} from 'react'
import {View} from 'react-native'
import styles from './styles'

class EmptyCard extends Component {
    render() {
        return (
            <View style={styles.container} />
        )
    }
}

export default EmptyCard
