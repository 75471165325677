import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../config/style'

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    animationContainer: {
        overflow: 'hidden',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 10
    },
    listItem: {
        height: 50,
        width: 220,
        resizeMode: 'contain',
        margin: 5
    },
    animationIconContainer: {
        height: 100,
        width: 100,
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        bottom: 0,
        margin: 10
    },
    icon: {
        height: 70,
        width: 70
    }

})