import { StyleSheet } from 'react-native';
import { IS_TABLET } from '../../../../helpers/device';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: 20,
  },
  containerLoading: {
    padding: 8,
  },
  textLoading: {
    fontSize: IS_TABLET ? 24 : 16,
  },
});
