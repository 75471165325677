import {StyleSheet, Platform} from 'react-native'
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        ...Platform.select({
            default: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 1,
                shadowRadius: 2,
                shadowOffset: {width: 0, height: 2},
                backgroundColor: 'white'
            },
            android: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 1,
                shadowRadius: 2,
                shadowOffset: {width: 0, height: 2},
                elevation: 3,
                backgroundColor: 'white'
            },
            web: {
                boxShadow: '0px 2px 5px 1px rgba(0,0,0,0.2)',
                backgroundColor: 'white'
            }
            
        }),
        marginBottom: 10,
        marginTop: 10,
        borderRadius: 5
    },
    standardWidth: {
        width: '90%'
    },
    fullWidth: {
        width: '100%'
    },
    header: {
        minHeight: 50,
        // backgroundColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: 10,
    },
    headerText: {
        flex: 1,
        color: TEXT_COLORS.normal,
        fontFamily: 'clan-pro-narr-book',
        fontSize: 25
    },
    arrow: {
        height: 35,
        width: 35
    },
    content: {
        // padding: 10
        // margin: 10
    },
    contentText: {
        padding: 10
    }
})