import { Action, ActionCreator, Dispatch} from 'redux'
import { Types } from './types'
import { ShoppingList, RawShoppingList, ShoppingIngredient, Ingredient } from '../../model';

export interface IAddShoppingList extends Action {
    type: Types.ADD_SHOPPING_LIST,
    shoppingList: RawShoppingList
}

export interface IRemoveShoppingList extends Action {
    type: Types.REMOVE_SHOPPING_LIST,
    id: number
}

export interface ISetCurrentShoppingList extends Action {
    type: Types.SET_CURRENT_SHOPPING_LIST,
    id: number
}

export interface IAddToShoppingList extends Action {
    type: Types.ADD_TO_SHOPPING_LIST,
    id: number,
    ingredients: ShoppingIngredient[]
}

export interface IUpdateShoppingList extends Action {
    type: Types.UPADTE_SHOPPING_LIST,
    shoppingList: ShoppingList
}

export type IShoppingListAction = IAddShoppingList | IRemoveShoppingList | ISetCurrentShoppingList | IAddToShoppingList | IUpdateShoppingList

export const updateShoppingListAction: ActionCreator<IUpdateShoppingList> = (shoppingList: ShoppingList) => {
    return {
        type: Types.UPADTE_SHOPPING_LIST,
        shoppingList: shoppingList
    }
}

export function updateShoppingList(shoppingList: ShoppingList) {
    return async (dispatch: Dispatch) => {
        dispatch(updateShoppingListAction(shoppingList))
    }
}

export const addToShoppingListAction: ActionCreator<IAddToShoppingList> = (id: number, ingredients: ShoppingIngredient[]) => {
    return {
        type: Types.ADD_TO_SHOPPING_LIST,
        id: id,
        ingredients: ingredients
    }
}

export function addToShoppingList(id: number, s: ShoppingIngredient[]) {
    return async (dispatch: Dispatch) => {
        dispatch(addToShoppingListAction(id, s))
    }
}

export const addShoppingListAction: ActionCreator<IAddShoppingList> = (shoppingList: RawShoppingList) => {
    return {
        type: Types.ADD_SHOPPING_LIST,
        shoppingList: shoppingList
    }
}

export function addShoppingList(shoppingList: RawShoppingList) {
    return async (dispatch: Dispatch) => {
        dispatch(addShoppingListAction(shoppingList))
    }
}

export const removeShoppingListAction: ActionCreator<IRemoveShoppingList> = (id: number) => {
    return {
        type: Types.REMOVE_SHOPPING_LIST,
        id: id
    }
}

export function removeShoppingList(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(removeShoppingListAction(id))
    }
}

export const setCurrentShoppingListAction: ActionCreator<ISetCurrentShoppingList> = (id: number) => {
    return {
        type: Types.SET_CURRENT_SHOPPING_LIST,
        id: id
    }
}

export function setCurrentShoppingList(id: number) {
    return async (dispatch: Dispatch) => {
        dispatch(setCurrentShoppingListAction(id))
    }
}

