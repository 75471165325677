import { StyleSheet } from 'react-native';
import { PRIMARY_THEME_COLORS } from '../../config/style';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  tipContainer: {
    maxWidth: 300,
    padding: 10,
    backgroundColor: 'white',
    marginLeft: 80,
    margin: 10,
    borderRadius: 2,
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonContainer: {
    flexDirection: 'column',
  },
  text: {
    marginBottom: 20,
  },
  arrow: {
    width: 20,
    height: 30,
    position: 'absolute',
    top: 5,
    left: -30,
  },
  divider: {
    height: 16,
    width: 1,
  },
  buttonSecond: {
    backgroundColor: PRIMARY_THEME_COLORS.green,
  },
});
