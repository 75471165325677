import React from 'react';
import { Component } from 'react';
import styles from './styles';
import MfModal, { animationTypes } from '../mf_modal/index.native';
import { View, Image, ImageSourcePropType } from 'react-native';
import MfText, { TEXT_STYLES } from '../mf_text';
import arrow from '../../../assets/ui/tip_arrow.png';
import { SafeAreaView } from 'react-navigation';
import Button from '../button/button';
import { getText } from '../../helpers/text/translation';

type props = {
  visible: boolean;
  onClose: () => void;
  onHide: () => void;
};

const MenuTip = ({ visible, onClose, onHide }: props) => {
  return (
    <MfModal
      visibile={visible}
      transparent
      animationType={animationTypes.FADE}
      onClose={onClose}
    >
      <View style={styles.container}>
        <SafeAreaView>
          <View style={styles.tipContainer}>
            <Image style={styles.arrow} source={arrow as ImageSourcePropType} />
            <View>
              <MfText textStyle={TEXT_STYLES.BOLD_PLAIN}>Tips!</MfText>
              <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {getText(
                  'Tryck på strecken för att få information och se film om hur appen MatFröjd används'
                )}
              </MfText>
            </View>
            <View style={styles.buttonContainer}>
              <Button onPress={onClose} title={'Visa inte igen'} />
              <View style={styles.divider} />
              <Button
                style={styles.buttonSecond}
                onPress={onHide}
                title={'Okej!'}
              />
            </View>
          </View>
        </SafeAreaView>
      </View>
    </MfModal>
  );
};

export default MenuTip;
