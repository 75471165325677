import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { View, Image, ImageSourcePropType } from 'react-native';
import Touchable from '../touchable';
import MfText, { TEXT_STYLES } from '../mf_text';
import circle from '../../../assets/ui/circle.png'
import check from '../../../assets/mf_ui/bockav-ikon-8cb414.png'
import { TOUCHEABLE_TYPES } from '../touchable/touchable';

interface ICheckableItem {
    content?: string,
    customComponent?: JSX.Element
    checked: boolean,
    onPress: Function,
    style?: {},
    checkTouchable?: boolean,
    onCheckPress?: Function,
    preventUncheckedUpdate?: boolean
}

class CheckableItem extends Component<ICheckableItem> {

    shouldComponentUpdate(nextProps: ICheckableItem) {
        if (this.props.preventUncheckedUpdate) {
            if (nextProps.checked !== this.props.checked) return true
            if (nextProps.customComponent !== this.props.customComponent) return true
            return false
        }
        return true
    }
    onPress() {

    }
    onCheckPress() {
        if (this.props.onCheckPress) this.props.onCheckPress()
    }

    getIcon(checked: boolean) {
        const img = checked ? check : circle
        return (
            <View style={styles.imageContainer}>
                <Image style={styles.icon} source={img as ImageSourcePropType} />
            </View>
        )
        
    }

    getContent() {
        return (
            <View style={styles.contentContainer}>
            {this.props.customComponent ||
                <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{this.props.content}</MfText> || null}
            </View>
        )
    }
    render() {
        
        if (this.props.checkTouchable) {
            return (
                <Touchable type={TOUCHEABLE_TYPES.HIHGLIGHT} onPress={() => this.props.onPress()}>
                    <View style={[styles.container, this.props.style]}>
                        <Touchable type={TOUCHEABLE_TYPES.OPACITY} onPress={() => this.onCheckPress()}>
                            {this.getIcon(this.props.checked)}
                        </Touchable>
                        {this.getContent()}
                    </View>
                </Touchable>
            )
        }
        return (
            <Touchable fastResponse={true} onPress={() => this.props.onPress()}>
                <View style={[styles.container, this.props.style]}>
                    {this.getIcon(this.props.checked)}
                    {this.getContent()}
                </View>
            </Touchable>
        )
    }
}

export default CheckableItem
