import { IPantryAction } from '../actions';
import { Types } from '../actions/types';
import { IPantryState } from '../../model/state';

const initialState: IPantryState = {
  pantryItems: [],
};

// TODO, replace IngredientBase to pantryItem for ingredients/pantryIngredients

const reducer = (state = initialState, action: IPantryAction): IPantryState => {
  switch (action.type) {
    case Types.ADD_TO_PANTRY:
      return {
        ...state,
        pantryItems: [...state.pantryItems, ...action.pantryItems],
      };
    case Types.REMOVE_FROM_PANTRY:
      return {
        ...state,
        pantryItems: state.pantryItems.filter((ing) => ing.id !== action.id),
      };
    case Types.CLEAR_PANTRY:
      return {
        ...state,
        pantryItems: [],
      };
    default:
      return state;
  }
};

// export default persistReducer(config, reducer)
export default reducer;
