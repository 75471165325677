import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    container: {
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    imageContainer: {
        width: 35,
        height: 35,
        marginLeft: 5,
        marginRight: 5
    },
    icon: {
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: undefined,
        height: undefined,
        resizeMode: 'cover'
    },
    contentContainer: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10

    }
})