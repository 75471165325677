import React from 'react'
import {Component} from 'react'
import {Animated, Text, StyleSheet, View} from 'react-native'
// import styles from './styles'

import { IMfSwipeable } from './i_mf_swipeable';

class MfSwipeable extends Component<IMfSwipeable> {
      render() {
        return (
            <View>
                {this.props.children}
            </View>
        );
      }
}

export default MfSwipeable
