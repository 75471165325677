import { Action, ActionCreator} from 'redux'
import { Types } from './types'

export interface IAddFavorite extends Action {
    type: Types.ADD_TO_FAVORITE,
    recipeId: number
}

export interface IRemoveFavorite extends Action {
    type: Types.REMOVE_FROM_FAVORITE,
    recipeId: number
}

export type IFavoriteAction = IAddFavorite | IRemoveFavorite

export const addToFavorites: ActionCreator<IAddFavorite> = (recipeId: number) => {
    return {
        type: Types.ADD_TO_FAVORITE,
        recipeId: recipeId
    }
}

export const removeFromFavorites: ActionCreator<IRemoveFavorite> = (recipeId: number) => {
    return {
        type: Types.REMOVE_FROM_FAVORITE,
        recipeId: recipeId
    }
}