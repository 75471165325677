import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { View, ScrollView } from 'react-native';
import { ICategoryState, IState } from '../../../model/state';
import { Recipe } from '../../../model';
import { IRecipeState } from '../../../model/state';
import {
  addToPantry,
  removeFromPantry,
  clearPantry,
  IAddAction,
  IRemoveAction,
  IClearAction,
  setRecipe,
} from '../../../redux/actions';
import MfContainer from '../../../components/mf_container';
import { backgrounds } from '../../../components/mf_container/mf_container';
import styles from './styles';
import Button, { BUTTON_VARIANTS } from '../../../components/button/button';
import AddToPantryModal from './add_to_pantry_modal';
import { getText } from '../../../helpers/text/translation';
import FindRecipeModal from './find_recipe_modal';
import MfText, { TEXT_STYLES } from '../../../components/mf_text';
import Alert from '../../../components/alert';
import Chip from '../../../components/chip';
import { EType, PantryItem } from '../../../components/search/types';
import { IS_TABLET } from '../../../helpers/device';

interface ReduxProps {
  pantryItems: PantryItem[];
  recipeState: IRecipeState;
  categoryState: ICategoryState;
}
export interface ReactProps {
  setTitle: Function;
  onNavigate: Function;
}
interface DispatchProps {
  setCurrentRecipe: Function;
  addToPantry: (pantryItem: PantryItem[]) => IAddAction;
  removeFromPantry: (id: number) => IRemoveAction;
  clearPantry: () => IClearAction;
}
interface FilterMap {
  [recipeId: number]: {
    numMatched: number;
  };
}
export interface FilterResult {
  recipes: Recipe[];
  filterMap: FilterMap;
}
type Props = ReduxProps & ReactProps & DispatchProps;
interface State {}

class PantryContainer extends Component<Props, State> {
  state = {
    showAddModal: false,
    showFindRecipeModal: false,
    showClearModal: false,
  };

  onAdd = async (addPantryItem: { [key: string]: PantryItem }) => {
    this.props.addToPantry(Object.values(addPantryItem));
    this.setState({ showAddModal: false });
  };

  async removePantryItem(id: number) {
    await this.props.removeFromPantry(id);
  }

  filterRecipes(pantryItems: PantryItem[], recipes: Recipe[]): FilterMap {
    const filterMap: FilterMap = {};
    //Init the filterMap with 0 matched ingredients, and number of total ingredients
    //Ignore alternatives for now
    recipes.forEach((recipe) => {
      let matchedCatId: { [key: string]: boolean } = {};
      recipe.categories.forEach((category) => {
        if (
          !matchedCatId[category.id] &&
          pantryItems.some(
            (pantryItem) =>
              pantryItem.type === EType.TAG &&
              pantryItem.id === category.id &&
              pantryItem.name.toLocaleLowerCase() ===
                category.name.toLocaleLowerCase()
          )
        ) {
          matchedCatId[category.id] = true;
          if (filterMap[recipe.id]) {
            filterMap[recipe.id].numMatched += 1;
          } else {
            filterMap[recipe.id] = {
              numMatched: 1,
            };
          }
        }
      });
    });
    return filterMap;
  }

  getRecipesForFind(
    pantryItems: PantryItem[],
    recipes: Recipe[]
  ): FilterResult {
    const filterMap = this.filterRecipes(pantryItems, recipes);
    const filteredRecipes = Object.keys(filterMap).reduce(
      (acc: Recipe[], id: string) => {
        const recipe = recipes.find((recipe) => recipe.id === parseInt(id));
        if (
          recipe &&
          filterMap[parseInt(id)].numMatched === pantryItems.length
        ) {
          acc.push(recipe);
        }
        return acc;
      },
      []
    );
    return {
      recipes: filteredRecipes,
      filterMap: filterMap,
    };
  }

  async recipeSelected(id: number, name: string) {
    await this.props.setCurrentRecipe(id);
    this.setState({ showFindRecipeModal: false });
    this.props.setTitle(name);
    this.props.onNavigate();
  }

  clear() {
    this.setState({ showClearModal: true });
  }

  onCloseClearModal() {
    this.setState({ showClearModal: false });
  }

  onClearAccept() {
    this.props.clearPantry();
  }

  renderEmptyPantryText() {
    return (
      <View style={styles.emptyContainer}>
        <MfText textStyle={TEXT_STYLES.SUB_HEADER}>
          {getText('Vilka livsmedel vill du laga mat med?')}
        </MfText>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText(
            'Lägg till en eller flera ingredienser genom att trycka på +'
          )}
        </MfText>
        {IS_TABLET && (
          <View style={styles.containerActionButtonTablet}>
            <Button
              style={styles.actionButtonTablet}
              onPress={() => this.setState({ showAddModal: true })}
              title="+"
            />
          </View>
        )}
      </View>
    );
  }

  render() {
    const { showAddModal, showFindRecipeModal } = this.state;
    const { recipeState, pantryItems } = this.props;
    const filterResult =
      recipeState.recipes?.length > 0
        ? this.getRecipesForFind(pantryItems, recipeState.recipes)
        : { recipes: [], filterMap: {} };

    return (
      <MfContainer backgroundImage={backgrounds.PANTRY}>
        <Alert
          title={getText('Rensa')}
          content={getText('Vill du verkligen rensa hela skafferiet?')}
          onClose={() => this.onCloseClearModal()}
          onAccept={() => this.onClearAccept()}
          onDenied={() => this.onCloseClearModal()}
          visible={this.state.showClearModal}
        />
        <View>
          {pantryItems.length > 0 ? (
            <ScrollView contentContainerStyle={styles.container}>
              {pantryItems.map((i) => (
                <Chip
                  key={i.id}
                  term={i.name}
                  onPress={() => this.removePantryItem(i.id)}
                />
              ))}
            </ScrollView>
          ) : (
            this.renderEmptyPantryText()
          )}
        </View>
        <View style={styles.spacer} />
        <View style={styles.bottomButtonContainer}>
          <Button
            variant={BUTTON_VARIANTS.NO_RADIUS}
            containerStyle={styles.buttonBottom}
            style={styles.buttonLeft}
            title={getText('Rensa')}
            onPress={() => this.clear()}
          />
          <Button
            style={styles.buttonRight}
            containerStyle={styles.buttonBottom}
            variant={BUTTON_VARIANTS.NO_RADIUS}
            onPress={() => this.setState({ showFindRecipeModal: true })}
            title={`${getText('Hitta recept')} (${
              filterResult.recipes.length
            })`}
          />
        </View>
        {IS_TABLET && pantryItems.length > 0 && (
          <View style={[styles.emptyContainer, styles.actionText]}>
            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {getText('Tryck på + för att söka på fler ingredienser')}
            </MfText>
          </View>
        )}
        {(!IS_TABLET || (IS_TABLET && pantryItems.length > 0)) && (
          <Button
            bottom={100}
            style={styles.actionButton}
            variant={BUTTON_VARIANTS.FLOATING_ACTION}
            onPress={() => this.setState({ showAddModal: true })}
            title="+"
          />
        )}
        <AddToPantryModal
          visible={showAddModal}
          onAdd={this.onAdd}
          onClose={() => this.setState({ showAddModal: false })}
          categories={this.props.categoryState.categories}
          currentPantryItems={this.props.pantryItems}
        />
        <FindRecipeModal
          visible={showFindRecipeModal}
          onSelected={(id: number, name: string) =>
            this.recipeSelected(id, name)
          }
          filterResult={filterResult}
          onClose={() => this.setState({ showFindRecipeModal: false })}
        />
      </MfContainer>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    pantryItems: state.pantryState.pantryItems,
    recipeState: state.recipeState,
    categoryState: state.categoryState,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  const props: DispatchProps = {
    setCurrentRecipe: (id: number) => dispatch(setRecipe(id)),
    addToPantry: (pantryItem: PantryItem[]) =>
      dispatch(addToPantry(pantryItem)),
    removeFromPantry: (id: number) => dispatch(removeFromPantry(id)),
    clearPantry: () => dispatch(clearPantry()),
  };
  return props;
};
export default connect<ReduxProps, DispatchProps, ReactProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(PantryContainer);
