import { StyleSheet } from 'react-native';
import { PRIMARY_THEME_COLORS } from '../../config/style';

export default StyleSheet.create({
  containerExplanation: {
    paddingHorizontal: 16,
    paddingVertical: 32,
  },
  spacer: {
    height: 8,
  },
  linkExplanation: {
    textDecorationLine: 'underline',
    color: PRIMARY_THEME_COLORS.green,
  },
});
