import React from 'react';
import { Component } from 'react';
import { Image } from 'react-native';
import styles from './styles';
import { INavButton } from './i_nav_button';
import Touchable from '../../touchable';

class NavButton extends Component<INavButton> {
  render() {
    return (
      <div>
        {this.props.onPress == undefined ? (
          <Image
            style={styles.icon}
            source={this.props.icon}
          />
        ) : (
          <Touchable
            style={styles.container}
            onPress={this.props.onPress as Function}
          >
            <Image
              style={styles.iconWeb}
              source={{ uri: this.props.icon }}
            />
          </Touchable>
        )}
      </div>
    );
  }
}

export default NavButton;
