import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS, TEXT_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        padding: 10,
        minWidth: 50,
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    checked: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes
    },
    notChecked: {
        backgroundColor: '#a6a6a6'
    },
    text: {
        color: TEXT_COLORS.light,
        marginRight: 10
    },
    largeText: {
        fontSize: 20,
        fontFamily: 'clan-pro-narr-bold',
    },
    check: {
        height: 20,
        width: 20
    },
    unCheck: {
        height: 20,
        width: 20,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: 'white'
    }
})