import { Action, ActionCreator, Dispatch} from 'redux'
import { Types } from './types';


export interface ISetShowDownloadQuestion extends Action {
    type: Types.SET_SHOW_DOWNLOAD_QUESTION
    showDownloadQuestion: boolean
}

export interface ISetFirstTime extends Action {
    type: Types.SET_FIRST_TIME
    firstTime: boolean
}

export type IConfigAction = ISetShowDownloadQuestion | ISetFirstTime

export const setShowDownloadQuestion: ActionCreator<IConfigAction> = (show: boolean) => {
    return {
        type: Types.SET_SHOW_DOWNLOAD_QUESTION,
        showDownloadQuestion: show
    }
}

export const setFirstTime: ActionCreator<IConfigAction> = (firstTime: boolean) => {
    return {
        type: Types.SET_FIRST_TIME,
        firstTime: firstTime
    }
}