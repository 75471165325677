import {StyleSheet} from 'react-native'
import { WINDOW_SIZE, PRIMARY_THEME_COLORS } from '../../../../../config/style';

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
    contentContainer: {
        margin: 20,
        backgroundColor: 'white',
        alignItems: 'center',
        width: 300
    },
    headerContainer: {
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image: {
        width: 35,
        height: 35
    },
    headerRight: {
        width: 35,
        height: 35
    },
    inputContainer: {

    },
    
    input: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 10,
        minWidth: 200
    },
    buttonContainer: {
        margin: 20
    },
    addButton: {
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList
    }
})