import { IConfigAction } from "../actions"
import { Types } from '../actions/types'
import { IConfigState } from '../../model/state'
import { persistReducer } from "redux-persist"
import MFStorage from "./storage"

const initialState: IConfigState = {
    showDownloadQuestion: true,
    firstTime: true
}

const config = {
    key: 'configState',
    storage: MFStorage,
    // blacklist: ['showDownloadQuestion']
}

const reducer =  (state = initialState, action: IConfigAction): IConfigState => {
    switch (action.type) {
        case Types.SET_SHOW_DOWNLOAD_QUESTION:
            return { 
                ...state, 
                showDownloadQuestion: action.showDownloadQuestion
                
            }
        case Types.SET_FIRST_TIME:
            return {
                ...state,
                firstTime: action.firstTime
            }
        default: return state
    }
}

export default persistReducer(config, reducer)
