import React from 'react';
import { Component } from 'react';
import { View } from 'react-native';
import { IMenuButton } from './i_menu';

class MenuButton extends Component<IMenuButton> {
  render() {
    return <View />;
  }
}

export default MenuButton;
