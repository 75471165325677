import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../../../../config/style';

export default StyleSheet.create({
    headerContainer: {
        width: '100%',
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerRight: {
        width: 35,
        height: 35
    },
    image: {
        width: 35,
        height: 35
    },
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    contentContainer: {
        height: '100%',
        backgroundColor: 'white',
        alignItems: 'center'
    },
    searchItem: {
        minHeight: 40,
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'grey',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchContainer: {
        width: '100%',
        flex: 2,
        backgroundColor: 'white'
    },
    footer: {
        // height: 100,
        backgroundColor: 'green'
    },
    button: {
        backgroundColor: PRIMARY_THEME_COLORS.inventory,
        height: 70,
        width: WINDOW_SIZE.width
    },
    input: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        padding: 10,
        width: '100%'
    }
})