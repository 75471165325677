import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  flatList: {
    width: '100%',
  },
  containerFlatList: {
    alignItems: 'center',
    paddingVertical: 20,
  },
  filterButtonC: {
    marginBottom: 20,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
  },

  emptyResult: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
  },
});
