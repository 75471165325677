import AsyncStorage from '@react-native-community/async-storage';
import fetch from 'cross-fetch'
import {DATA_TIMESTAMP_URL, PROD_DATA} from '../../config'
import {fetchRecipes, fetchCategories, fetchIngredients, fetchNutritions, fetchMenu} from '../../redux/actions'
import {store} from '../../config/store'

const TIMESTAMP_KEY = 'timestamp_key'

async function getNewTimestamp() {
    return await (await fetch(DATA_TIMESTAMP_URL, {
        mode: "cors", 
        cache: "no-store",
        headers: {
            'Cache-Control': 'no-cache',
            'pragma': 'no-cache'
        }
    })).text()
}

export async function setTimestamp() {
    const new_timestamp = await getNewTimestamp()
    await AsyncStorage.setItem(TIMESTAMP_KEY, new_timestamp)
}

export async function hasTimeStamp() {
    const old_timestamp = await AsyncStorage.getItem(TIMESTAMP_KEY)
    return old_timestamp !== null
}

export async function shouldFetch(): Promise<boolean> {
    if (!PROD_DATA) return false
    try {
        const old_timestamp = await AsyncStorage.getItem(TIMESTAMP_KEY)
        const new_timestamp = await getNewTimestamp()
        let should_fetch = false

        if(!old_timestamp) {
            should_fetch = true
        } else {
            if (parseInt(old_timestamp) < parseInt(new_timestamp)) {
                should_fetch = true
            }
        }
        return should_fetch
    } catch(e) {
        console.log("Error")
    }
    return false
}

export async function fetchData() {
    await setTimestamp()
    await store.dispatch(<any>fetchMenu())
    await store.dispatch(<any>fetchRecipes())
    await store.dispatch(<any>fetchCategories())
    await store.dispatch(<any>fetchIngredients())
    await store.dispatch(<any>fetchNutritions())
}