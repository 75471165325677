import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    container: {
        width: 30,
        height: 30
    },
    icon: {
        flex: 1,
        width: undefined,
        height: undefined,
        resizeMode: 'cover'
    },
    web: {
        backgroundColor: 'pink'
    },
})