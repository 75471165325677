export enum IngredientKeys {
  MEASURE = 'measure',
  MEASURE_FULL = 'measureFull',
  MEASURE_FULL_PLURAL = 'measureFullPlural',
  MEASURE_PLURAL = 'measurePlural',
  MEASURE_SHOP = 'measureShop',
  QUANTITY = 'quantity',
  // MEASURE_FULL_PLURAL_SHOP = 'measureFullPluralShop', // NOT USED IN CODE BUT EXISRTS DB
  // MEASURE_FULL_SHOP = 'measureFullShop', // NOT USED IN CODE BUT EXISRTS DB
  // MEASURE_PLURAL_SHOP = 'measurePluralShop', // NOT USED IN CODE BUT EXISRTS DB
}
