import React from 'react'
import {PureComponent} from 'react'
import styles from './styles'
import { View, RegisteredStyle } from 'react-native';
import Touchable from '../touchable';
import MfText, { TEXT_STYLES } from '../mf_text';

interface IFlatListItem {
    onPress: () => void,
    title: string,
    subTitle?: string,
    action?: JSX.Element,
    style?: Object
}

class FlatListItem extends PureComponent<IFlatListItem> {
    render() {
        const {title, subTitle, action, style} = this.props
        return (
            <Touchable onPress={() => this.props.onPress()}>
                <View style={[styles.container, style]}>
                    <View style={styles.textContainer}>
                        <MfText style={styles.title} textStyle={TEXT_STYLES.SUB_HEADER}>{title}</MfText>
                        {subTitle ? <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{subTitle}</MfText> : null}
                    </View>
                    <View style={styles.actionContainer}>
                        {action || null}
                    </View>
                    
                </View>
            </Touchable>
        )
    }

}

export default FlatListItem
