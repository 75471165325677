import { StyleSheet } from 'react-native';
import { WINDOW_SIZE, PRIMARY_THEME_COLORS } from '../../../../config/style';
import { IS_TABLET } from '../../../../helpers/device';

const buttonHeight = 80;

export default StyleSheet.create({
  buttonLeft: {
    backgroundColor: PRIMARY_THEME_COLORS.variation,
    height: buttonHeight,
    minWidth: '50%',
    maxWidth: IS_TABLET ? 250 : WINDOW_SIZE.width / 2,
    margin: 0,
  },
  buttonLeftTablet: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  buttonRight: {
    backgroundColor: PRIMARY_THEME_COLORS.recipes,
    height: buttonHeight,
    minWidth: '50%',
    maxWidth: IS_TABLET ? 250 : WINDOW_SIZE.width / 2,
    margin: 0,
  },
  singleButtonRight: {
    backgroundColor: PRIMARY_THEME_COLORS.recipes,
    height: buttonHeight,
    width: WINDOW_SIZE.width,
  },
  buttonRightTablet: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
  },
  singleButtonRightTablet: {
    borderRadius: 50,
  },

  buttonContainer: {
    height: buttonHeight,
    flexDirection: 'row',
  },
  buttonContainerTablet: {
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    justifyContent: 'center',
  },
  buttonContainerWeb: {
    height: buttonHeight,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonWidthWeb: {
    width: IS_TABLET ? 200 : '50vw',
  },
});
