import { createStore, applyMiddleware } from 'redux';
import { persistStore} from 'redux-persist'
import thunkMiddleware from 'redux-thunk'

import persistReducer from '../../redux/reducers'
import { IFetchAction, getRecipes, getCategories, getIngredients, getNutritions, getMenu } from '../../redux/actions'
import { setTimestamp, hasTimeStamp } from '../../helpers/version_handler'
import { PROD_DATA } from '..'

export const store = createStore(persistReducer, applyMiddleware(thunkMiddleware))
export const persistor = persistStore(store, undefined, async () => {
    if (PROD_DATA && !hasTimeStamp) await setTimestamp()
    await store.dispatch(<any>getMenu())
    await store.dispatch(<any>getRecipes())
    await store.dispatch(<any>getCategories())
    await store.dispatch(<any>getIngredients())
    await store.dispatch(<any>getNutritions())
})
