import React from 'react';
import ShoppingListContainer from './shopping_list_container';
import { Screen } from '../../../interface';
import { Platform } from 'react-native';
import { PRIMARY_THEME_COLORS } from '../../../config/style';
import { NavigationScreenProps } from 'react-navigation';
import DeleteButton from '../../../components/nav_buttons/delete_button';
import { IS_TABLET } from '../../../helpers/device';
import PopupMenu from '../../../components/popup-menu';
import { getText } from '../../../helpers/text/translation';
import { ICONS } from '../../../components/popup-menu/index.native';
import { Text } from 'react-native';
import Touchable from '../../../components/touchable';
import routes from '../../../navigation/routes';

const route = routes.shoppingListDetail.name;

class ShoppingLists extends Screen {
  state = {
    navTitle: '',
    triggerShare: false,
    triggerDelete: false,
    triggerRename: false,
  };

  static navigationOptions = ({ navigation }: NavigationScreenProps) => {
    const doneDelStyles = {
      container: {
        borderRadius: 10,
        borderColor: 'white',
        borderWidth: 1,
        marginRight: 10,
        padding: 5,
      },
      text: {
        color: 'white',
        fontSize: 20,
      },
    };
    return {
      // headerRight: IS_TABLET ? <ShareButton onPress={navigation.getParam('buttonOnPress')} /> : null,
      headerRight: IS_TABLET ? (
        <PopupMenu
          options={[
            {
              title: getText('Dela'),
              icon: ICONS.SHARE,
              onSelect: navigation.getParam('shareOnPress'),
            },
            {
              title: getText('Ändra namn'),
              icon: ICONS.EDIT,
              onSelect: navigation.getParam('renameOnPress'),
            },
            {
              title: getText('Radera'),
              icon: ICONS.DELETE,
              onSelect: navigation.getParam('deleteOnPress'),
            },
          ]}
          id={'shopping-more'}
        />
      ) : navigation.getParam('isDeleting') ? (
        <Touchable
          style={doneDelStyles.container}
          onPress={navigation.getParam('onDeleteCancel')}
        >
          <Text style={doneDelStyles.text}>Klar</Text>
        </Touchable>
      ) : (
        <DeleteButton onPress={navigation.getParam('deleteOnPress')} />
      ),
    };
  };

  componentDidMount() {
    if (Platform.OS === 'web') {
      this.props.webNavigation.setNavBarBackgroundColor(
        PRIMARY_THEME_COLORS.shoppingList
      );
      this.props.webNavigation.setHeaderRight(
        IS_TABLET ? (
          <PopupMenu
            options={[
              {
                title: getText('Dela'),
                icon: ICONS.SHARE,
                onSelect: this.onTriggerShare,
              },
              {
                title: getText('Ändra namn'),
                icon: ICONS.EDIT,
                onSelect: this.onTriggerRename,
              },
              {
                title: getText('Radera'),
                icon: ICONS.DELETE,
                onSelect: this.onTriggerDelete,
              },
            ]}
            id={'shopping-more'}
          />
        ) : null
      );
    } else {
      this.props.navigation.setParams({
        shareOnPress: this.onTriggerShare,
        renameOnPress: this.onTriggerRename,
        deleteOnPress: this.onTriggerDelete,
        onDeleteCancel: this.onDeleteCancel,
        isDeleting: false,
      });
    }
  }

  navigate() {
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, this.state.navTitle);
    } else {
      this.props.navigation.navigate(route, {
        title: this.state.navTitle,
      });
    }
  }

  onTriggerShare = () => {
    this.setState({ triggerShare: true });
  };

  onShareRespond = () => {
    this.setState({ triggerShare: false });
  };

  setTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  onTriggerDelete = () => {
    this.setState({ triggerDelete: true });
    if (Platform.OS !== 'web')
      this.props.navigation.setParams({ isDeleting: true });
  };

  onDeleteCancel = () => {
    this.setState({ triggerDelete: false });
    if (Platform.OS !== 'web')
      this.props.navigation.setParams({
        isDeleting: false,
      });
  };

  onDeleteRespond = () => {
    this.setState({ triggerDelete: false });
    if (Platform.OS !== 'web')
      this.props.navigation.setParams({
        isDeleting: false,
      });
  };

  onTriggerRename = () => {
    this.setState({ triggerRename: true });
  };

  onRenameRespond = () => {
    this.setState({ triggerRename: false });
  };

  render() {
    return (
      <ShoppingListContainer
        triggerShare={this.state.triggerShare}
        triggerDelete={this.state.triggerDelete}
        triggerRename={this.state.triggerRename}
        onShareRespond={this.onShareRespond}
        onDeleteRespond={this.onDeleteRespond}
        onRenameRespond={this.onRenameRespond}
        onNavigate={() => this.navigate()}
        setTitle={this.setTitle}
      />
    );
  }
}

export default ShoppingLists;
