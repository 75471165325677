import React from 'react';
import { Component } from 'react';
import styles from './styles';
import { Animated } from 'react-native';

interface IAnimatedPosition {
  toX: number;
  toY: number;
  duration: number;
  delay: number;
  curve?: boolean;
  onAnimationEnd: Function;
}

class AnimatedPosition extends Component<IAnimatedPosition> {
  state = {
    positionX: new Animated.Value(0),
    positionY: new Animated.Value(0),
  };
  componentDidMount() {
    this.animate();
  }

  animate() {
    setTimeout(() => {
      this.animateX();
      this.animateY();
    }, this.props.delay);
  }

  animateX() {
    const { toX, duration, curve } = this.props;
    const first = Math.sign(toX) >= 0 ? 50 : -50;
    Animated.sequence([
      Animated.timing(this.state.positionX, {
        toValue: curve ? first : 0,
        duration: curve ? duration : 0,
        useNativeDriver: true,
      }),
      Animated.timing(this.state.positionX, {
        toValue: toX,
        duration: duration,
        useNativeDriver: true,
      }),
    ]).start();
  }

  animateY() {
    const { toY, duration, curve } = this.props;
    const first = Math.sign(toY) >= 0 ? 50 : -50;
    Animated.sequence([
      Animated.timing(this.state.positionY, {
        toValue: curve ? first : 0,
        duration: curve ? duration : 0,
        useNativeDriver: true,
      }),
      Animated.timing(this.state.positionY, {
        toValue: toY,
        duration: duration,
        useNativeDriver: true,
      }),
    ]).start(() => {
      this.props.onAnimationEnd();
    });
  }

  render() {
    return (
      <Animated.View
        style={{
          transform: [
            { translateX: this.state.positionX },
            { translateY: this.state.positionY },
          ],
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export default AnimatedPosition;
