import React from 'react';
import { Component } from 'react';
import { View, ScrollView, Platform } from 'react-native';
import { IContainer } from '../../../../interface';
import { ShoppingList, RawShoppingList } from '../../../../model';
import { IState } from '../../../../model/state';
import {
  addShoppingList,
  removeShoppingList,
  setCurrentShoppingList,
} from '../../../../redux/actions';
import { connect } from 'react-redux';
import ListItem from '../../../../components/flat_list_item';
import styles from './styles';
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import AddShoppingListModal from './add_shopping_list_modal';
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import Alert from '../../../../components/alert';
import { getText } from '../../../../helpers/text/translation';
import MfSwipeable from '../../../../components/mf_swipeable';
import { PRIMARY_THEME_COLORS } from '../../../../config/style';
import ShoppingListDetailContainer from '../../shopping_list_detail/shopping_list_detail_container';
import AnimatedOpacity from '../../../../components/animated_opacity';
import AnimatedListItem from '../../../../components/animated_list_item';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';

interface ReduxProps {
  shoppingLists: ShoppingList[];
  currentShoppingList: ShoppingList | null;
}
interface DispatchProps {
  addShoppingList: Function;
  removeShoppingList: Function;
  setCurrentShoppingList: Function;
}
export interface ReactProps extends IContainer {
  triggerShare: boolean;
  onShareRespond: () => void;
  triggerRename: boolean;
  onRenameRespond: () => void;
  triggerDelete: boolean;
  onDeleteRespond: () => void;
}
type Props = ReduxProps & ReactProps & DispatchProps;
interface State {}

class ShoppingListContainerTablet extends Component<Props, State> {
  state = {
    showAddModal: false,
    showDeleteListModal: false,
    deleteId: null,
    hasLists: false,
    deleteList: false,
  };
  componentDidMount() {
    this.props
      .setCurrentShoppingList(null)
      .then(() => this.setInitialCurrentList());
  }
  itemOnPress(id: number) {
    this.props.setCurrentShoppingList(id);
    this.setState({ deleteId: id });
  }
  setInitialCurrentList(deleted?: boolean) {
    const { shoppingLists, currentShoppingList } = this.props;
    if (currentShoppingList === null || deleted) {
      if (shoppingLists.length > 0) {
        this.setState({ deleteId: shoppingLists[0].id });
        this.props
          .setCurrentShoppingList(shoppingLists[0].id)
          .then(() => this.setState({ hasLists: true }));
      } else {
        this.setState({ hasLists: false });
      }
    } else {
      this.setState({ hasLists: true });
    }
  }

  onCreateNewList(title: string) {
    const s: RawShoppingList = {
      title: title,
      ingredients: [],
    };
    this.props.addShoppingList(s).then(() => {
      this.setState({ showAddModal: false });
      this.setInitialCurrentList();
    });
  }

  onDeleteAnimation() {
    this.setState({ deleteList: true });
  }
  onDeleteAnimationEnd() {
    this.setState({ deleteList: false });
  }
  onDelete() {
    const { removeShoppingList, setCurrentShoppingList } = this.props;
    this.onDeleteAnimationEnd();
    removeShoppingList(this.state.deleteId).then(() => {
      setCurrentShoppingList(null).then(() => this.setInitialCurrentList(true));
      this.props.onDeleteRespond();
    });
  }
  onCloseDeleteModal() {
    this.setState({ showDeleteListModal: false });
    this.props.onDeleteRespond();
  }
  showDeleteModal(id: number) {
    this.setState({ deleteId: id }, () => {
      this.setState({ showDeleteListModal: true });
    });
  }

  sortShoppingLists(s: ShoppingList[]) {
    return s.sort((a, b) => {
      if (a.creationDate < b.creationDate) return 1;
      if (a.creationDate > b.creationDate) return -1;
      return 0;
    });
  }

  render() {
    const { shoppingLists, currentShoppingList, triggerDelete } = this.props;
    const { hasLists, deleteList, deleteId } = this.state;
    return (
      <MfContainer
        backgroundImage={backgrounds.SHOPPING_LIST}
        style={styles.container}
      >
        <Alert
          title={getText('Ta bort')}
          content={getText('Vill du ta bort den här inköpslistan?')}
          onClose={() => this.onCloseDeleteModal()}
          onAccept={() => this.onDeleteAnimation()}
          onDenied={() => this.onCloseDeleteModal()}
          visible={this.state.showDeleteListModal || triggerDelete}
        />
        <AddShoppingListModal
          visible={this.state.showAddModal}
          onClose={() => this.setState({ showAddModal: false })}
          onCreate={(title: string) => this.onCreateNewList(title)}
        />
        <View style={styles.tabletContainer}>
          <View style={styles.tabletLeft}>
            <ScrollView
              scrollEventThrottle={4}
              contentContainerStyle={
                Platform.OS === 'web'
                  ? styles.contentContainerWeb
                  : styles.contentContainer
              }
            >
              {this.sortShoppingLists(shoppingLists).map((shoppingList) => {
                const isCurrent = currentShoppingList
                  ? currentShoppingList.id == shoppingList.id
                  : false;
                return (
                  <AnimatedListItem
                    deleteAnimation={shoppingList.id === deleteId && deleteList}
                    key={shoppingList.id}
                    onAnimationEnd={() => this.onDelete()}
                  >
                    <MfSwipeable
                      key={shoppingList.id}
                      title={getText('Ta bort')}
                      color={PRIMARY_THEME_COLORS.shoppingList}
                      onPress={() => this.showDeleteModal(shoppingList.id)}
                    >
                      <ListItem
                        onPress={() => this.itemOnPress(shoppingList.id)}
                        title={shoppingList.title}
                        style={isCurrent ? styles.selectedListItem : undefined}
                        subTitle={shoppingList.creationDate}
                      />
                    </MfSwipeable>
                  </AnimatedListItem>
                );
              })}
            </ScrollView>
            <Button
              style={styles.buttonTablet}
              textColor="white"
              variant={BUTTON_VARIANTS.NO_RADIUS}
              onPress={() => this.setState({ showAddModal: true })}
              title={getText('+ Skapa inköpslista')}
            />
          </View>
          {deleteList ? (
            <View style={styles.tabletRight}>
              <AnimatedOpacity
                onAnimationEnd={() => {}}
                duration={400}
                delay={0}
                reverse
              >
                {hasLists ? (
                  <ShoppingListDetailContainer
                    isTablet={true}
                    onNavigate={() => {}}
                    triggerShare={this.props.triggerShare}
                    onShareRespond={this.props.onShareRespond}
                    triggerDelete={false}
                    triggerRename={this.props.triggerRename}
                    onDeleteRespond={() => {}}
                    onRenameRespond={() => {}}
                    onDeleteGoBack={() => {}}
                    setTitle={() => {}}
                  />
                ) : null}
              </AnimatedOpacity>
            </View>
          ) : shoppingLists.length > 0 && currentShoppingList !== null ? (
            <View style={styles.tabletRight}>
              {hasLists ? (
                <ShoppingListDetailContainer
                  isTablet={true}
                  onNavigate={() => {}}
                  triggerShare={this.props.triggerShare}
                  onShareRespond={this.props.onShareRespond}
                  triggerDelete={false}
                  triggerRename={this.props.triggerRename}
                  onDeleteRespond={() => {}}
                  onRenameRespond={this.props.onRenameRespond}
                  onDeleteGoBack={() => {}}
                  setTitle={() => {}}
                />
              ) : null}
            </View>
          ) : (
            <View style={[styles.emptyContainerTablet]}>
              <MfText textStyle={TEXT_STYLES.SUB_HEADER}>
                {getText('Det finns inga inköpslistor')}
              </MfText>
              <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {getText(
                  "Skapa en ny inköpslista genom att trycka på '+ Skapa ny inköpslista'"
                )}
              </MfText>
            </View>
          )}
        </View>
      </MfContainer>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    shoppingLists: state.shoppingListState.shoppingLists,
    currentShoppingList: state.shoppingListState.currentShoppingList,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    addShoppingList: (s: ShoppingList) => dispatch(addShoppingList(s)),
    removeShoppingList: (id: number) => dispatch(removeShoppingList(id)),
    setCurrentShoppingList: (id: number) =>
      dispatch(setCurrentShoppingList(id)),
  };
};

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingListContainerTablet);
