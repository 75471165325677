import { INutritionAction } from "../actions"
import { Types } from '../actions/types'
import { INutritionState } from '../../model/state'
import { persistReducer } from "redux-persist";
import { TESTING } from "../../config"
import {Nutrition} from '../../model'
import MFStorage from "./storage";



const initialState: INutritionState = {
    isFetching: false,
    didFetch: false,
    dataLoaded: false,
    nutritions: []
}

//WARNING: REMOVE 'recipes' when deploying!
const config = {
    key: 'nutritionState',
    storage: MFStorage,
    blacklist: ['isFetching', 'didFetch', 'dataLoaded']
}

const reducer =  (state = initialState, action: INutritionAction): INutritionState => {
    switch (action.type) {
        case Types.REQUEST_NUTRITION:
            return {
                ...state,
                isFetching: true,
                didFetch: false,
                dataLoaded: false
            }
        case Types.RECIEVE_NUTRITION:
            return {
                ...state,
                isFetching: false,
                didFetch: true,
                dataLoaded: true,
                nutritions: action.nutritions
            }
        case Types.ERROR_NUTRITION:
            return {
                ...state,
                isFetching: false,
                didFetch: true,
                dataLoaded: false,
            }
        default: return state
    }
}


export default persistReducer(config, reducer)