import React, { Component } from 'react';
import styles from './styles'
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu';
import MoreButton from '../nav_buttons/more_button'
import {IPopupMenu} from './i_popup_menu'
import del from '../../../assets/ui/trash_black.png'
import {Icon} from 'react-native-elements'
import { Image,ImageSourcePropType } from 'react-native';
import MfText, { TEXT_STYLES } from '../mf_text';

export enum ICONS {
    DELETE = 'DELETE',
    SHARE = 'SHARE',
    EDIT = 'EDIT'
}

class PopupMenu extends Component<IPopupMenu> { 
    getIcon(icon: ICONS) {
        switch (icon) {
            case ICONS.SHARE:
                return <Icon type='material-community' size={25} name="share" color="black"/>
            case ICONS.EDIT:
                return <Icon type='material-community' size={25} name="pencil" color="black"/>
            case ICONS.DELETE:
                return <Icon type='material-community' size={25} name="delete" color="black"/>
                // <Image style={styles.icon} source={del as ImageSourcePropType} /> 
            default:
                return
        }
    }

    render() {
        return (
            <Menu>
            <MenuTrigger>
                <MoreButton/>    
            </MenuTrigger> 
            <MenuOptions customStyles={styles}>
                {this.props.options.map(
                    opt => <MenuOption 
                    key={opt.title} 
                    style={styles.item}
                    onSelect={opt.onSelect}>
                        {opt.icon && this.getIcon(opt.icon)}
                        <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>{opt.title}</MfText>
                    </MenuOption>
                )}
            </MenuOptions>
            </Menu>
        )
    }
}
export default PopupMenu