import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {Modal, Platform, View} from 'react-native'
import {IMfModal} from './i_mf_modal'
import ReactModal from 'react-modal'

export enum animationTypes {
    NONE = 'none',
    SLIDE = 'slide',
    FADE = 'fade'
}


class MfModal extends Component<IMfModal> {
    render() {
        return (
            <Modal transparent={this.props.transparent} visible={this.props.visibile} animationType={this.props.animationType} onRequestClose={this.props.onClose}>
                {this.props.children}
            </Modal>
        )
    }
        
    
}

export default MfModal
