import { StyleSheet, Platform } from 'react-native';
import { CARD_SIZE, CARD_IMAGE_SIZE } from '../../../../../config/style';

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    position: 'absolute',
    top: Platform.OS === 'web' ? 0 : 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
    resizeMode: 'cover',
  },
  actionContainer: {
    height: CARD_SIZE.height - CARD_IMAGE_SIZE.height,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  actionIcon: {
    height: 35,
    width: 35,
  },
});
