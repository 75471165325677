import React from 'react';
import recipeStack from './stacks/recipeStack';
import instructionStack from './stacks/instructionStack';
import infoStack from './stacks/infoStack';
import settingStack from './stacks/settingStack';
import movieStack from './stacks/movieStack';
import routes from './routes';
import privacyPolicyStack from './stacks/privacyPolicyStack';
import { IScreen } from '../interface';

export const Routes = routes;

// TODO: Check so that IStack is not interfering with react-navigation (?)

export type IStack = {
  [key: string]: {
    navigationOptions: {
      title: string;
      webRoute: string;
      headerBackTitle: null;
      headerLeft?: any;
      headerTitleStyle?: any;
      headerTintColor?: string;
      headerStyle?: any;
    };
    screen: typeof React.Component | React.FC<IScreen>;
    isRoot: boolean;
    isStackRoot: boolean;
  };
};

export type IStacks = {
  [key: string]: {
    screen: IStack;
    navigationOptions: {
      title: string;
    };
    rootRoute: string;
  };
};

export const Stacks = {
  RecipeStack: {
    screen: recipeStack,
    navigationOptions: {
      title: routes.start.title,
      customIcon: 'en ikon',
    },
    rootRoute: '/',
  },
  InstructionStack: {
    screen: instructionStack,
    navigationOptions: {
      title: routes.instructions.title,
    },
    rootRoute: routes.instructions.route,
  },
  MovieStack: {
    screen: movieStack,
    navigationOptions: {
      title: routes.movies.title,
    },
    rootRoute: routes.movies.route,
  },
  SettingStack: {
    screen: settingStack,
    navigationOptions: {
      title: routes.generalSettings.title,
    },
    rootRoute: routes.generalSettings.route,
  },
  InfoStack: {
    screen: infoStack,
    navigationOptions: {
      title: routes.information.title,
    },
    rootRoute: routes.information.route,
  },
  PrivacyPolicyStack: {
    screen: privacyPolicyStack,
    navigationOptions: {
      title: routes.privacyPolicy.title,
    },
    rootRoute: routes.privacyPolicy.route,
  },
};
