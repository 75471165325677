export enum ERoutes {
  START = 'Start',
  CATEGORIES = 'Categories',
  PANTRY = 'Pantry',
  RECIPE_LIST = 'RecipeList',
  SHOPPING_LIST = 'ShoppingList',
  RECIPE_OVERVIEW = 'RecipeOverview',
  RECIPE_INSTRUCTIONS = 'RecipeInstructions',
  FAVORITES = 'Favorites',
  SHOPPING_LIST_DETAIL = 'ShoppingListDetail',
  SEARCH = 'Search',
  INFORMATION = 'Information',
  GENERAL_SETTINGS = 'GeneralSettings',
  MOVIES = 'Movies',
  INSTRUCTIONS = 'Instructions',
  PRIVACY_POLICY = 'PrivacyPolicy',
}

export enum ERoutesTitle {
  'Start' = 'MatFröjd',
  'Categories' = 'Receptkategorier',
  'Pantry' = 'Skafferisök',
  'RecipeList' = 'Receptlista',
  'ShoppingList' = 'Inköpslista',
  'RecipeOverview' = 'Receptöverblick',
  'RecipeInstructions' = 'Receptinstruktion',
  'Favorites' = 'Favoriter',
  'ShoppingListDetail' = 'Inköpslista',
  'Search' = 'Sök recept',
  'Information' = 'Om MatFröjd',
  'GeneralSettings' = 'Inställningar',
  'Movies' = 'Demofilm',
  'Instructions' = 'Ikoner & ord',
}

const routes = {
  start: {
    title: 'MatFröjd',
    name: ERoutes.START,
    route: '/start',
  },
  categories: {
    title: 'Receptkategorier',
    name: ERoutes.CATEGORIES,
    route: '/categories',
  },
  recipeList: {
    title: 'Receptlista',
    name: ERoutes.RECIPE_LIST,
    route: '/recipe_list',
  },
  recipeOverview: {
    title: 'Receptöverblick',
    name: ERoutes.RECIPE_OVERVIEW,
    route: '/recipe_overview',
  },
  recipeInstructions: {
    title: 'Receptinstruktion',
    name: ERoutes.RECIPE_INSTRUCTIONS,
    route: '/recipe_instructions',
  },
  shoppingList: {
    title: 'Inköpslista',
    name: ERoutes.SHOPPING_LIST,
    route: '/shopping_list',
  },
  shoppingListDetail: {
    title: 'Inköpslista',
    name: ERoutes.SHOPPING_LIST_DETAIL,
    route: '/shopping_list_detail',
  },
  pantry: {
    title: 'Skafferisök',
    name: ERoutes.PANTRY,
    route: '/pantry',
  },
  favorites: {
    title: 'Favoriter',
    name: ERoutes.FAVORITES,
    route: '/favorites',
  },
  search: {
    title: 'Sök recept',
    name: ERoutes.SEARCH,
    route: '/search',
  },
  information: {
    title: 'Om MatFröjd',
    name: ERoutes.INFORMATION,
    route: '/om_matfrojd',
  },
  generalSettings: {
    title: 'Inställningar',
    name: ERoutes.GENERAL_SETTINGS,
    route: '/installningar',
  },
  movies: {
    title: 'Demofilm',
    name: ERoutes.MOVIES,
    route: '/demofilm',
  },
  instructions: {
    title: 'Ikoner & ord',
    name: ERoutes.INSTRUCTIONS,
    route: '/om_ikoner',
  },
  privacyPolicy: {
    title: 'Integritetspolicy',
    name: ERoutes.PRIVACY_POLICY,
    route: '/integritetspolicy',
  },
};

export default routes;
