import { Types } from '../actions/types'
import { IMenuContentState } from '../../model/state'
import { persistReducer } from "redux-persist";
import { TESTING } from "../../config"
import { IMenuAction } from "../actions/";
import MFStorage from './storage';

const initialState: IMenuContentState = {
    isFetching: false,
    didFetch: false,
    dataLoaded: false,
    menuContent: []
}
const config = {
    key: 'menuContentState',
    storage: MFStorage,
    blacklist: ['isFetching', 'didFetch', 'dataLoaded']
}

const reducer =  (state = initialState, action: IMenuAction): IMenuContentState => {
    switch (action.type) {
        case Types.CHECK_MENU:
            // return Object.assign({}, state, {dataLoaded: state.menuContent.length > 0 && (state.didFetch || !TESTING)})
            return Object.assign({}, state, {dataLoaded: false})
        case Types.REQUEST_MENU:
            return {...state, isFetching: true, didFetch: false, dataLoaded: false}
        case Types.RECIEVE_MENU:
            return {...state, isFetching: false, didFetch: false, dataLoaded: true, menuContent: action.menu}
        case Types.ERROR_MENU:
            return {...state, isFetching:false,didFetch:false,dataLoaded:false}
        default: return state
    }
}
export default persistReducer(config, reducer)