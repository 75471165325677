import { StyleSheet } from 'react-native';
import { PRIMARY_THEME_COLORS } from '../../../../config/style';

export default StyleSheet.create({
  descriptionContainer: {
    padding: 15,
  },
  showMore: {
    marginTop: 5,
    textAlign: 'right',
    color: PRIMARY_THEME_COLORS.recipes,
  },
});
