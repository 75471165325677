import { StyleSheet } from 'react-native';
import { IS_WEB } from '../../../config/style/web_helper';
import { IS_TINY_DEVICE, IS_SUPER_TINY_DEVICE } from '../../../helpers/device';

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: IS_WEB ? 60 : undefined,
  },
  linearGradient: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    marginTop: IS_SUPER_TINY_DEVICE ? 10 : 20,
    justifyContent: 'space-around',
    flex: 3,
  },

  logoContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logo: {
    height: IS_SUPER_TINY_DEVICE ? 40 : IS_TINY_DEVICE ? 50 : 65,
    width: 300,
    resizeMode: 'contain',
    marginBottom: IS_SUPER_TINY_DEVICE ? 15 : 30,
  },
  logoSecond: {
    height: IS_SUPER_TINY_DEVICE ? 30 : IS_TINY_DEVICE ? 40 : 50,
    width: 300,
    resizeMode: 'contain',
    marginBottom: IS_SUPER_TINY_DEVICE ? 10 : IS_TINY_DEVICE ? 16 : 30,
  },
});
