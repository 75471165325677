import { PRIMARY_THEME_COLORS } from '../../config/style';
import {
  headerStyle,
  headerTintColor,
  headerTitleStyle,
} from '../styles';
import { Settings } from '../../screens';
import { IStack } from '../';
import routes from '../routes';

const settingStack: IStack = {
  [routes.generalSettings.name]: {
    screen: Settings,
    navigationOptions: {
      title: routes.generalSettings.title,
      webRoute: routes.generalSettings.route,
      headerBackTitle: null,
      headerTitleStyle: headerTitleStyle,
      headerTintColor: headerTintColor,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: true,
  },
};

export default settingStack;
