import React from 'react'
import {Component} from 'react'
import {View, Animated, Image, ImageSourcePropType} from 'react-native'
import styles from './styles'
import AnimatedOpacity from '../animated_opacity';
import ListImg from '../../../assets/ui/list_item/list_item.png'
import MfModal from '../mf_modal';
import { animationTypes } from '../mf_modal';
import { WINDOW_SIZE } from '../../config/style';
import shop from '../../../assets/mf_ui/handla-ikon.png'

interface IShoppingAnimation {
    onAnimationEnd: Function
}

class ShoppingAnimation extends Component<IShoppingAnimation> {

    state = {
        positionXAnimation: new Animated.Value(0),
        positionYAnimation: new Animated.Value(0),
        scaleAnimation: new Animated.Value(1),
        opacityAnimation: new Animated.Value(0),
        iconXAnimation: new Animated.Value(-120)
    }
    componentDidMount() {
        this.animateStuff()
    }

    getListItems() {
        const amount = 5
        const duration = 200
        const delay = 100
        const items = []
        for(let i=0;i<amount;i++) {
            const item = 
            <AnimatedOpacity onAnimationEnd={() => {}} key={i} duration={duration} delay={delay * i}>
                <Image style={styles.listItem} source={ListImg as ImageSourcePropType} />
            </AnimatedOpacity>
            items.push(item)
        }
        return items
    }

    animateStuff() {
        setTimeout(() => {
            this.animateOpacity(200)
            this.animateScale(650)
            this.animateX(700)
            this.animateY(700)
            this.animateIconX(300)
        }, 500)
        setTimeout(() => {
            this.animateIconEnd(130)
        },1900)
    }

    animateIconX(duration: number) {
        Animated.sequence([
            Animated.timing(this.state.iconXAnimation, {
                toValue: 20,
                duration: duration
            }),
            Animated.timing(this.state.iconXAnimation, {
                toValue: 0,
                duration: duration/2
            }),
            
        ]).start()
    }

    animateIconEnd(duration: number) {
        Animated.sequence([
            Animated.timing(this.state.iconXAnimation, {
                toValue: 20,
                duration: duration
            }),
            Animated.timing(this.state.iconXAnimation, {
                toValue: -120,
                duration: duration
            }),
            
        ]).start(() => this.onAnimationEnd())
    }

    animateOpacity(duration: number) {
        Animated.timing(this.state.opacityAnimation, {
            toValue: 1,
            duration: duration
        }).start()
    }

    animateScale(duration: number) {
        const larger = 1.5
        const smaller = 0
        Animated.timing(this.state.scaleAnimation, {
            toValue: larger,
            duration: duration
        }).start(() => {
            Animated.timing(this.state.scaleAnimation, {
                toValue: smaller,
                duration: duration
            }).start()
        })
    }

    animateX(duration: number) {
        const first = -50
        const last = -WINDOW_SIZE.width /2 
        Animated.timing(this.state.positionXAnimation, {
            toValue: first,
            duration: duration
        }).start(() => {
            Animated.timing(this.state.positionXAnimation, {
                toValue: last,
                duration: duration
            }).start()
        })
    }

    animateY(duration: number) {
        const first = -10
        const last = WINDOW_SIZE.height / 2 
        Animated.timing(this.state.positionYAnimation, {
            toValue: first,
            duration: duration
        }).start(() => {
            Animated.timing(this.state.positionYAnimation, {
                toValue: last,
                duration: duration
            }).start()
        })
    }

    onAnimationEnd() {
        this.props.onAnimationEnd()
    }

    render() {
        return (
            <MfModal visibile={true} transparent={true} animationType={animationTypes.FADE} onClose={() => {}}>
                <View style={styles.container}>
                    <Animated.View style={
                        [
                            styles.animationContainer, 
                            {opacity: this.state.opacityAnimation},
                            {transform: [
                                {translateX: this.state.positionXAnimation},
                                {translateY: this.state.positionYAnimation},
                                {scale: this.state.scaleAnimation}
                            ]},

                        ]}>
                        {this.getListItems()}
                    </Animated.View>
                    <Animated.View style={[
                        styles.animationIconContainer,
                        {transform: [{translateX: this.state.iconXAnimation}]}
                        ]}>
                        <Image style={styles.icon} source={shop as ImageSourcePropType}/>
                    </Animated.View>
                </View>
            </MfModal>
        )
    }
}

export default ShoppingAnimation
