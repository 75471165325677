import React from 'react'
import {Component} from 'react'
import {View, SafeAreaView, TextInput, Image, ImageSourcePropType, KeyboardAvoidingView, Platform} from 'react-native'
import styles from './styles'
import MfModal, { animationTypes } from '../../../../../components/mf_modal'
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Button from '../../../../../components/button/button';
import close from '../../../../../../assets/ui/close_black.png'
import Touchable from '../../../../../components/touchable';
import { getDate } from '../../../../../helpers/date';

interface IAddShoppingListModal {
    visible: boolean,
    onClose: Function,
    onCreate: Function,
}

class AddShoppingListModal extends Component<IAddShoppingListModal> {
    state = {
        text: this.initialPlaceHolder(),
        showCreateView: false,
        header: getText('Inköpslista')
    }

    initialPlaceHolder() {
        // return getDate()
        return getText('Namn på inköpslista')
    }
    onCreate() {
        this.props.onCreate(this.state.text)
        this.setState({text: this.initialPlaceHolder()})
    }
    
    onClose() {
        this.setState({showCreateView: false})
        this.props.onClose()
    }
    render() {
        return(
            <MfModal visibile={this.props.visible} animationType={animationTypes.FADE} transparent={true} onClose={() => this.onClose()}>
                <SafeAreaView style={styles.container}>
                    <KeyboardAvoidingView enabled behavior={Platform.OS == "android" ? undefined : "padding"} keyboardVerticalOffset={200}>  
                        <View style={styles.contentContainer}>
                            <View style={styles.headerContainer}>
                                <Touchable onPress={() => this.onClose()}>
                                    <View>
                                        <Image style={styles.image} source={close as ImageSourcePropType} />
                                    </View>
                                </Touchable>
                                <MfText textStyle={TEXT_STYLES.HEADER}>{getText('Ny inköpslista')}</MfText>
                                <View style={styles.headerRight}></View>
                            </View>
                            <View>
                                <TextInput style={styles.input} placeholder={this.initialPlaceHolder()}  onChangeText={(text: string) => this.setState({text: text})}/>
                                <View style={styles.buttonContainer}>
                                    <Button style={styles.addButton} title={getText('Skapa')} onPress={() => this.onCreate()} /> 
                                </View>
                            </View>
                        </View>
                    </KeyboardAvoidingView>
                </SafeAreaView>
            </MfModal>
        )
    }
}

export default AddShoppingListModal