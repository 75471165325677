import * as Hash from 'object-hash'
import * as FileSystem from 'expo-file-system'

export async function preLoadImageAsset(source: string) {
    // const uri = this.props.source
    const uri = source.replace("http://", "https://")
    const imageName = Hash.default(uri)
    const extension = uri.substr((uri.lastIndexOf('.') +1))
    // const folder = 'photos/'
    const path = `${FileSystem.documentDirectory}${imageName}.${extension}`
    const image = await FileSystem.getInfoAsync(path)
    
    if (image.exists) return `Image exists: ${uri}`

    const newImage = await FileSystem.downloadAsync(uri, path)
    return `${uri} preloaded in to assets`
}

export async function preLoadAssetBatch(assets: string[]) {
    const result : string[] = []
    for(let i = 0; i<assets.length;i++) {
        const res = await preLoadImageAsset(assets[i])
        result.push(res)
    }
    return result
}