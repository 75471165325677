import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../config/style';

export default StyleSheet.create({
    chip: {
        flexDirection: "row",
        alignItems: "center",
        borderStyle: "solid",
        borderRadius: 5,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
        paddingVertical: 3,
        paddingHorizontal: 8,
        margin: 10
    },
    chipRemoveBtn: {
        width: 20,
        height: 20,
        marginLeft: 10,
        borderRadius: 100,
        // borderWidth: 1,
        backgroundColor: "white",
        padding: 5
    },
    chipText: {
        color: 'white',
        fontWeight: 'bold'
    }
})