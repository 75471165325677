import { StyleSheet, Platform } from 'react-native'
import { PRIMARY_THEME_COLORS, SECONDARY_THEME_COLORS, CARD_IMAGE_SIZE, WINDOW_SIZE, NAVBAR_SIZE } from '../../../../config/style';
import { IS_TABLET } from '../../../../helpers/device';

const headerHeight = 80

export default StyleSheet.create({
    contentContainerWeb: {
        marginTop: 60,
        flex: 1,
        overflow: 'scroll'
    },
    headerContainer: {
        width: '100%',
        height: headerHeight,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    contentContainer: {
        padding: 15,
        marginTop: 10,
    },
    imageContainer: {
        height: headerHeight,
        width: 150
    },
    imageContainerTablet: {
        height: CARD_IMAGE_SIZE.height,
    },
    image: {
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: undefined,
        height: undefined,
        resizeMode: 'cover'
    },
    tabletContainer: {
        height: '100%',
        width: '100%',
        flexDirection: 'row'
    },
    tabletLeft: {
        flex: 3,
    },
    tabletRight: {
        flex: 2,
        backgroundColor: SECONDARY_THEME_COLORS.lightGreen,   
    },
    tabletScrollView: {
        height: '100%',
        paddingBottom: 40
    },
    actionContainer: {
        flex: Platform.select({
            web: undefined,
            default: 1
        }),
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    actionContainerTablet: {
        margin: 10,
        marginBottom: 40,
    },
    favourites: {
        height: 40,
        width: 40,
        marginRight: 10
    },
    endContainer: {
        alignItems: 'center',
    },
    endButton: {
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
    },
    cardIngredients: {
        width: '100%',
        padding: 40
    },
    doneText: {
        fontSize: 26,
        margin: 20,
        fontFamily: 'clan-pro-narr-bold'
    }
})

