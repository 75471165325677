import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent'
    },
    image: {
        height: 200,
        width: 200,
        marginBottom: 20
        // backgroundColor: 'pink'
    },
    smallImage: {
        height: 50,
        width: 50
    },
    loader: {
        justifyContent: 'center',
        alignItems: 'center'
    }
})