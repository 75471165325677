import { Ingredient, Nutrition } from '../model';

export type CalculatedNutrion = {
  title: string;
  unit: string;
  amount: number;
  color: string;
  recommendedAmount: number;
};

export interface CalculatedNutrions {
  protein: CalculatedNutrion;
  energiKj: CalculatedNutrion;
  energiKcal: CalculatedNutrion;
  carbs: CalculatedNutrion;
  vitamin_c: CalculatedNutrion;
  fibers: CalculatedNutrion;
  iron: CalculatedNutrion;
  calcium: CalculatedNutrion;
  vitamin_d: CalculatedNutrion;
  vitamin_b12: CalculatedNutrion;
  sodium: CalculatedNutrion;
  folate: CalculatedNutrion;
  selenium: CalculatedNutrion;
  iodine: CalculatedNutrion;
  fat_total: CalculatedNutrion;
  fat: {
    fat_mono: CalculatedNutrion;
    fat_total: CalculatedNutrion;
    // fat_trans: CalculatedNutrion,
    fat_poly: CalculatedNutrion;
    fat_sat: CalculatedNutrion;
    // fat_glycerol: CalculatedNutrion
  };
}

export function calculateNutritions(
  ingredients: { [key: string]: Ingredient },
  nutritions: Nutrition[],
  minServings: number
) {
  const calculatedNutritions: CalculatedNutrions = getEmptyCalculatedNutritions();
  Object.keys(ingredients).forEach((i) => {
    const ing = ingredients[i];
    if (ing.externalId !== null) {
      const nuts = nutritions.filter((n) => n.livsmedel_id === ing.externalId);
      calculatedNutritions.energiKj = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Enkj'),
        calculatedNutritions.energiKj,
        ing,
        minServings,
        '#002FB7',
        820000
      );
      calculatedNutritions.energiKcal = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Ener'),
        calculatedNutritions.energiKcal,
        ing,
        minServings,
        '#64AF00',
        196000
      );
      calculatedNutritions.protein = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Prot'),
        calculatedNutritions.protein,
        ing,
        minServings,
        '#0BDCED',
        7240
      );
      calculatedNutritions.fat_total = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Fett'),
        calculatedNutritions.fat.fat_total,
        ing,
        minServings,
        '#4A6BFF',
        7200
      );
      calculatedNutritions.carbs = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Kolh'),
        calculatedNutritions.carbs,
        ing,
        minServings,
        '#FF4A4A',
        24170
      );
      calculatedNutritions.fibers = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Fibe'),
        calculatedNutritions.fibers,
        ing,
        minServings,
        '#FFC04A',
        2460
      );
      calculatedNutritions.vitamin_c = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'VitC'),
        calculatedNutritions.vitamin_c,
        ing,
        minServings,
        '#FFD02E',
        7500
      );
      calculatedNutritions.iron = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Fe'),
        calculatedNutritions.iron,
        ing,
        minServings,
        '#F9FF4A',
        900
      );
      calculatedNutritions.calcium = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Ca'),
        calculatedNutritions.calcium,
        ing,
        minServings,
        '#7EACFF',
        80000
      );
      calculatedNutritions.vitamin_d = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'VitD'),
        calculatedNutritions.vitamin_d,
        ing,
        minServings,
        '#5AFF4A',
        2000
      );
      calculatedNutritions.vitamin_b12 = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'VitB12'),
        calculatedNutritions.vitamin_b12,
        ing,
        minServings,
        '#FF9A4A',
        200
      );
      calculatedNutritions.sodium = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Na'),
        calculatedNutritions.sodium,
        ing,
        minServings,
        '#7E4949',
        230000
      );
      calculatedNutritions.folate = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Folat'),
        calculatedNutritions.folate,
        ing,
        minServings,
        '#496F7C',
        30000
      );
      calculatedNutritions.selenium = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'Se'),
        calculatedNutritions.selenium,
        ing,
        minServings,
        '#4F3C6D',
        5000
      );
      calculatedNutritions.iodine = createCalculatedNutrition(
        nuts.find((n) => n.str_id === 'I'),
        calculatedNutritions.iodine,
        ing,
        minServings,
        '#1A1313',
        15000
      );
      calculatedNutritions.fat = {
        // fat_glycerol: createCalculatedNutrition(nuts.find(n => n.str_id === 'Ener') , calculatedNutritions.energiKcal, ing),
        fat_mono: createCalculatedNutrition(
          nuts.find((n) => n.str_id === 'Mone'),
          calculatedNutritions.fat.fat_mono,
          ing,
          minServings,
          '#4AC2FF'
        ),
        fat_poly: createCalculatedNutrition(
          nuts.find((n) => n.str_id === 'Pole'),
          calculatedNutritions.fat.fat_poly,
          ing,
          minServings,
          '#0700BD'
        ),
        fat_sat: createCalculatedNutrition(
          nuts.find((n) => n.str_id === 'Mfet'),
          calculatedNutritions.fat.fat_sat,
          ing,
          minServings,
          '#BD5700'
        ),
        fat_total: createCalculatedNutrition(
          nuts.find((n) => n.str_id === 'Fett'),
          calculatedNutritions.fat.fat_total,
          ing,
          minServings,
          '#4A6BFF',
          7200
        ),
        // fat_trans: createCalculatedNutrition(nuts.find(n => n.str_id === 'Ener') , calculatedNutritions.energiKcal, ing)
      };
    }
  });
  return calculatedNutritions;
}

const EXCLUDED_UNITS = ['kcal', 'kj'];
const getUnit = (unit?: string) => {
  return EXCLUDED_UNITS.includes(unit?.toLocaleLowerCase() ?? '') ? '' : unit;
};

function createCalculatedNutrition(
  nutrition: Nutrition | undefined,
  calculatedNutrition: CalculatedNutrion,
  ingredient: Ingredient,
  minServings: number,
  color: string,
  maxRecommendation?: number
) {
  return {
    title: nutrition?.name ?? calculatedNutrition.title,
    unit:
      nutrition !== undefined
        ? getUnit(nutrition.unit)
        : calculatedNutrition.unit,
    amount:
      nutrition !== undefined
        ? calculatedNutrition.amount +
          (nutrition.value * ingredient.weight) / minServings
        : calculatedNutrition.amount,
    color: color,
    recommendedAmount: maxRecommendation ? maxRecommendation : 0,
  };
}

function getEmptyCalculatedNutritions() {
  const tempNut: CalculatedNutrion = {
    title: '',
    unit: '',
    amount: 0,
    color: 'black',
    recommendedAmount: 0,
  };
  return {
    energiKj: tempNut,
    energiKcal: tempNut,
    protein: tempNut,
    fat_total: tempNut,
    carbs: tempNut,
    fibers: tempNut,
    vitamin_c: tempNut,
    iron: tempNut,
    calcium: tempNut,
    vitamin_d: tempNut,
    vitamin_b12: tempNut,
    sodium: tempNut,
    folate: tempNut,
    selenium: tempNut,
    iodine: tempNut,
    fat: {
      fat_mono: tempNut,
      // fat_trans: tempNut,
      fat_total: tempNut,
      fat_poly: tempNut,
      fat_sat: tempNut,
      // fat_glycerol: tempNut
    },
  };
}

export function calFatEnergi(nuts: CalculatedNutrions, nut: CalculatedNutrion) {
  // const sum = nuts.carbs.amount + nuts.protein.amount + nuts.fat.fat_total.amount
  const sumKcal = nuts.energiKcal.amount;
  let kcal = 0;
  switch (nut.title) {
    case 'Protein':
      kcal = gramToKcal.protein;
      break;
    case 'Fibrer':
      kcal = gramToKcal.fibers;
      break;
    case 'Kolhydrater':
      kcal = gramToKcal.carbs;
      break;
    default:
      kcal = gramToKcal.fat;
      break;
  }
  return ((nut.amount * kcal) / sumKcal) * 100;
}

export const gramToKcal = {
  protein: 4,
  fat: 9,
  carbs: 4,
  fibers: 2,
};
