import { platform } from 'process';
import { Platform } from 'react-native';
import {
  PRIMARY_THEME_COLORS,
  WINDOW_SIZE,
} from '../config/style';
import { IS_TABLET } from '../helpers/device';

const titleWidthDefault = IS_TABLET
  ? undefined
  : WINDOW_SIZE.width - 100;

export const headerTitleStyle = {
  color: 'white',
  fontSize: IS_TABLET ? 45 : 24,
  fontFamily: 'clan-pro-narr-book',
  margin: 0,
  width: titleWidthDefault,
};

export const headerStyle = {
  shadowColor: PRIMARY_THEME_COLORS.shadowColor,
  shadowOpacity: 0.8,
  shadowRadius: 2,
  shadowOffset: { width: 0, height: 2 },
  height: 60,
};

export const headerTitleStyleWithRightIcon = {
  width:
    Platform.OS === 'android'
      ? titleWidthDefault - 18
      : titleWidthDefault,
};

export const headerTintColor = 'white';
