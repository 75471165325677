import {
  Ingredient,
  StepIngredient,
  Recipe,
  IngredientMeasures,
} from '../model';
import { IngredientKeys } from './constants';

enum Measurements {
  L,
  DL,
  ML,
  MSK,
  TSK,
  KRM,
  KG,
  GRAM,
}

const quantityStringForPortions = (
  ingredient: StepIngredient,
  numPortions: number,
  minPortions: number
) => {
  let quantity = ingredient.useAll
    ? ingredient.quantityAll
    : ingredient.quantityUse;
  return quantityString((quantity * numPortions) / minPortions);
};

const quantityString = (quantity: number) => {
  let integralPart = quantity;
  let decimalPart = 4.0 * (quantity - integralPart);
  let quarters = decimalPart;
  let fractionString = '';
  switch (quarters) {
    case 1:
      fractionString = '¼';
      break;
    case 2:
      fractionString = '½';
      break;
    case 3:
      fractionString = '¾';
      break;
  }
  return convertQuarters(quantity).toString() + fractionString;
};

const isStepIngredient = (
  ingredient: StepIngredient | Ingredient
): ingredient is StepIngredient => {
  return 'useAll' in ingredient;
};

const shouldUsePlural = (
  ingredient: StepIngredient | Ingredient,
  numPortions: number,
  minPortions: number
) => {
  const quantity = isStepIngredient(ingredient)
    ? ingredient.useAll
      ? ingredient.quantityAll
      : ingredient.quantityUse
    : ingredient.quantity;
  return (quantity * numPortions) / minPortions >= 2;
};

const getIndex = (measurement: string) => {
  switch (measurement) {
    case 'l':
      return Measurements.L;
    case 'dl':
      return Measurements.DL;
    case 'ml':
      return Measurements.ML;
    case 'msk':
      return Measurements.MSK;
    case 'tsk':
      return Measurements.TSK;
    case 'krm':
      return Measurements.KRM;
    case 'kg':
      return Measurements.KG;
    case 'gram':
      return Measurements.GRAM;
    default:
      return -1;
  }
};
const getMeasureByIndex = (index: number) => {
  switch (index) {
    case Measurements.L:
      return 'l';
    case Measurements.DL:
      return 'dl';
    case Measurements.ML:
      return 'ml';
    case Measurements.MSK:
      return 'msk';
    case Measurements.TSK:
      return 'tsk';
    case Measurements.KRM:
      return 'krm';
    case Measurements.KG:
      return 'kg';
    case Measurements.GRAM:
      return 'gram';
    default:
      return '';
  }
};
const getConvertMap = () => {
  // prettier-ignore
  return [
    /*         L           DL      ML       MSK         TSK     KRM    KG      GRAM
    /* L    */ [1,		     10,     1000,    30/0.45,    200,    1000,  -1,     -1   ],
    /* DL   */ [0.1, 	     1, 	   100,     3/0.45,     20,     100,   -1,     -1   ],
    /* ML   */ [0.001,     0.01,   1,       1/15,       1/5,    1,     -1,     -1   ],
    /* MSK  */ [0.45/30,   0.45/3, 15,      1,          3,      15,    -1,     -1   ],
    /* TSK  */ [1/200,     1/20,   5,       1/3,        1,      5,     -1,     -1   ],
    /* KRM  */ [1/1000,    1/100,  1,       1/15,       1/5,    1,     -1,     -1   ],
    /* KG   */ [-1,        -1,     -1,      -1,         -1,     -1,    1,      1000 ],
    /* GRAM */ [-1,        -1,     -1,      -1,         -1,     -1,    1/1000, 1    ]
  ]
};

const convertUp = (measureIndex: number, quantity: number) => {
  if (measureIndex === Measurements.KRM && quantity > 2) {
    return Math.round((quantity / 5) * 2) / 2;
  }
  if (measureIndex === Measurements.TSK && quantity > 2) {
    return Math.round((quantity / 3) * 2) / 2;
  }
  if (measureIndex === Measurements.MSK && quantity > 5) {
    return Math.round((quantity / 6) * 2) / 2;
  }
};

const getQuantityAndMeasurement = (
  measure: string,
  quantity: number,
  isIncreasing: boolean,
  initialIngredient?: Ingredient
) => {
  if (!isIncreasing && !!initialIngredient) {
    return initialIngredient;
  }
  const measureIndex = getIndex(measure);
  let newQuantity: number | undefined = convertUp(measureIndex, quantity);

  if (!newQuantity) {
    return {
      [IngredientKeys.QUANTITY]: quantity,
    };
  }

  let newMeasureIndex = measureIndex + (isIncreasing ? -1 : 1);
  if (newMeasureIndex === Measurements.ML) {
    newMeasureIndex = newMeasureIndex + (isIncreasing ? -1 : 1);
  }
  const newMeasure = getMeasureByIndex(newMeasureIndex);
  return {
    [IngredientKeys.QUANTITY]: newQuantity,
    [IngredientKeys.MEASURE]: newMeasure,
    [IngredientKeys.MEASURE_FULL]: newMeasure,
    [IngredientKeys.MEASURE_PLURAL]: newMeasure,
  };
};

const combineMeasurements = (
  q1: number,
  m1: string,
  q2: number,
  m2: string
) => {
  const convertMap = getConvertMap();
  const i1 = getIndex(m1);
  const i2 = getIndex(m2);
  if (i1 === -1 || i2 === -1 || convertMap[i1][i2] == -1) return null;
  const [quantity, measurement] =
    convertMap[i1][i2] > convertMap[i2][i1]
      ? [q1 + q2 * convertMap[i2][i1], m1]
      : [q2 + q1 * convertMap[i1][i2], m2];
  return { q: Math.ceil(quantity * 2) / 2, m: measurement };
};

const convertQuarters = (quantity: number) => {
  if (quantity % 1 != 0) {
    const s = quantity.toString().split('.');
    const start = parseInt(s[0]) > 0 ? s[0] : '';
    let end = '';
    switch (s[1]) {
      case '25':
        end = '\xBC';
        break;
      case '5':
        end = '\xBD';
        break;
      case '75':
        end = '\xBE';
        break;
      default:
        end = `${quantity}`;
        break;
    }
    return `${start} ${end}`;
  }
  return quantity;
};

const roundQuantity = (q: number) => {
  return Math.ceil(q);
};

const getStandardQuantities = (recipes: Recipe[]) => {
  const measures: IngredientMeasures = {};
  recipes.forEach((rec) => {
    rec.sections.forEach((section) => {
      section.ingredients.forEach((ing) => {
        if (!(ing.id in measures)) {
          let quantity = Math.round(ing.quantityShop);
          const quantityLengt = quantity.toString().split('.')[0].length || 0;
          switch (quantityLengt) {
            case 1:
              quantity = 1;
              break;
            case 2:
              quantity = 10;
              break;
            case 3:
              quantity = 100;
              break;
            case 4:
              quantity = 1000;
              break;
            default:
              quantity = 1;
              break;
          }
          measures[ing.id] = {
            quantity: quantity,
            measure: ing.measureShop,
          };
        }
      });
    });
  });
  return measures;
};

export default {
  quantityStringForPortions,
  quantityString,
  isStepIngredient,
  shouldUsePlural,
  getIndex,
  getMeasureByIndex,
  getConvertMap,
  getQuantityAndMeasurement,
  combineMeasurements,
  convertQuarters,
  roundQuantity,
  getStandardQuantities,
};
