import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, Keyboard, Platform, EmitterSubscription} from 'react-native'

interface IKeyBoardHideView {
    style?: object
}

class KeyboardHideView extends Component<IKeyBoardHideView> {

    private keyboardDidHideListener: EmitterSubscription | null
    private keyboardDidShowListener: EmitterSubscription | null

    state = {
        isKeyboardVisible: false
    }

    constructor(props: IKeyBoardHideView) {
        super(props)
        this.keyboardDidShowListener = Platform.OS == "android" ? Keyboard.addListener(
            "keyboardDidShow",
            this.keyboardDidShow
        ) : null
        this.keyboardDidHideListener = Platform.OS == "android" ? Keyboard.addListener(
            "keyboardDidHide",
            this.keyboardDidHide
        ) : null
    }

    componentWillUnmount() {
        if (this.keyboardDidShowListener)
            this.keyboardDidShowListener.remove();
        if (this.keyboardDidHideListener)
            this.keyboardDidHideListener.remove();
    }

    keyboardDidShow = () => {
        this.setState({
            isKeyboardVisible: true
        });
    };

    keyboardDidHide = () => {
        this.setState({
            isKeyboardVisible: false
        });
    };

    render() {
        return (
            <View style={this.props.style}>
                {!this.state.isKeyboardVisible && this.props.children}
            </View>
        )
    }
}
export default KeyboardHideView