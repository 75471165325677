import { IS_WEB } from './style/web_helper';

export const TESTING = false;
export const PROD_DATA = false;
export const BACKEND_URL = 'https://admin.matfrojd.com';
export const BASE_URL = PROD_DATA
  ? 'https://admin.matfrojd.com/images/data/'
  : 'https://admin.matfrojd.com/clientGet.php?function=get';
const SUFFIX = PROD_DATA ? '.json' : '';
// const ALL_RECIPES = IS_WEB ? '&all_recipes=1' : ''; USE THIS TO SHOW TEST DATA IN WEB
const ALL_RECIPES = '';
export const RECIPE_URL = `${BASE_URL}Recipes${SUFFIX}${ALL_RECIPES}`;
export const CATEGORY_URL = `${BASE_URL}RecipeCategories${SUFFIX}${ALL_RECIPES}`;
export const INGREDIENT_URL = `${BASE_URL}Ingredients${SUFFIX}`;
export const NUTRITION_URL = `${BASE_URL}Nutrition${SUFFIX}`;
export const MENU_URL = `${BASE_URL}Menu${SUFFIX}`;

export const DATA_TIMESTAMP_URL = `${BASE_URL}data_timestamp.txt`;
