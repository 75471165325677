import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {Image, ImageSourcePropType, View, Animated} from 'react-native'
import Touchable from '../touchable'
import Heart from '../../../assets/mf_ui/favorit-star-ikon-dc6400.png'
import HeartFilled from '../../../assets/mf_ui/favorit-star-fyld-ikon-dc6400.png'
import MfModal, { animationTypes } from '../mf_modal';
import { TOUCHEABLE_TYPES } from '../touchable/touchable';

interface IFavoriteAnimation {
    isFavorite: boolean,
    onAdd: () => void,
    onRemove: () => void
}

class FavoriteAnimation extends Component<IFavoriteAnimation> {
    state = {
        opacity: new Animated.Value(0),
        showModal: false
    }

    componentDidMount() {
        const {isFavorite} = this.props
        this.state.opacity.setValue(isFavorite ? 1 : 0)
    }

    animateOpacity(after: () => void) {
        const {isFavorite} = this.props
        const toValue = isFavorite ? 0 : 1
        Animated.timing(this.state.opacity, {
            toValue: toValue,
            duration: 500
        }).start(() => after())
    }

    onPress() {
        const {isFavorite, onAdd, onRemove} = this.props
        const addRemove = isFavorite ? onRemove : onAdd
        this.setState({showModal: true})
        this.animateOpacity(() => {
            this.setState({showModal: false}, () => addRemove())
            }
        )
    }
    render() {
        const {isFavorite} = this.props
        const {showModal, opacity} = this.state
        const image = isFavorite ? HeartFilled : Heart
        return (
            <View>
                <Touchable type={TOUCHEABLE_TYPES.OPACITY} onPress={() => this.onPress()}>
                    <Image style={styles.image} source={image as ImageSourcePropType} />
                </Touchable>
                <MfModal animationType={animationTypes.FADE} visibile={showModal} onClose={() => {}} transparent={true}>
                    <View style={styles.modal}>
                        <View style={styles.modalImageContainer}>
                            <Image style={styles.modalImage} source={Heart as ImageSourcePropType} />
                            <Animated.Image style={[styles.modalImage, {opacity: opacity}, styles.overLay]} source={HeartFilled as ImageSourcePropType} />
                        </View>
                    </View>
                </MfModal>
            </View>
        )
    }
}

export default FavoriteAnimation
