import {StyleSheet} from 'react-native'
import { PRIMARY_THEME_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.8)'
    },
    popup: {
        backgroundColor: 'white',
        alignItems: 'center',
        margin: 20,
        padding: 10
    },
    title: {

    },
    content: {
        marginTop: 10,
        padding: 10
    },
    buttonContainer: {
        marginTop: 20,
        flexDirection: 'row',
    },
    button: {
        height: 100,
        width: 120
    },
    buttonAccept: {
        backgroundColor: PRIMARY_THEME_COLORS.green
    },
    buttonDenied: {
        backgroundColor: PRIMARY_THEME_COLORS.red
    }

    
})