import React from 'react';
import CategoriesContainer from './categories_container';
import { Screen } from '../../../interface';
import { Platform } from 'react-native';
import { ERoutes } from '../../../navigation/routes';
import { NavigationScreenProps } from 'react-navigation';
import SearchButton from '../../../components/nav_buttons/search_button/';
import routes from '../../../navigation/routes';

// If override componentDidMount call super.componentDidMount()
class Categories extends Screen {
  state = {
    navTitle: '',
  };

  static navigationOptions = ({
    navigation,
  }: NavigationScreenProps) => {
    return {
      headerTitle: navigation.getParam('title'),
      headerRight: (
        <SearchButton
          onPress={navigation.getParam('searchClicked')}
        />
      ),
    };
  };

  componentDidMount() {
    super.componentDidMount();
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        searchClicked: () => this.searchClicked(),
      });
    } else {
      this.props.webNavigation.setHeaderRight(
        <SearchButton
          onPress={() => this.searchClicked()}
        />
      );
    }
  }

  navigate(route?: ERoutes) {
    if (!route) route = routes.recipeList.name;
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, this.state.navTitle);
    } else {
      this.props.navigation.navigate(route, {
        title: this.state.navTitle,
      });
    }
  }

  searchClicked() {
    this.setState({ navTitle: 'Sök recept' }, () =>
      this.navigate(routes.search.name)
    );
  }

  setTitle = (title: string) => {
    this.setState({ navTitle: title });
  };

  render() {
    return (
      <CategoriesContainer
        setTitle={this.setTitle}
        onNavigate={() => this.navigate()}
      />
    );
  }
}

export default Categories;
