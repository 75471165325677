import React, { Component, MouseEvent } from 'react';
import MoreButton from '../nav_buttons/more_button';
import { View } from 'react-native';
import { IPopupMenu } from './i_popup_menu';
import { ICONS } from './index.native';
import styles from './styles';
import MfText, { TEXT_STYLES } from '../mf_text';
import { Icon } from 'react-native-elements';
import Touchable from '../touchable';

interface IPopupList {
  anchorEl: Element | null;
  id: string;
  open: boolean;
  onClose: Function;
}

interface IPopupItem {
  onPress: (event: MouseEvent) => void;
  title: string;
  icon?: ICONS;
}

class PopupList extends Component<IPopupList> {
  render() {
    return <View style={styles.menuWeb}>{this.props.children}</View>;
  }
}

class PopupItem extends Component<IPopupItem> {
  getIcon() {
    switch (this.props.icon) {
      case ICONS.SHARE:
        return (
          <Icon
            type="material-community"
            size={25}
            name="share"
            color="black"
          />
        );
      case ICONS.EDIT:
        return (
          <Icon
            type="material-community"
            size={25}
            name="pencil"
            color="black"
          />
        );
      case ICONS.DELETE:
        return (
          <Icon
            type="material-community"
            size={25}
            name="delete"
            color="black"
          />
        );
      default:
        return;
    }
  }
  render() {
    return (
      <Touchable onPress={this.props.onPress}>
        <View style={styles.menuItemWeb}>
          {this.getIcon()}
          <MfText style={styles.text} textStyle={TEXT_STYLES.PLAIN_TEXT}>
            {this.props.title}
          </MfText>
        </View>
      </Touchable>
    );
  }
}

class PopupMenu extends Component<IPopupMenu> {
  state = {
    anchorEl: null,
    menuVisible: false,
  };

  toggleVisibility = () => {
    this.setState({ menuVisible: !this.state.menuVisible });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, menuVisible } = this.state;

    return (
      <View testID="pop-up-menu-web" style={styles.menuButtonWeb}>
        <MoreButton onPress={this.toggleVisibility} />
        {menuVisible && (
          <PopupList
            id={this.props.id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {this.props.options.map((option) => {
              return (
                <PopupItem
                  key={option.title}
                  onPress={() => {
                    this.toggleVisibility();
                    option.onSelect();
                  }}
                  title={option.title}
                  icon={option.icon}
                />
              );
            })}
          </PopupList>
        )}
      </View>
    );
  }
}

export default PopupMenu;
