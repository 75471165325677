import { Types } from '../actions/types'
import { IIngredientState } from '../../model/state'
import { persistReducer } from "redux-persist";
import { TESTING } from "../../config"
import { IIngredientAction } from "../actions/";
import { IngredientBase } from '../../model';
import MFStorage from './storage';

const initialState: IIngredientState = {
    isFetching: false,
    didFetch: false,
    dataLoaded: false,
    ingredients: [],
    userIngredients: [],
    lastId: 0,
    standardMeasure: {}
}
//WARNING: REMOVE 'ingredients' when deploying!
const config = {
    key: 'ingredientState',
    storage: MFStorage,
    blacklist: ['isFetching', 'didFetch', 'dataLoaded']
}

// blacklist: ['isFetching', 'didFetch', 'dataLoaded']

const reducer =  (state = initialState, action: IIngredientAction): IIngredientState => {
    switch (action.type) {
        case Types.CHECK_INGREDIENTS:
        //When in test mode always wait for fetch, otherwise data in local storage is enough 
            return Object.assign({}, state, {dataLoaded: state.ingredients.length > 0 && (state.didFetch || !TESTING)})
        case Types.REQUEST_INGREDIENTS:
            return Object.assign({}, state, {isFetching: true})
        case Types.RECIEVE_INGREDIENTS:
            return Object.assign({}, state, {isFetching: false, didFetch: true, dataLoaded: true, ingredients: action.ingredients}, adjustUserIngredientIds(state, action.ingredients))
        case Types.ERROR_INGREDIENTS:
            return Object.assign({}, state, {error: action.error})
        case Types.SET_INGREDIENT_MEASURE:
            return Object.assign({}, state, {standardMeasure: action.measures})
        case Types.ADD_USER_INGREDIENT:
            return addUserIngredient(action.ingredient, state)
        default: return state
    }
}

function addUserIngredient(ingredient: IngredientBase, state: IIngredientState) {
    let id = state.lastId + 1
    const arr = Array.from(state.userIngredients)
    arr.push(ingredient)
    return {
        ...state,
        userIngredients: arr,
        lastId: id
    }

}

function adjustUserIngredientIds(state: IIngredientState, ingredients: IngredientBase[]) {
    const highId = Math.max.apply(Math, ingredients.map(i => i.id))
    if (state.userIngredients.length > 0) {
        const lowUserId = Math.min.apply(Math, state.userIngredients.map(i => i.id))
        const userArr = Array.from(state.userIngredients)
        let lastId = state.lastId
        if (highId > lowUserId) {
            let i = highId + 1
            userArr.forEach(ing => {
                ing.id = i
                i++
            })
            lastId = i
        }
        return {
            userIngredients: userArr,
            lastId: lastId
        }
    } else {
        return {
            userIngredients: state.userIngredients,
            lastId: highId + 1
        }
    }
    
}

export default persistReducer(config, reducer)