import { StyleSheet } from "react-native";
import { IS_TABLET } from "../../helpers/device";

export default StyleSheet.create({
    body: {
        fontFamily: 'clan-pro-narr-book',
    },
    p: {
        fontFamily: 'clan-pro-narr-book',
        fontSize: 20,
        margin: 0,
        padding: 0,
    },
    h1: {
        fontFamily: 'clan-pro-medium',
        fontSize: 35,
        margin: 0,
        padding: 0,
    },
    h2: {
        fontFamily: 'clan-pro-bold',
        fontSize: 30, 
        margin: 0,
        padding: 0,
    },
    h3: {
        fontSize: 27,
        margin: 0,
        padding: 0,
    },
    strong: {
        fontFamily: 'clan-pro-bold',
        fontSize: 20,
        margin: 0,
        padding: 0,
    },
    section: {
        width: '100%',
    },
    article: {
        width: '100%',
    },
})