import React from 'react'
import {Component} from 'react'
import {View, Image, ImageSourcePropType, Animated, StyleSheet} from 'react-native'
import Touchable from '../touchable'
import MfText, { TEXT_STYLES } from '../mf_text'
import MfCollapsible from '../mf_collapsible'
import styles from './styles'
import arrow from '../../../assets/ui/arrow_down.png'
import HTMLView from 'react-native-htmlview'

interface ICollapsibleCard {
    collapsed: boolean,
    title: string,
    content?: string
    customComponent?: JSX.Element
    onHeaderPress: () => void,
    headerColor: string,
    fullWidth?: boolean,
    html?:boolean
}

class CollapsibleCard extends Component<ICollapsibleCard> {
    state = {
        arrowAnim: new Animated.Value(0)
    }

    onPress() {
        this.props.onHeaderPress()
        this.spinArrow()
    }

    spinArrow() {
        const {collapsed} = this.props
        const toValue = collapsed ? 0 : 1
        Animated.timing(
            this.state.arrowAnim,
            {
                toValue: toValue,
                duration: 150
            }
        ).start()
    }

    render() {
        const {collapsed, title, content, onHeaderPress, headerColor, fullWidth, customComponent, html} = this.props
        const spin = this.state.arrowAnim.interpolate({
            inputRange: [0,1],
            outputRange: ['0deg', '180deg']
        })
        const htmlStyles = StyleSheet.create({
            p: {
                fontFamily: 'clan-pro-narr-book',
                fontSize: 20,
                padding: 10
            },
            b: {
                fontSize: 20,
                fontFamily: 'clan-pro-medium',
                padding: 10
            }
        })
        return (
            <View style={[styles.container, fullWidth ? styles.fullWidth : styles.standardWidth]}>
                <Touchable onPress={() => this.onPress()}>
                    <View style={[styles.header, {backgroundColor: headerColor}]}>
                        <MfText style={styles.headerText} textStyle={TEXT_STYLES.SUB_HEADER}>{title}</MfText>
                        <Animated.Image style={[styles.arrow, {transform: [{rotate: spin}]}]} source={arrow as ImageSourcePropType} />
                    </View>
                </Touchable>
                <MfCollapsible collapsed={collapsed}>
                    <View style={styles.content}>
                        {html ?
                        content &&
                        <HTMLView stylesheet={htmlStyles} value={`<p>${content}</p>`} />
                        :
                        <MfText style={styles.contentText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                            {content}
                        </MfText>
                        }
                        
                        {customComponent}
                    </View>
                </MfCollapsible>
            </View>
        )
    }
}

export default CollapsibleCard
