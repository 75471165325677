import { Dispatch, Action, ActionCreator } from 'redux';
import fetch from 'cross-fetch';
import { Ingredient, Recipe } from '../../model';
import { ThunkAction } from 'redux-thunk';
import { Types } from './types';
import { IState, IAlteredSections, IngredientsById } from '../../model/state';
import * as config from '../../config';
import { CheckedSections } from '../../screens/recipes/recipe_instructions/recipe_instructions_container/recipe_instructions_container';

interface ICheckAction extends Action {
  type: Types.CHECK_RECIPES;
}

interface IRequestAction extends Action {
  type: Types.REQUEST_RECIPES;
}

interface IReceiveAction extends Action {
  type: Types.RECEIVE_RECIPES;
  recipes: Recipe[];
}

interface IErrorAction extends Action {
  type: Types.ERROR_RECIPES;
  error: Error;
}

interface ISetCurrentRecipe extends Action {
  type: Types.SET_CURRENT_RECIPE;
  id: number;
}

interface ISetRecipeAmount extends Action {
  type: Types.SET_RECIPE_AMOUNT;
  amount: number;
}

interface ISetAlteredSections extends Action {
  type: Types.SET_ALTERED_SECTIONS;
  sections: IAlteredSections;
}

interface ISetPreloadedImages extends Action {
  type: Types.SET_REC_PRELOADED_IMAGES;
  didPreLoad: boolean;
}

interface ISetSectionsDone extends Action {
  type: Types.SET_SECTIONS_DONE;
  sections: CheckedSections;
}

interface ISetCurrentIngredients extends Action {
  type: Types.SET_CURRENT_INGREDIENTS;
  ingredients: IngredientsById;
}

interface ISetCurrentAlteredIngredients extends Action {
  type: Types.SET_CURRENT_ALTERED_INGREDIENTS;
  alteredIngredients: number[];
}

interface IUpdateCurrentRecipeData extends Action {
  type: Types.UPDATE_CURRENT_RECIPE_DATA;
  recipe: Recipe;
}

export type IRecipeAction =
  | IRequestAction
  | IReceiveAction
  | IErrorAction
  | ICheckAction
  | ISetCurrentRecipe
  | ISetRecipeAmount
  | ISetAlteredSections
  | ISetPreloadedImages
  | ISetSectionsDone
  | ISetCurrentIngredients
  | ISetCurrentAlteredIngredients
  | IUpdateCurrentRecipeData;

export const setPreloadedRecipeImages: ActionCreator<ISetPreloadedImages> = (
  didPreLoad: boolean
) => {
  return {
    type: Types.SET_REC_PRELOADED_IMAGES,
    didPreLoad: didPreLoad,
  };
};

export const setCurrentSectionsDone: ActionCreator<ISetSectionsDone> = (
  sections: CheckedSections
) => {
  return {
    type: Types.SET_SECTIONS_DONE,
    sections: sections,
  };
};

const checkRecipes: ICheckAction = {
  type: Types.CHECK_RECIPES,
};

const requestRecipes: IRequestAction = {
  type: Types.REQUEST_RECIPES,
};

const setAlteredSectionsAction: ActionCreator<ISetAlteredSections> = (
  alteredSections: IAlteredSections
) => {
  return {
    type: Types.SET_ALTERED_SECTIONS,
    sections: alteredSections,
  };
};

const receiveRecipes: ActionCreator<IReceiveAction> = (recipes: Recipe[]) => {
  return {
    type: Types.RECEIVE_RECIPES,
    recipes: recipes,
  };
};

const errorRecipes: ActionCreator<IErrorAction> = (error: Error) => {
  return {
    type: Types.ERROR_RECIPES,
    error: error,
  };
};

const setCurrentRecipe: ActionCreator<ISetCurrentRecipe> = (id: number) => {
  return {
    type: Types.SET_CURRENT_RECIPE,
    id: id,
  };
};

const setRecipeAmounAction: ActionCreator<ISetRecipeAmount> = (
  amount: number
) => {
  return {
    type: Types.SET_RECIPE_AMOUNT,
    amount: amount,
  };
};

const updateCurrentRecipeDataAction: ActionCreator<IUpdateCurrentRecipeData> = (
  recipe: Recipe
) => {
  return {
    type: Types.UPDATE_CURRENT_RECIPE_DATA,
    recipe,
  };
};

export const setCurrentIngredients =
  (ingredients: IngredientsById) => async (dispatch: Dispatch) =>
    dispatch({
      type: Types.SET_CURRENT_INGREDIENTS,
      ingredients,
    });

export const setCurrentAlteredIngredients =
  (alteredIngredients: number[]) => async (dispatch: Dispatch) =>
    dispatch({
      type: Types.SET_CURRENT_ALTERED_INGREDIENTS,
      alteredIngredients,
    });

export function setRecipe(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(setCurrentRecipe(id));
  };
}

export function setAlteredSections(s: IAlteredSections) {
  return async (dispatch: Dispatch) => {
    dispatch(setAlteredSectionsAction(s));
  };
}

export function setRecipeAmount(amount: number) {
  return async (dispatch: Dispatch) => {
    dispatch(setRecipeAmounAction(amount));
  };
}

export function fetchRecipes(): ThunkAction<
  Promise<IRecipeAction>,
  IState,
  undefined,
  IRecipeAction
> {
  return async (dispatch) => {
    dispatch(requestRecipes);
    try {
      const response = await fetch(config.RECIPE_URL, { mode: 'cors' });
      const json = await response.json();
      return dispatch(receiveRecipes(json));
    } catch (error) {
      return dispatch(errorRecipes(error));
    }
  };
}

export function getRecipes(): ThunkAction<
  Promise<IRecipeAction>,
  IState,
  undefined,
  IRecipeAction
> {
  return async (dispatch, getState) => {
    const check = dispatch(checkRecipes);
    if (
      (!getState().recipeState.isFetching &&
        !getState().recipeState.dataLoaded) ||
      config.TESTING
    ) {
      return dispatch(fetchRecipes());
    } else {
      return check;
    }
  };
}

export const updateCurrentRecipeData = (recipe: Recipe) => {
  return async (dispatch: Dispatch) => {
    dispatch(updateCurrentRecipeDataAction(recipe));
  };
};
