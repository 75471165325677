import { StyleSheet, Platform } from 'react-native';
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../../config/style';
import {
  IS_TABLET,
  IS_SMALL_DEVICE,
  IS_TINY_DEVICE,
} from '../../helpers/device';

export default StyleSheet.create({
  button: {
    backgroundColor: PRIMARY_THEME_COLORS.red,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  standardContainer: {
    backgroundColor: 'rgba(0,0,0,0)',
    ...Platform.select({
      default: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.5,
        shadowRadius: 6,
        shadowOffset: { width: 0, height: 5 },
      },
      android: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.5,
        shadowRadius: 6,
        shadowOffset: { width: 0, height: 5 },
        elevation: 3,
      },
      web: {
        boxShadow: '0px 8px 30px 0px rgba(0,0,0,0.3)',
      },
    }),
  },
  largeButton: {
    minHeight: IS_TABLET ? 90 : IS_TINY_DEVICE ? 65 : 75,
    width: WINDOW_SIZE.width - 40,
    maxWidth: IS_TINY_DEVICE ? 320 : 370,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    borderRadius: 10,
  },
  largeButtonContainer: {
    borderRadius: 10,
    overflow: 'hidden',
  },
  largeButtonTextContainer: {},
  largeButtonText: {
    color: 'white',
    fontSize: IS_TABLET ? 38 : 26,
    fontFamily: 'clan-pro-book',
    padding: 0,
    margin: 0,
  },
  largeButtonIcon: {
    height: IS_TABLET ? 60 : IS_SMALL_DEVICE ? 35 : 55,
    width: IS_TABLET ? 60 : 50,
    resizeMode: 'contain',
  },
  standard: {
    height: 70,
    minWidth: 220,
    maxWidth: 220,
    borderRadius: 70,
  },
  minorRadius: {
    height: 50,
    minWidth: 200,
    maxWidth: 200,
    margin: 10,
    borderRadius: 15,
  },
  text: {
    color: 'white',
    fontSize: IS_TABLET ? 30 : 22,
    lineHeight: Platform.OS === 'ios' ? 38 : 34,
    textAlign: 'center',
    textAlignVertical: 'center',
    alignSelf: 'center',
  },
  floatingAction: {
    height: 60,
    width: 60,
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
  },
  floatingActionContainer: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 65,
    right: 30,
    borderRadius: 50,
    ...Platform.select({
      default: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.5,
        shadowRadius: 8,
        shadowOffset: { width: 0, height: 8 },
      },
      android: {
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.5,
        shadowRadius: 8,
        shadowOffset: { width: 0, height: 8 },
        elevation: 3,
      },
      web: {
        boxShadow: '0px 8px 30px 0px rgba(0,0,0,0.3)',
      },
    }),
  },
  addImage: {
    height: 30,
    width: 30,
  },
  floatingActionLeft: {},
  floatingActionRight: {},
});
