import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Screen } from '../../interface';

class PrivacyPolicyPage extends Screen {
  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>INTEGRITETSPOLICY</Text>
        <Text style={styles.text}>
          Detta dokument uppdaterades senast 28/8 2019
        </Text>
        <Text style={styles.text}>
          Matfröjd samlar ej in och sparar heller ingen personlig information om
          användaren.
        </Text>
        <Text style={[styles.text, styles.bold]}>
          Ändringar i Integritetspolicyn
        </Text>
        <Text style={styles.text}>
          Matfröjd förbehåller sig rätten att ensam och när som helst ändra
          denna Integritetspolicy. Förändringar som vi anser vara av väsentlig
          betydelse kommer att meddelas dig genom Tjänsten och/eller per
          SMS/e-post och träda i kraft tidigast en kalendermånad efter att du
          meddelats om ändringarna. Matfröjd förbehåller sig rätten att i
          undantagsfall vidta förändringar som träder i kraft omedelbart. Även
          sådana ändringar kommer att meddelas dig genom Tjänsten och/eller per
          SMS/epost. Vill du inte acceptera en ändring av Integritetspolicyn ska
          du upphöra med användningen av Tjänsten.
        </Text>
        <Text style={[styles.text, styles.bold]}>Datainspektionen</Text>
        <Text style={styles.text}>
          Datainspektionen är tillsynsmyndighet för behandling av
          personuppgifter.
          {<br />}
          Kontaktuppgifter till Datainspektionen finner du nedan:
        </Text>
        <Text style={styles.text}>
          Datainspektionen {<br />}
          Box 8114 {<br />}
          104 20 {<br />}
          Stockholm {<br />}
          Telefon: 08-657 61 00 {<br />}
          E-post: datainspektionen@datainspektionen.se
        </Text>
      </View>
    );
  }
}

export default PrivacyPolicyPage;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 100,
    maxWidth: 800,
    paddingHorizontal: 16,
  },
  text: {
    marginBottom: 24,
    fontSize: 20,
  },
  bold: {
    fontWeight: 'bold',
  },
});
