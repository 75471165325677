import React, { ReactNode } from 'react';
import { NavigationScreenProps } from 'react-navigation';
import MenuButton from '../../components/menu_button';
import { View, Image, Platform, ImageSourcePropType, Text } from 'react-native';
import logo from '../../../assets/logos/icon_clean.png';
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../../config/style';
import { IState } from '../../model/state';
import { connect } from 'react-redux';
import { MenuItem } from '../../model';
import { NavigationScreenProp } from 'react-navigation';
import HTMLView, { HTMLViewNode } from 'react-native-htmlview';
import { WebView } from 'react-native-webview';
import MfImage from '../../components/mf_image';
import HtmlStyles from './html_styles';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { BACKEND_URL } from '../../config';
import MfContainer from '../../components/mf_container';
import loader from '../../../assets/logos/icon_clean.png';
import { IS_TABLET } from '../../helpers/device';
import Routes, { ERoutes } from '../../navigation/routes';
import { IS_WEB } from '../../config/style/web_helper';

export interface ReactProps {
  navigation: NavigationScreenProp<any, any>;
  webNavigate: WebNavigate;
  webNavigation: {
    setHeaderRight: Function;
    actionCallback: Function;
    title: string;
    setNavBarBackgroundColor: Function;
    goBack: () => void;
  };
}

interface WebNavigate {
  (route: ERoutes, nextTitle: string): void;
}

interface ReduxProps {
  menu: MenuItem[];
}
interface DispatchProps {}
type Props = ReduxProps & ReactProps & DispatchProps;

const imageStyle = {
  width: undefined,
  height: undefined,
  flex: 1,
  alignSelf: 'stretch' as 'stretch',
};

class MenuItemScreen extends React.Component<Props> {
  state = {
    content: '',
  };

  static navigationOptions = ({ navigation }: NavigationScreenProps) => {
    return {
      headerLeft: <MenuButton onPress={() => navigation.toggleDrawer()} />,
      headerRight: (
        <View style={{ height: 30, width: 30, marginRight: 10 }}>
          <Image style={imageStyle} source={logo as ImageSourcePropType} />
        </View>
      ),
    };
  };

  componentDidMount() {
    if (Platform.OS !== 'web') {
      if (this.props.navigation.getParam('routeKey'))
        this.setContent(this.props.navigation.getParam('routeKey'));
    } else {
      this.props.webNavigation.setHeaderRight(
        <Image
          style={{ width: 40, height: 40 }}
          source={{ uri: logo } as ImageSourcePropType}
        />
      );
      this.props.webNavigation.setNavBarBackgroundColor(
        PRIMARY_THEME_COLORS.recipes
      );
      this.setWebContent(window.location.pathname.split('/')[1]);
    }
  }

  setWebContent(routeKey: string) {
    let key = '';
    switch (routeKey) {
      case Routes.information.route.slice(1):
        key = Routes.information.name;
        break;
      case Routes.movies.route.slice(1):
        key = Routes.movies.name;
        break;
      case Routes.instructions.route.slice(1):
        key = Routes.instructions.name;
        break;
      default:
    }
    this.setContent(key);
  }

  setContent(routeKey: string) {
    const content = this.props.menu.find(
      (m) => m.str_id === routeKey.toUpperCase()
    );
    this.setState({ content: content?.content });
  }

  renderNode(
    node: HTMLViewNode,
    index: number,
    siblings: HTMLViewNode,
    parent: HTMLViewNode,
    defaultRenderer: (node: HTMLViewNode, parent: HTMLViewNode) => ReactNode
  ) {
    if (node.name === 'p' && Platform.OS === 'android') {
      if (
        'children' in node &&
        (node as any).children.length > 0 &&
        (node as any).children[0].name === 'img'
      ) {
        return defaultRenderer((node as any).children, (node as any).parent);
      }
    }
    if (node.name === 'iframe') {
      if (IS_WEB) {
        return (
          <TouchableOpacity
            onPress={() => {
              window.open(node.attribs.src, '_blank');
            }}
          >
            <Text style={{ textDecorationLine: 'underline', color: '#007AFF' }}>
              Klicka här för att spela upp klippet
            </Text>
          </TouchableOpacity>
        );
      } else {
        const height = IS_TABLET ? '90%' : '100%';
        const width = IS_TABLET ? '92%' : '92%';
        const iframe = `<iframe src="${node.attribs.src}" width=${width} height=${height} frameborder="0" allowfullscreen=""></iframe>`;
        return (
          <View
            key={index}
            style={{
              width: WINDOW_SIZE.width,
              height: IS_TABLET ? 500 : 300,
            }}
          >
            <WebView scalesPageToFit={true} source={{ html: iframe }} />
          </View>
        );
      }
    }
    if (node.name === 'img') {
      return (
        <MfImage
          key={index}
          style={{
            height: parseInt(node.attribs.height),
            width: parseInt(node.attribs.width),
          }}
          loader={
            <Image
              style={{
                height: parseInt(node.attribs.height),
                width: parseInt(node.attribs.width),
              }}
              source={loader as ImageSourcePropType}
            ></Image>
          }
          source={`${BACKEND_URL}/${node.attribs.src}`}
        />
      );
    }
    return undefined;
  }

  render() {
    return (
      <MfContainer>
        <ScrollView
          contentContainerStyle={{
            paddingBottom: 50,
            padding: 10,
          }}
        >
          <HTMLView
            addLineBreaks={false}
            stylesheet={HtmlStyles}
            value={this.state.content}
            renderNode={this.renderNode}
          />
        </ScrollView>
      </MfContainer>
    );
  }
}
const mapStateToProps = (state: IState) => {
  return {
    menu: state.menuState.menuContent,
  };
};

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(
  mapStateToProps,
  {}
)(MenuItemScreen);
