import React from 'react'
import {Component} from 'react'
import styles from './styles'
import icon from '../../../assets/logos/icon_clean.png'
import { View, Image, ImageSourcePropType, Animated } from 'react-native';
import MfText, { TEXT_STYLES } from '../mf_text';
import AnimatedOpacity from '../animated_opacity';

interface ILoader {
    text?: string,
    small?: boolean
}

class Loader extends Component<ILoader> {

    render() {
        if (this.props.small) {
            return (
                <AnimatedOpacity from={1} to={0.3} duration={800} delay={0} onAnimationEnd={() => {}} loop={true}>
                    <View style={styles.loader}>
                        <Image style={[styles.image, this.props.small ? styles.smallImage : null]} source={icon as ImageSourcePropType} />
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>Laddar...</MfText>
                        {this.props.text ? <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{this.props.text}</MfText>:null}
                    </View>
                </AnimatedOpacity>
            )
        }
        return (
            <View style={styles.container}>
                <AnimatedOpacity from={1} to={0.3} duration={800} delay={0} onAnimationEnd={() => {}} loop={true}>
                    <View style={styles.loader}>
                        <Image style={[styles.image, this.props.small ? styles.smallImage : null]} source={icon as ImageSourcePropType} />
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>Laddar...</MfText>
                        {this.props.text ? <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{this.props.text}</MfText>:null}
                    </View>
                </AnimatedOpacity>
                
            </View>
        )
    }
}

export default Loader
