import React from 'react';
import { Component } from 'react';
import styles from './styles';
import {
  View,
  SafeAreaView,
  Image,
  ImageSourcePropType,
  ScrollView,
} from 'react-native';
import MfModal, { animationTypes } from '../../../../../components/mf_modal';
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import Close from '../../../../../../assets/ui/close.png';
import Touchable from '../../../../../components/touchable';
import { INutritionModal } from './i_nutrition_modal';
import {
  CalculatedNutrion,
  calFatEnergi,
} from '../../../../../helpers/nutritions_handlers';
import { IS_TABLET } from '../../../../../helpers/device';
import { WINDOW_SIZE } from '../../../../../config/style';
import PieChart from 'react-minimal-pie-chart';
import { getUnitDisplayText } from '../../../../../helpers/recipeOverview';
import NutritionExplanation from '../../../../../components/nutrition_explanation';

const colors = {
  amount: '#33FF93',
  ofRec: '#33A5FF',
  totRec: '#DD33FF',
};

class NutritionModal extends Component<INutritionModal> {
  onClose() {
    this.props.onClose();
  }

  insertDecimal(num: number) {
    return parseFloat((num / 100).toFixed(1).toString());
    return num;
  }

  checkNutritions() {
    let isOk = true;
    const { nutritions } = this.props;
    Object.values(nutritions).forEach((nutrition: CalculatedNutrion) => {
      if (nutrition.title !== undefined && nutrition.title === '') {
        isOk = false;
      }
    });
    return isOk;
  }

  getHelpText() {
    const circleSize = 30;
    return (
      <View style={styles.help}>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{`${getText(
          'Näringsinnehåll per'
        )} ${this.props.measurement}*`}</MfText>
        <View style={styles.helpContainer}>
          <svg height={circleSize} width={circleSize + 10}>
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={circleSize / 2}
              fill={colors.amount}
            />
          </svg>
          <MfText textStyle={TEXT_STYLES.BOLD_PLAIN}>
            {getText('= Summa')}
          </MfText>
        </View>
        <View style={styles.helpContainer}>
          <svg height={circleSize} width={circleSize + 10}>
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={circleSize / 2}
              fill={colors.ofRec}
            />
          </svg>
          <MfText textStyle={TEXT_STYLES.BOLD_PLAIN}>
            {getText('= % av dagsrekommendation')}
          </MfText>
        </View>
        <View style={styles.helpContainer}>
          <svg height={circleSize} width={circleSize + 10}>
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={circleSize / 2}
              fill={colors.totRec}
            />
          </svg>
          <MfText textStyle={TEXT_STYLES.BOLD_PLAIN}>
            {getText('= Dagsrekommendation')}
          </MfText>
        </View>
      </View>
    );
  }

  getNutItem(
    key: number,
    title: string,
    amount: number,
    ofRec: number,
    totRec: number,
    unit: string
  ) {
    const circleSize = 20;
    return (
      <View key={key} style={styles.nutContainerSmall}>
        <MfText textStyle={TEXT_STYLES.SUB_HEADER}>
          {`${title} ${getUnitDisplayText(unit)}`}
        </MfText>
        <View style={styles.nutContentContainer}>
          <View style={styles.nutContent}>
            <svg height={circleSize} width={circleSize + 10}>
              <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={circleSize / 2}
                fill={colors.amount}
              />
            </svg>
            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {this.insertDecimal(amount)}
            </MfText>
          </View>
          <View style={styles.nutContent}>
            <svg height={circleSize} width={circleSize + 10}>
              <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={circleSize / 2}
                fill={colors.ofRec}
              />
            </svg>
            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{`${this.insertDecimal(
              ofRec
            )} %`}</MfText>
          </View>
          <View style={styles.nutContent}>
            <svg height={circleSize} width={circleSize + 10}>
              <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={circleSize / 2}
                fill={colors.totRec}
              />
            </svg>
            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {this.insertDecimal(totRec)}
            </MfText>
          </View>
        </View>
      </View>
    );
  }

  getNutText() {
    const { nutritions } = this.props;
    const circleSize = 20;
    let i = 0;
    // @ts-ignore
    const nuts = Object.values(nutritions).map((nut) => {
      const nutr = nut as CalculatedNutrion;
      const ofRecommendation = (nutr.amount / nutr.recommendedAmount) * 10000;
      if (nutr.title !== undefined) {
        i++;
        if (IS_TABLET) {
          return (
            <View key={i} style={[styles.nutContainer]}>
              <svg height={circleSize} width={circleSize + 10}>
                <circle
                  cx={circleSize / 2}
                  cy={circleSize / 2}
                  r={circleSize / 2}
                  fill={nutr.color}
                />
              </svg>
              <MfText
                style={styles.nutText}
                textStyle={TEXT_STYLES.PLAIN_TEXT}
              >{`${nutr.title} ${getUnitDisplayText(nutr.unit)}`}</MfText>
              <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {this.insertDecimal(nutr.amount)}
              </MfText>
              <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {this.insertDecimal(ofRecommendation)}
              </MfText>
              <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {this.insertDecimal(nutr.recommendedAmount)}
              </MfText>
            </View>
          );
        }
        return this.getNutItem(
          i,
          nutr.title,
          nutr.amount,
          ofRecommendation,
          nutr.recommendedAmount,
          nutr.unit
        );
      }
    });
    const fatNutr = nutritions.fat.fat_total;
    const ofRecommendation =
      (fatNutr.amount / fatNutr.recommendedAmount) * 1000;
    const fat = !IS_TABLET ? (
      this.getNutItem(
        i,
        fatNutr.title,
        fatNutr.amount,
        ofRecommendation,
        fatNutr.recommendedAmount,
        fatNutr.unit
      )
    ) : (
      <View key={i} style={[styles.nutContainer]}>
        <svg height={circleSize} width={circleSize + 10}>
          <circle
            cx={circleSize / 2}
            cy={circleSize / 2}
            r={circleSize / 2}
            fill={fatNutr.color}
          />
        </svg>
        <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>{`${
          fatNutr.title
        } ${getUnitDisplayText(fatNutr.unit)}`}</MfText>
        {/* <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>{Math.round(nutr.amount)}</MfText> */}
        <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {this.insertDecimal(fatNutr.amount)}
        </MfText>
        <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {this.insertDecimal(ofRecommendation)}
        </MfText>
        {IS_TABLET ? (
          <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
            {this.insertDecimal(fatNutr.recommendedAmount)}
          </MfText>
        ) : null}
      </View>
    );

    return (
      <View style={styles.textContainer}>
        {IS_TABLET && (
          <View style={styles.nutsContainer}>
            <View style={styles.fill} />
            <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {getText('Typ')}
            </MfText>
            <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {getText('Summa')}
            </MfText>
            <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {getText('% av dagsrekommendation')}
            </MfText>
            <MfText style={styles.nutText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
              {getText('Dagsrekommendation')}
            </MfText>
          </View>
        )}
        {nuts}
        {fat}
      </View>
    );
  }

  getEnergi() {
    const { nutritions } = this.props;
    const data: CalculatedNutrion[] = [];

    data.push(nutritions.carbs);
    data.push(nutritions.fibers);
    data.push(nutritions.protein);
    data.push(nutritions.fat.fat_total);

    const circleSize = 10;
    return (
      <View style={styles.fatContainer}>
        <MfText style={styles.margin} textStyle={TEXT_STYLES.SUB_HEADER}>
          {getText('Energifördelning')}
        </MfText>
        {IS_TABLET ? this.getBarChart(data) : this.getPie(data)}
        {!IS_TABLET && (
          <View>
            {Object.values(data).map((f) => {
              return (
                <View key={f.title + 'f'} style={styles.helpContainer}>
                  <svg height={circleSize} width={circleSize + 10}>
                    <circle
                      cx={circleSize / 2}
                      cy={circleSize / 2}
                      r={circleSize / 2}
                      fill={f.color}
                    />
                  </svg>
                  <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{f.title}</MfText>
                </View>
              );
            })}
          </View>
        )}
      </View>
    );
  }

  getFat() {
    const { fat } = this.props.nutritions;
    const data: CalculatedNutrion[] = [];

    data.push(fat.fat_mono);
    data.push(fat.fat_poly);
    data.push(fat.fat_sat);

    const circleSize = 10;
    return (
      <View style={styles.fatContainer}>
        <MfText style={styles.margin} textStyle={TEXT_STYLES.SUB_HEADER}>
          {getText('Fettsyrornas andel i procent av den totala energin')}
        </MfText>
        {IS_TABLET ? this.getBarChart(data) : this.getPie(data)}
        {!IS_TABLET && (
          <View>
            {Object.values(fat).map((f) => {
              if (f === fat.fat_total) {
              } else
                return (
                  <View key={f.title} style={styles.helpContainer}>
                    <svg height={circleSize} width={circleSize + 10}>
                      <circle
                        cx={circleSize / 2}
                        cy={circleSize / 2}
                        r={circleSize / 2}
                        fill={f.color}
                      />
                    </svg>
                    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
                      {f.title}
                    </MfText>
                  </View>
                );
            })}
          </View>
        )}
      </View>
    );
  }

  getPie(data: CalculatedNutrion[]) {
    const pieData = data.map((nut) => {
      const amount = calFatEnergi(this.props.nutritions, nut);
      const rounded = Math.round(amount * 10) / 10;
      return {
        title: `${rounded} %`,
        // title: 'tjena!',
        value: calFatEnergi(this.props.nutritions, nut),
        color: nut.color,
      };
    });
    return (
      <PieChart
        label={(l) => {
          return l.data[l.dataIndex].title || '';
        }}
        labelStyle={{ fontSize: 8, fill: 'white' }}
        data={pieData}
      />
    );
  }

  getBarChart(data: CalculatedNutrion[]) {
    const max = Math.max(...data.map((f) => f.amount));
    const maxWidth = WINDOW_SIZE.width / 1.7;
    return (
      <View>
        {data.map((f) => {
          const width = f.amount / max;
          const style = {
            backgroundColor: f.color,
            width: maxWidth * width,
            height: 35,
            justifyContent: 'center' as 'center',
            paddingLeft: 5,
          };
          const amount = calFatEnergi(this.props.nutritions, f);
          const rounded = Math.round(amount * 10) / 10;
          return (
            <View style={styles.barContainer} key={f.title}>
              <MfText style={styles.barText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {f.title}
              </MfText>
              <View style={style}>
                <MfText
                  style={styles.barTextContent}
                  textStyle={TEXT_STYLES.PLAIN_TEXT}
                >
                  {rounded} %
                </MfText>
              </View>
            </View>
          );
        })}
      </View>
    );
  }

  render() {
    return (
      <MfModal
        animationType={animationTypes.SLIDE}
        transparent={true}
        visibile={this.props.visible}
        onClose={() => this.onClose()}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Touchable onPress={() => this.onClose()}>
              <Image
                style={styles.icon}
                source={Close as ImageSourcePropType}
              />
            </Touchable>
            <MfText style={styles.headerText} textStyle={TEXT_STYLES.HEADER}>
              {IS_TABLET
                ? `${getText('Näringsinnehåll per')} ${this.props.measurement}`
                : getText('Näringsinnehåll')}
            </MfText>
            <View style={styles.headerRight}></View>
          </View>
          {this.checkNutritions() ? (
            <ScrollView>
              {!IS_TABLET && this.getHelpText()}
              {this.getNutText()}
              {this.getEnergi()}
              {this.getFat()}
              <View style={styles.spacerWeb} />
              <NutritionExplanation />
            </ScrollView>
          ) : (
            <View>
              <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
                {getText('Ingen näringsberäkning kunde genomföras')}
              </MfText>
            </View>
          )}
        </SafeAreaView>
      </MfModal>
    );
  }
}

export default NutritionModal;
