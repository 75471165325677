import { StyleSheet } from 'react-native';
import {
  PRIMARY_THEME_COLORS,
  SECONDARY_THEME_COLORS,
} from '../../../../config/style';

export default StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  containerWeb: {
    width: '100%',
    marginTop: 60,
  },
  tabletContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    flexDirection: 'row',
  },
  tabletLeft: {
    flex: 3,
  },
  tabletRight: {
    flex: 2,
    padding: 20,
    paddingBottom: 0,
    backgroundColor: SECONDARY_THEME_COLORS.lightGreen,
  },
  nutrButton: {
    height: 30,
    width: 30,
    borderRadius: 30,
    backgroundColor: PRIMARY_THEME_COLORS.recipes,
  },
  portionContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
  portionButtonContainer: {
    flexDirection: 'row',
  },
  portionButton: {
    height: 40,
    width: 60,
  },
  portionText: {
    fontWeight: 'bold',
  },
});
