import React from 'react';
import { Component } from 'react';
import ShoppingListDetailContainer from './shopping_list_detail_container';
import { NavigationScreenProps } from 'react-navigation';
import ShareButton from '../../../components/nav_buttons/share_button';
import { Platform } from 'react-native';
import { Screen, IScreen } from '../../../interface/screen';
import { PRIMARY_THEME_COLORS } from '../../../config/style';
import { getText } from '../../../helpers/text/translation';
import PopupMenu from '../../../components/popup-menu/';
import { ICONS } from '../../../components/popup-menu/index.native';

class ShoppingListDetail extends Screen {
  state = {
    triggerShare: false,
    triggerDelete: false,
    triggerRename: false,
    title: '',
  };
  constructor(props: IScreen) {
    super(props);
  }

  static navigationOptions = ({ navigation }: NavigationScreenProps) => {
    let title;
    if (navigation.state.params && 'title' in navigation.state.params) {
      title = navigation.state.params['title'];
    } else {
      title = '';
    }
    return {
      headerRight: (
        <PopupMenu
          options={[
            {
              title: getText('Dela'),
              icon: ICONS.SHARE,
              onSelect: navigation.getParam('shareOnPress'),
            },
            {
              title: getText('Ändra namn'),
              icon: ICONS.EDIT,
              onSelect: navigation.getParam('renameOnPress'),
            },
            {
              title: getText('Radera'),
              icon: ICONS.DELETE,
              onSelect: navigation.getParam('deleteOnPress'),
            },
          ]}
          id={'shopping-more'}
        />
      ),
      // headerTitle: navigation.getParam('title'),
      headerTitle: title,
    };
  };

  componentDidMount() {
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        shareOnPress: this.onTriggerShare,
        renameOnPress: this.onTriggerRename,
        deleteOnPress: this.onTriggerDelete,
      });
    } else {
      this.props.webNavigation.setHeaderRight(
        <PopupMenu
          options={[
            {
              title: getText('Dela'),
              icon: ICONS.SHARE,
              onSelect: this.onTriggerShare,
            },
            {
              title: getText('Ändra'),
              icon: ICONS.EDIT,
              onSelect: this.onTriggerRename,
            },
            {
              title: getText('Radera'),
              icon: ICONS.DELETE,
              onSelect: this.onTriggerDelete,
            },
          ]}
          id={'shopping-more'}
        />
      );
    }
  }

  onTriggerShare = () => {
    this.setState({ triggerShare: true });
  };

  onShareRespond = () => {
    this.setState({ triggerShare: false });
  };
  onTriggerDelete = () => {
    this.setState({ triggerDelete: true });
  };

  onDeleteRespond = () => {
    this.setState({ triggerDelete: false });
  };

  onTriggerRename = () => {
    this.setState({ triggerRename: true });
  };

  onRenameRespond = () => {
    this.setState({ triggerRename: false });
  };

  onGoBack = () => {
    if (Platform.OS === 'web') {
      this.props.webNavigation.goBack();
    } else {
      this.props.navigation.goBack();
    }
  };

  setTitle = (t: string) => {
    this.props.navigation.setParams({ title: t });
  };

  render() {
    return (
      <ShoppingListDetailContainer
        triggerShare={this.state.triggerShare}
        triggerDelete={this.state.triggerDelete}
        triggerRename={this.state.triggerRename}
        onShareRespond={this.onShareRespond}
        onDeleteRespond={this.onDeleteRespond}
        onRenameRespond={this.onRenameRespond}
        onDeleteGoBack={this.onGoBack}
        onNavigate={() => {}}
        setTitle={this.setTitle}
      />
    );
  }
}

export default ShoppingListDetail;
