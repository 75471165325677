import { combineReducers } from 'redux';
import recipeReducer from './recipes'
import categoryReducer from './categories'
import pantryReducer from './pantry'
import favoriteReducer from './favorites'
import shoppListReducer from './shopping_lists'
import ingredientReducer from './ingredients'
import nutritionReducer from './nutritions'
import configReducer from './config'
import menuReducer from './menu'

const rootReducer = combineReducers( {
    recipeState: recipeReducer, 
    categoryState: categoryReducer, 
    pantryState: pantryReducer, 
    favoriteState: favoriteReducer,
    shoppingListState: shoppListReducer,
    ingredientState: ingredientReducer,
    nutritionState: nutritionReducer,
    configState: configReducer,
    menuState: menuReducer
});



export default rootReducer