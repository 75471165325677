import React from 'react'
import {Component} from 'react'
import {INavButton} from '../nav_button/i_nav_button'
import NavButton from '../nav_button'

class DeleteButton extends Component<INavButton> {
    render() {
        return (
            <NavButton onPress={() => (this.props.onPress as Function)()} name={"delete"} />
        )
    }
}

export default DeleteButton