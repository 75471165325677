import React from 'react'
import {Component} from 'react'
import { View} from 'react-native';
import {IMfShare} from './i_mf_share'
import { getText } from '../../helpers/text/translation'

class MfShare extends Component<IMfShare> {
    constructor(props: IMfShare) {
        super(props)
        this.mailto()
    }
    mailto() {
        window.location.href = `mailto:?subject=${getText('Inköpslista')}&body=${this.props.message}`
    }
    render() {
        return null
    }
}

export default MfShare
