import React from 'react';
import { Linking, Text, View } from 'react-native';
import MfText, { TEXT_STYLES } from '../mf_text';
import styles from './styles';

const NutritionExplanation = () => (
  <View style={styles.containerExplanation}>
    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
      * Näringsvärdet är beräknat på råvaror i rättens ingredienslista med
      värden från Livsmedelsverkets näringsdatabas. Alla näringsvärden ska ses
      som ungefärliga.
    </MfText>
    <View style={styles.spacer} />
    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
      Referens för dagsrekommendation är ”Kvinna över 74 år, inaktiv”.
    </MfText>
    <View style={styles.spacer} />
    <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
      För mer info om näringsinnehåll och livsmedelsval se{' '}
      <Text
        style={styles.linkExplanation}
        onPress={() => {
          Linking.openURL('http://www.matforaldre.nu').catch((err: any) =>
            console.error('An error occurred', err)
          );
        }}
      >
        www.matforaldre.nu
      </Text>{' '}
      under rubrik ”Handbok”.
    </MfText>
  </View>
);

export default NutritionExplanation;
