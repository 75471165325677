import { StyleSheet } from 'react-native';
import {
  PRIMARY_THEME_COLORS,
  TEXT_COLORS,
  WINDOW_SIZE,
} from '../../../../../config/style';

export default StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
  nutContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: '#e0ebeb',
  },
  nutContainerSmall: {
    borderWidth: 1,
    borderColor: 'grey',
    margin: 10,
    borderRadius: 10,
    padding: 5,
  },
  textContainer: {
    // padding: 10
  },
  nutText: {
    flex: 1,
    marginRight: 10,
  },
  header: {
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: PRIMARY_THEME_COLORS.recipes,
  },
  headerRight: {
    width: 40,
  },
  icon: {
    height: 40,
    width: 40,
  },
  headerText: {
    color: TEXT_COLORS.light,
  },
  fill: {
    width: 30,
  },
  nutsContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: 20,
    paddingRight: 20,
  },
  nutContent: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    margin: 10,
  },
  nutContentContainer: {
    flexDirection: 'row',
  },
  help: {
    padding: 10,
  },
  helpContainer: {
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center',
  },
  fatContainer: {
    marginTop: 20,
    padding: 10,
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pieContainer: {
    width: WINDOW_SIZE.width,
  },
  barContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: 5,
  },
  barText: {
    width: WINDOW_SIZE.width / 3.5,
    marginRight: 10,
  },
  barTextContent: {
    color: 'white',
  },
  margin: {
    margin: 10,
    marginBottom: 30,
  },
  spacerWeb: {
    height: 32,
  },
});
