import React from 'react'
import {Component} from 'react'
import {View} from 'react-native'
import styles from './styles'
import MfModal, { animationTypes } from '../mf_modal';
import Button from '../button';
import { getText } from '../../helpers/text/translation';
import MfText, { TEXT_STYLES } from '../mf_text';
import { BUTTON_VARIANTS } from '../button/button';

interface IAlert {
    title?: string,
    content: string
    onAccept: Function,
    onDenied: Function,
    onClose: Function,
    visible: boolean
}

class Alert extends Component<IAlert> {
    onAccept() {
        this.props.onAccept()
        this.props.onClose()
    }
    onDenied() {
        this.props.onDenied()
        this.props.onClose()
    }
    render() {
        return (
            <MfModal animationType={animationTypes.FADE} visibile={this.props.visible} transparent={true} onClose={() => this.props.onClose()}>
                <View style={styles.container}>
                    <View style={styles.popup}>
                        <View style={styles.title}>
                            <MfText textStyle={TEXT_STYLES.HEADER}>
                                {this.props.title}
                            </MfText>
                        </View>
                        <View style={styles.content}>
                            <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
                                {this.props.content}
                            </MfText>
                        </View>
                        <View style={styles.buttonContainer}>
                            <Button variant={BUTTON_VARIANTS.NO_RADIUS} style={[styles.button, styles.buttonDenied]} title={getText('Nej')} onPress={() => this.onDenied()}/>
                            <Button variant={BUTTON_VARIANTS.NO_RADIUS} style={[styles.button, styles.buttonAccept]} title={getText('Ja')} onPress={() => this.onAccept()}/>
                        </View>
                    </View>
                </View>
            </MfModal>
        )
        
    }

}

export default Alert
