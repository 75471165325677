import {StyleSheet} from 'react-native'
import { WEB_SCROLLVIEW_STYLE } from '../../config/style/web_helper'

export default StyleSheet.create({
    containerWeb: {
        marginTop: 60,
        height: WEB_SCROLLVIEW_STYLE.height,
        
    },
    container: {
        height: "100%",
        // paddingBottom: 20
    },
    noMargin: {
        marginTop: 0
    }
    
})