import { PRIMARY_THEME_COLORS } from '../../config/style';
import {
  headerStyle,
  headerTintColor,
  headerTitleStyle,
  headerTitleStyleWithRightIcon,
} from '../styles';
import {
  Categories,
  Favorites,
  Pantry,
  RecipeInstructions,
  RecipeList,
  RecipeOverview,
  ShoppingListDetail,
  ShoppingLists,
  Start,
  Search,
} from '../../screens';
import { IStack } from '../';
import routes from '../routes';

const recipeStack: IStack = {
  [routes.start.name]: {
    screen: Start,
    navigationOptions: {
      title: routes.start.title,
      webRoute: routes.start.route,
      headerBackTitle: null,
      headerTitleStyle,
      headerTintColor,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: true,
    isStackRoot: true,
  },
  [routes.categories.name]: {
    screen: Categories,
    navigationOptions: {
      title: routes.categories.title,
      webRoute: routes.categories.route,
      headerBackTitle: null,
      headerTitleStyle,
      headerTintColor,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.recipeList.name]: {
    screen: RecipeList,
    navigationOptions: {
      title: routes.recipeList.title,
      webRoute: routes.recipeList.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.recipeOverview.name]: {
    screen: RecipeOverview,
    navigationOptions: {
      title: routes.recipeOverview.title,
      webRoute: routes.recipeOverview.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle: {
        ...headerTitleStyle,
        ...headerTitleStyleWithRightIcon,
      },
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.recipeInstructions.name]: {
    screen: RecipeInstructions,
    navigationOptions: {
      title: routes.recipeInstructions.title,
      webRoute: routes.recipeInstructions.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.shoppingList.name]: {
    screen: ShoppingLists,
    navigationOptions: {
      title: routes.shoppingList.title,
      webRoute: routes.shoppingList.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.shoppingListDetail.name]: {
    screen: ShoppingListDetail,
    navigationOptions: {
      title: routes.shoppingListDetail.title,
      webRoute: routes.shoppingListDetail.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.pantry.name]: {
    screen: Pantry,
    navigationOptions: {
      title: routes.pantry.title,
      webRoute: routes.pantry.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.inventory,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.favorites.name]: {
    screen: Favorites,
    navigationOptions: {
      title: routes.favorites.title,
      webRoute: routes.favorites.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.favorites,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
  [routes.search.name]: {
    screen: Search,
    navigationOptions: {
      title: routes.search.title,
      webRoute: routes.search.route,
      headerBackTitle: null,
      headerTintColor,
      headerTitleStyle,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: false,
  },
};

export default recipeStack;
