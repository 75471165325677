import React from 'react'
import {Component} from 'react'
import { View, Image, ImageSourcePropType, ScrollView, ImageBackground } from 'react-native';
import MfModal, { animationTypes } from '../../../../../components/mf_modal'
import styles from './style'
import { SafeAreaView } from 'react-native';
import Touchable from '../../../../../components/touchable';
import Close from '../../../../../../assets/ui/close_black.png'
import MfText, { TEXT_STYLES } from '../../../../../components/mf_text';
import { getText } from '../../../../../helpers/text/translation';
import { tip, Ingredient } from '../../../../../model';
import MfCollapsible from '../../../../../components/mf_collapsible';
import arrow from '../../../../../../assets/ui/arrow_down.png'
import CollapsibleCard from '../../../../../components/collapsible_card';
import { PRIMARY_THEME_COLORS } from '../../../../../config/style';
import { IS_TABLET } from '../../../../../helpers/device';
import Button, { BUTTON_VARIANTS } from '../../../../../components/button/button';
import wall from '../../../../../../assets/ui/wall_start.jpg'

interface IInfoModal {
    visible: boolean,
    onClose: Function,
    tips: tip[],
    fullWidth?: boolean,
    customComponent?: JSX.Element,
    onShowNutritions?: () => void
}

class InfoModal extends Component<IInfoModal> {
    state = {
        collapsed: this.setCollapsedElements()
    }
    setCollapsedElements() {
        const {tips} = this.props
        const obj : {[key:number]: boolean} = {}
        tips.forEach(tip => {
            obj[tip.id] = true
        })
        return obj
    }
    collapseOrExpand(key: number) {
        this.setState({collapsed: {...this.state.collapsed, [key]: !this.state.collapsed[key]}})
    }
    onClose() {
        this.props.onClose()
    }
    render() {
        const {tips, fullWidth, onShowNutritions} = this.props
        const {collapsed} = this.state
        return (
            <MfModal animationType={animationTypes.SLIDE} transparent={true} visibile={this.props.visible} onClose={() => this.onClose()}>
                <SafeAreaView style={styles.container}>
                    <View style={styles.contentContainer}>
                        <ImageBackground style={styles.background} source={wall as ImageSourcePropType}>
                        <View style={styles.header}>
                            <Touchable onPress={() => this.onClose()}>
                                <Image style={styles.icon} source={Close as ImageSourcePropType} />
                            </Touchable>
                            <MfText style={styles.headerText} textStyle={TEXT_STYLES.HEADER}>{getText('Information')}</MfText>
                            <View style={styles.headerRight}></View>
                        </View>
                        <ScrollView>
                            {this.props.children}
                            <View style={{alignItems: 'center'}}>
                                {tips.map(tip => {
                                    return (
                                        <CollapsibleCard 
                                            key={tip.id} 
                                            title={tip.name} 
                                            fullWidth={fullWidth}
                                            collapsed={collapsed[tip.id]} 
                                            html={true}
                                            content={tip.content} 
                                            headerColor={PRIMARY_THEME_COLORS.tips}
                                            onHeaderPress={() => this.collapseOrExpand(tip.id)} />
                                    )
                                })}
                                {onShowNutritions &&
                                <View style={styles.nutButtonContainer}>
                                    <Button 
                                        style={styles.nutButton} 
                                        variant={BUTTON_VARIANTS.NO_RADIUS} 
                                        borderRadiusStyle={styles.nutButtonRadius}
                                        title={getText("Visa näringsinnehåll")} 
                                        textColor='black'
                                        onPress={() => onShowNutritions()} />
                                </View>
                                }
                                
                            </View>
                        </ScrollView>
                        </ImageBackground>
                    </View>
                </SafeAreaView>
            </MfModal>
        )
    }
}

export default InfoModal