import React from 'react'
import {Component} from 'react'
import {View, ScrollView, Platform} from 'react-native'
import {IContainer} from '../../../../interface'
import { ShoppingList, RawShoppingList } from '../../../../model';
import { IState } from '../../../../model/state';
import { addShoppingList, removeShoppingList, setCurrentShoppingList } from '../../../../redux/actions';
import { connect } from 'react-redux';
import ListItem from '../../../../components/flat_list_item'
import styles from './styles'
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import AddShoppingListModal from './add_shopping_list_modal';
import MfContainer from '../../../../components/mf_container';
import { backgrounds } from '../../../../components/mf_container/mf_container';
import Alert from '../../../../components/alert';
import { getText } from '../../../../helpers/text/translation';
import MfSwipeable from '../../../../components/mf_swipeable';
import { PRIMARY_THEME_COLORS } from '../../../../config/style';
import AnimatedListItem from '../../../../components/animated_list_item';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import DeleteButton from '../../../../components/nav_buttons/delete_button/delete_button';

interface ReduxProps {shoppingLists: ShoppingList[]}
interface DispatchProps {
    addShoppingList: Function,
    removeShoppingList: Function,
    setCurrentShoppingList: Function
}
export interface ReactProps extends IContainer {
    triggerShare: boolean,
    onShareRespond: () => void,
    triggerRename: boolean,
    onRenameRespond: () => void,
    triggerDelete: boolean,
    onDeleteRespond: () => void,
}
type Props = ReduxProps & ReactProps & DispatchProps
interface State {}
interface hej {
    name: string,
    id: number
}

class ShoppingListContainer extends Component<Props, State> {

    state = {
        showAddModal: false,
        showDeleteListModal: false,
        deleteId: null,
        deleteList: false
    }
    componentDidMount() {
        // this.props.removeShoppingList(1)
    }
    itemOnPress(id: number, title: string) {
        if (this.props.triggerDelete) {
            this.showDeleteModal(id)
        } else {
            this.props.setCurrentShoppingList(id)
            .then(() => {
                this.props.setTitle(title)
                this.props.onNavigate()
            })
        }
        
    }
    onCreateNewList(title: string) {
        const s : RawShoppingList = {
            title: title,
            ingredients: []
        }
        this.props.addShoppingList(s)
        this.setState({showAddModal: false})
    }
    onDeleteAnimation() {
        this.setState({deleteList: true})
    }
    onDeleteAnimationEnd() {
        this.setState({deleteList: false})
    }
    onDelete() {
        this.onDeleteAnimationEnd()
        this.props.removeShoppingList(this.state.deleteId)
    }
    onCloseDeleteModal() {
        this.setState({showDeleteListModal: false})
    }
    showDeleteModal(id: number) {
        this.setState({deleteId: id}, () => {
            this.setState({showDeleteListModal: true})
        })
    }

    sortShoppingLists(s: ShoppingList[]) {
        return s.sort((a,b) => {
            if (a.creationDate < b.creationDate) return 1
            if (a.creationDate > b.creationDate) return -1
            return 0
        })
    }
    render() {
        const {shoppingLists, triggerDelete} = this.props
        const {deleteId, deleteList} = this.state
        return (
            <MfContainer backgroundImage={backgrounds.SHOPPING_LIST} style={styles.container}>
            <Alert 
                title={getText('Ta bort')} 
                content={getText('Vill du ta bort den här inköpslistan?')}
                onClose={() => this.onCloseDeleteModal()}
                onAccept={() => this.onDeleteAnimation()}
                onDenied={() => this.onCloseDeleteModal()}
                visible={this.state.showDeleteListModal}
                />
            <AddShoppingListModal 
                visible={this.state.showAddModal} 
                onClose={() => this.setState({showAddModal: false})} 
                onCreate={(title: string) => this.onCreateNewList(title)}/>
                {shoppingLists.length > 0 ? 
                    <ScrollView 
                        scrollEventThrottle={4} 
                        contentContainerStyle={Platform.OS === 'web' ? styles.contentContainerWeb : styles.contentContainer}>
                        {this.sortShoppingLists(shoppingLists).map(shoppingList => {
                                return (
                                    <AnimatedListItem 
                                        deleteAnimation={shoppingList.id === deleteId && deleteList} 
                                        key={shoppingList.id} 
                                        onAnimationEnd={() => this.onDelete()}>
                                        <MfSwipeable 
                                            key={shoppingList.id} 
                                            title={getText('Ta bort')} 
                                            color={PRIMARY_THEME_COLORS.shoppingList} 
                                            onPress={() => this.showDeleteModal(shoppingList.id)}>
                                            <ListItem 
                                            onPress={() => this.itemOnPress(shoppingList.id, shoppingList.title)} 
                                            title={shoppingList.title} 
                                            action={triggerDelete ? <View style={styles.deleteButton}>
                                                <DeleteButton />
                                                </View> 
                                            : undefined}
                                            subTitle={shoppingList.creationDate} />
                                        </MfSwipeable>   
                                    </AnimatedListItem>
                                )
                        })}
                    </ScrollView>
                :
                    <View style={styles.emptyContainer}>
                        <MfText textStyle={TEXT_STYLES.SUB_HEADER}>{getText("Det finns inga inköpslistor")}</MfText>
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{getText("Skapa en ny inköpslista genom att trycka på +")}</MfText>
                    </View>
                }
                <Button style={styles.actionButton} variant={BUTTON_VARIANTS.FLOATING_ACTION} onPress={() => this.setState({showAddModal: true})} title='+' />
            </MfContainer>
        )
    }
}

const mapStateToProps = (state: IState) => {
    return {
        shoppingLists: state.shoppingListState.shoppingLists
    }
}

const mapDispatchToProps = (dispatch: Function) => {
    return {
        addShoppingList: (s: ShoppingList) => dispatch(addShoppingList(s)),
        removeShoppingList: (id: number) => dispatch(removeShoppingList(id)),
        setCurrentShoppingList: (id: number) => dispatch(setCurrentShoppingList(id))
    }
}

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(mapStateToProps, mapDispatchToProps)(ShoppingListContainer)
