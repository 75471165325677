import React from 'react'
import {Component} from 'react'
import styles from './styles'
import {View} from 'react-native'
import AnimatedOpacity from '../animated_opacity';
import AnimatedPosition from '../animated_position';
import { IS_TABLET } from '../../helpers/device';

interface IAnimatedListItem {
    onAnimationEnd: Function,
    deleteAnimation: boolean,
    delay?: number
}

class AnimatedListItem extends Component<IAnimatedListItem> {

    state = {
        opacityDuration: 500,
        opacityDelay: IS_TABLET ? 600 : 0,
        positionDuration: 400,
        positionDelay: IS_TABLET ? 600 : 0
    }

    onAnimationEnd() {
        this.props.onAnimationEnd()
    }

    render() {
        const {opacityDelay,opacityDuration,positionDelay,positionDuration} = this.state
        const {deleteAnimation} = this.props
        if (deleteAnimation) {
            return (
            <AnimatedOpacity duration={opacityDuration} delay={opacityDelay} onAnimationEnd={() => {}} reverse>
                <AnimatedPosition duration={positionDuration} delay={positionDelay} onAnimationEnd={() => this.onAnimationEnd()} toX={-500} toY={0}>
                    {this.props.children}
                </AnimatedPosition>
            </AnimatedOpacity>
            )
        } else {
            return (
                <View>
                    {this.props.children}
                </View>
            )
        }
        
    }
}

export default AnimatedListItem
