import React from 'react';
import { Platform } from 'react-native';
import { Screen } from '../../../interface';
import RecipeOverviewContainer from './recipe_overview_container';
import { NavigationScreenProps } from 'react-navigation';
import InformationButton from '../../../components/nav_buttons/information_button/';
import routes from '../../../navigation/routes';

const route = routes.recipeInstructions.name;

class RecipeOverview extends Screen {
  state = {
    showModal: false,
    navTitle:
      Platform.OS !== 'web'
        ? this.props.navigation.getParam('title')
        : this.props.webNavigation.title,
  };
  static navigationOptions = ({ navigation }: NavigationScreenProps) => {
    return {
      headerRight: (
        <InformationButton onPress={navigation.getParam('buttonOnPress')} />
      ),
      headerTitle: navigation.getParam('title'),
    };
  };

  componentDidMount() {
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        buttonOnPress: this.toogleModal,
      });
    } else {
      this.props.webNavigation.setHeaderRight(
        <InformationButton onPress={() => this.toogleModal()} />
      );
    }
  }
  toogleModal = () => {
    this.setState({ showModal: true });
  };

  onModalClose = () => {
    this.setState({ showModal: false });
  };
  navigate() {
    const title =
      Platform.OS !== 'web'
        ? this.props.navigation.getParam('title')
        : this.props.webNavigation.title;
    if (Platform.OS === 'web') {
      this.props.webNavigate(route, title);
    } else {
      this.props.navigation.navigate(route, {
        title: title,
      });
    }
  }

  render() {
    return (
      <RecipeOverviewContainer
        setTitle={() => {}}
        showModal={this.state.showModal}
        onModalClose={this.onModalClose}
        onNavigate={() => this.navigate()}
      />
    );
  }
}

export default RecipeOverview;
