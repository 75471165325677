import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    image: {
        height: 40,
        width: 40,
        marginRight: 10
    },
    modal: {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.1)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalImageContainer: {
        height: 200,
        width: 200
    },
    modalImage: {
        height: 200,
        width: 200
    },
    overLay: {
        position: 'absolute',
        top: 0,
        left: 0
    }
})