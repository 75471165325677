import {StyleSheet} from 'react-native'

export default StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    animView: {
        height: 1,
        width: 1,
        borderRadius: 50,
        backgroundColor: 'grey',
        position: 'absolute',
        zIndex: 20,
        
    }
    
})