import React, { Component } from 'react';
import WebLoader from './web_loader';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../app/config/store';
import './fonts.css';

const LoadingView = () => <div>Laddar..!</div>;

export default class WebContainer extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<LoadingView />} persistor={persistor}>
          <WebLoader />
        </PersistGate>
      </Provider>
    );
  }
}
