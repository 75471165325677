import React from 'react';
import { Component } from 'react';
const FlexSearch = require('flexsearch/flexsearch');
import { Props, State, Type, SearchResult, EType, EResultType } from './types';
import MfSearchBar from './mf_search_bar';
import { getText } from '../../helpers/text/translation';
import {
  getItemsToFlexSearchIndex,
  splitIds,
} from '../../helpers/pantrySearch';

class Search extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const index = FlexSearch.create({
      //Not sure what to put here to get it to treat åäö in a good way
      encode: 'simple',
      tokenize: 'forward',
      rtl: false,
      resolution: 1,
    });
    const exclude = this.props.excludeTypes || [];

    const itemsToAdd = getItemsToFlexSearchIndex(
      exclude,
      this.props.recipes,
      this.props.categories
    );
    itemsToAdd.forEach((item) => {
      index.add(item.id, item.id);
    });
    this.state.index = index;
  }

  state: State = {
    search: '',
    index: null,
  };

  handleSearchResults(res: Array<SearchResult>) {
    const { resultType, callback } = this.props;
    const order: Array<Type> = [
      EType.RECIPE,
      EType.TAG,
      EType.SECTION,
      EType.INGREDIENT,
    ];
    res.sort((a, b) => {
      if (order.indexOf(a.type) < order.indexOf(b.type)) return -1;
      if (order.indexOf(a.type) > order.indexOf(b.type)) return 1;
      return 0;
    });
    if (resultType == EResultType.SEARCHRESULT) {
      callback(res, this.state.search);
      return;
    }

    let recipeIds: Array<number> = [];
    let seen = new Set<number>();
    res.forEach((r) => {
      if (!seen.has(r.recipeId)) {
        recipeIds.push(r.recipeId);
        seen.add(r.recipeId);
      }
    });
    callback(recipeIds, this.state.search);
  }

  updateSearch = async (search: string) => {
    this.setState({ search });
    if (this.props.searchChangedCallback) {
      this.props.searchChangedCallback(search);
    }
    const res = await this.state.index.search(search);
    this.handleSearchResults(res.map(splitIds));
  };

  render() {
    const { search } = this.state;
    return (
      <MfSearchBar
        placeholder={this.props.placeHolder || getText('Skriv här...')}
        onChangeText={this.updateSearch}
        onCancel={this.props.onCancel}
        value={search}
      />
    );
  }
}

export default Search;
