import React from 'react';
import { Component } from 'react';
import {
  View,
  SafeAreaView,
  Image,
  ImageSourcePropType,
  Keyboard,
} from 'react-native';
import styles from './styles';
import MfModal, { animationTypes } from '../../../../components/mf_modal';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { getText } from '../../../../helpers/text/translation';
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import close from '../../../../../assets/ui/close_black.png';
import Touchable from '../../../../components/touchable';
import { RecipeCategory } from '../../../../model';
import CheckableItem from '../../../../components/checkable_item';
import KeyboardHideView from '../../../../components/keyboard_hide_view';
import { FlatList } from 'react-native-gesture-handler';
import Search from '../../../../components/search';
import {
  EResultType,
  EType,
  PantryItem,
  SearchResult,
} from '../../../../components/search/types';
import {
  getAllPantryItems,
  getPantryItems,
  itemIsSame,
} from '../../../../helpers/pantrySearch';

interface IAddToPantryModal {
  visible: boolean;
  onClose: Function;
  onAdd: (addPantryItem: { [key: number]: PantryItem }) => void;
  categories: RecipeCategory[];
  currentPantryItems: PantryItem[];
}
class AddToPantryModal extends Component<IAddToPantryModal> {
  state = {
    addedItems: this.initAddedItems(),
    searchResult: [],
    searchTerm: '',
    pantryItems: [],
  };

  componentDidUpdate(prevProps: IAddToPantryModal) {
    if (!prevProps.visible && this.props.visible) {
      if (this.props.currentPantryItems?.length > 0) {
        this.setState({
          pantryItems: getAllPantryItems(this.props.categories).filter(
            (item) =>
              !this.props.currentPantryItems.some(
                (currItem) => currItem.id === item.id
              )
          ),
        });
      } else {
        this.setState({
          pantryItems: getAllPantryItems(this.props.categories),
        });
      }
    }
  }

  initAddedItems() {
    const obj: { [key: string]: PantryItem } = {};
    return obj;
  }

  itemOnPress(pantryItem: PantryItem) {
    Keyboard.dismiss();
    const addedItems = Object.assign({}, this.state.addedItems);
    if (itemIsSame(pantryItem, addedItems[pantryItem.id])) {
      delete addedItems[pantryItem.id];
    } else {
      addedItems[pantryItem.id] = pantryItem;
    }
    this.setState({ addedItems });
  }

  onAddDone() {
    this.props.onAdd(this.state.addedItems);
    this.setState({ addedItems: this.initAddedItems() });
  }

  handleSearchResult = (searchResult: SearchResult[], searchTerm: string) => {
    const pantryItems =
      searchTerm === ''
        ? getAllPantryItems(this.props.categories)
        : getPantryItems(searchResult);
    this.setState({
      searchResult,
      pantryItems,
      searchTerm,
    });
  };

  isItemChecked(item: PantryItem): boolean {
    return Object.values(this.state.addedItems).some((addedItem) =>
      itemIsSame(addedItem, item)
    );
  }

  render() {
    return (
      <MfModal
        animationType={animationTypes.SLIDE}
        transparent={true}
        visibile={this.props.visible}
        onClose={() => {}}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.contentContainer}>
            <View style={styles.headerContainer}>
              <Touchable onPress={() => this.props.onClose()}>
                <View>
                  <Image
                    style={styles.image}
                    source={close as ImageSourcePropType}
                  />
                </View>
              </Touchable>
              <MfText textStyle={TEXT_STYLES.HEADER}>
                {getText('Lägg till en eller flera ingredienser')}
              </MfText>
              <View style={styles.headerRight}></View>
            </View>
            <View style={styles.searchContainer}>
              <Search
                categories={this.props.categories}
                onCancel={() => {}}
                placeHolder={getText('Sök')}
                callback={this.handleSearchResult}
                excludeTypes={[EType.RECIPE, EType.SECTION, EType.INGREDIENT]}
                resultType={EResultType.SEARCHRESULT}
              />
              <FlatList
                keyboardShouldPersistTaps="handled"
                extraData={this.state.addedItems}
                data={this.state.pantryItems}
                keyExtractor={(item) => `${item.id}-${item.name}`}
                renderItem={({ item }: { item: PantryItem }) => {
                  return (
                    <CheckableItem
                      style={styles.searchItem}
                      onPress={() => this.itemOnPress(item)}
                      checked={this.isItemChecked(item)}
                      customComponent={
                        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>
                          {item.name}
                        </MfText>
                      }
                    />
                  );
                }}
              />
            </View>
            <KeyboardHideView style={styles.footer}>
              <Button
                style={styles.button}
                variant={BUTTON_VARIANTS.NO_RADIUS}
                title={getText('Klar')}
                onPress={() => this.onAddDone()}
              />
            </KeyboardHideView>
          </View>
        </SafeAreaView>
      </MfModal>
    );
  }
}

export default AddToPantryModal;
