import { ICategoryAction } from "../actions"
import { Types } from '../actions/types'
import { ICategoryState } from '../../model/state'
import { persistReducer } from "redux-persist";
import { TESTING } from '../../config'
import { RecipeCategory } from "../../model";
import MFStorage from "./storage";

const initialState: ICategoryState = {
    isFetching: false,
    didFetch: false,
    dataLoaded: false,
    categories: [],
    featuredCategories: [],
    currentCategory: null,
    preLoadedImages: true
}
//WARNING: REMOVE 'categories' when deploying!
const config = {
    key: 'categoryState',
    storage: MFStorage,
    blacklist: ['isFetching', 'didFetch', 'dataLoaded', 'currentCategory']
}

// blacklist: ['isFetching', 'didFetch', 'dataLoaded', 'currentCategory']

const reducer =  (state = initialState, action: ICategoryAction): ICategoryState => {
    switch (action.type) {
        case Types.CHECK_CATEGORIES:
            return Object.assign({}, state, {dataLoaded: state.categories.length > 0 && (state.didFetch || !TESTING)})
        case Types.REQUEST_CATEGORIES:
            return Object.assign({}, state, {isFetching: true})
        case Types.RECEIVE_CATEGORIES:
            return Object.assign({}, state, {
                isFetching: false, 
                didFetch: true, 
                dataLoaded: true}, 
                {categories: action.categories},
                {featuredCategories: getFeaturedCategories(action.categories)})
        case Types.ERROR_CATEGORIES:
            return Object.assign({}, state, {error: action.error})
        case Types.SET_CURRENT_CATEGORY:
            return Object.assign({}, state, {currentCategory: getCategoryById(action.id, state.categories)})
        case Types.SET_CAT_PRELOADED_IMAGES: 
            return Object.assign({}, state, {preLoadedImages: action.didPreLoad})
        default: return state
    }
}

function getCategoryById(id: number, categories: RecipeCategory[]) {
    return categories.find(c => c.id === id)
}

function getFeaturedCategories(categories: RecipeCategory[]) {
    const fc = categories.filter(c => c.isFeatured)
    return fc.sort((a,b) => a.featuredOrder - b.featuredOrder )
}

export default persistReducer(config, reducer)