import React from 'react'
import {Component} from 'react'
import styles from './styles'
import { Platform, View, RegisteredStyle, StyleSheetProperties, ImageBackground, ImageSourcePropType, StyleProp, ViewStyle } from 'react-native';
import favorites from '../../../assets/wallpappers/favorites.jpg'
import recipe from '../../../assets/wallpappers/recipe.jpg'
import pantry from '../../../assets/wallpappers/pantry.jpg'
import shopping from '../../../assets/wallpappers/shopping.jpg'
import favorites_tablet from '../../../assets/wallpappers/favorites_tablet.jpg'
import recipe_tablet from '../../../assets/wallpappers/recipe_tablet.jpg'
import pantry_tablet from '../../../assets/wallpappers/pantry_tablet.jpg'
import shopping_tablet from '../../../assets/wallpappers/shopping_tablet.jpg'
import { IS_TABLET } from '../../helpers/device';


export enum backgrounds {
    RECIPE = 'RECIPE',
    FAVORITES = 'FAVORITES',
    SHOPPING_LIST = 'SHOPPING_LIST',
    PANTRY = 'PANTRY'
}

interface Props {
    // style?: RegisteredStyle<any>
    style?: StyleProp<ViewStyle>,
    backgroundImage?: backgrounds,
    noMargin?: boolean
}

class MfContainer extends Component<Props> {
    getImage() {
        switch (this.props.backgroundImage) {
            case backgrounds.RECIPE:
                return IS_TABLET ? recipe_tablet : recipe
            case backgrounds.FAVORITES:
                return IS_TABLET ? favorites_tablet : favorites
            case backgrounds.SHOPPING_LIST:
                return IS_TABLET ? shopping_tablet : shopping
            case backgrounds.PANTRY:
                return IS_TABLET ? pantry_tablet : pantry
            default:
                return recipe
        }
    }
    render() {
        const {noMargin} = this.props
        
        const style = Platform.OS == "web" ? [styles.containerWeb, noMargin && styles.noMargin] 
        : [styles.container, noMargin && styles.noMargin]

        if (this.props.backgroundImage) {
            return (
                <ImageBackground source={this.getImage() as ImageSourcePropType} style={[style, this.props.style]}>
                    {this.props.children}
                </ImageBackground>
            )
        }
        return (
            <View style={[style, this.props.style]}>
                {this.props.children}
            </View>
        )
    }
}

export default MfContainer
