import { IShoppingListState } from "../../model/state";
import {IShoppingListAction} from '../actions'
import { Types } from "../actions/types";
import { persistReducer } from "redux-persist";
import { ShoppingList, RawShoppingList, ShoppingIngredient } from "../../model";
import { getDate } from "../../helpers/date";
import MFStorage from "./storage";

const initalState: IShoppingListState = {
    shoppingLists: [],
    lastId: 0,
    currentShoppingList: null
}

const config = {
    key: 'shoppingListState',
    storage: MFStorage,
    blacklist: ['currenShoppingList']
}

const reducer = (state = initalState, action: IShoppingListAction): IShoppingListState => {
    switch (action.type) {
        case Types.ADD_SHOPPING_LIST:
            return addShoppingList(state, action.shoppingList, state.shoppingLists)
        case Types.REMOVE_SHOPPING_LIST:
            return {
                ...state,
                shoppingLists: removeShoppingList(action.id, state.shoppingLists)
            }
        case Types.SET_CURRENT_SHOPPING_LIST:
            return {
                ...state,
                currentShoppingList: getShoppingListById(action.id, state.shoppingLists)
            }
        case Types.ADD_TO_SHOPPING_LIST:
            return {
                ...state,
                shoppingLists: addToShoppingList(action.id, action.ingredients, state.shoppingLists)
            }
        case Types.UPADTE_SHOPPING_LIST:
            return {
                ...state,
                shoppingLists: updateShoppingList(action.shoppingList, state.shoppingLists),
                currentShoppingList: action.shoppingList
            }
        default: return state
    }
}

function addShoppingList(state: IShoppingListState, shoppingList: RawShoppingList, shoppingLists: ShoppingList[]) {
    let id = state.lastId + 1
    const arr = Array.from(shoppingLists)
    const exists = arr.find(a => a.id === id)
    if (exists && exists.id === id) {
        arr.sort((a,b) => a.id - b.id)
        id = arr[arr.length-1].id + 1
    }
    const newShoppingList : ShoppingList = {
        id: id,
        title: shoppingList.title,
        creationDate: getDate(),
        ingredients: addMultipleShoppingItemIds(shoppingList.ingredients)
    }
    arr.push(newShoppingList)
    return {
        ...state,
        shoppingLists: arr,
        lastId: id
    }
}

function addToShoppingList(id: number, ingredients: ShoppingIngredient[], shoppingLists: ShoppingList[]) {
    const arr = Array.from(shoppingLists)
    let shoppingList = arr.find(a => a.id === id) as ShoppingList
    if (shoppingList) {
        ingredients.forEach(i => {
            const exists = shoppingList.ingredients.find(si => si.id === i.id)
            if(exists) {
                exists.quantityShop = exists.quantityShop + i.quantityShop
            } else {
                i.shoppingItemId = getNewShoppingItemId(shoppingList.ingredients)
                shoppingList.ingredients.push(i)
            }
        })
    }
    return arr

}

function addMultipleShoppingItemIds(ingredients: ShoppingIngredient[]) {
    const newIngredients = Array.from(ingredients)
    ingredients.forEach(ing => {
        ing.shoppingItemId = getNewShoppingItemId(newIngredients)
    })

    return ingredients
}

function getNewShoppingItemId(ingredients: ShoppingIngredient[]) {
    let maxId = 0
    ingredients.map(ing => {
        if (ing.shoppingItemId > maxId) maxId = ing.shoppingItemId
    })
    return maxId+1
}

function updateShoppingList(s: ShoppingList, shoppingLists: ShoppingList[]) {
    const arr = Array.from(shoppingLists)
    let updateList = arr.find(a => a.id === s.id) as ShoppingList
    updateList.title = s.title,
    updateList.ingredients = s.ingredients
    return arr
}


function getShoppingListById(id: number, shoppingLists: ShoppingList[]) {
    return shoppingLists.find(s => s.id === id) || null
}

function removeShoppingList(id: number, shoppingLists: ShoppingList[]) {
    const arr = Array.from(shoppingLists).filter(s => s.id !== id)
    return arr
}

export default persistReducer(config, reducer)