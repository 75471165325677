import React from 'react';
import { Component } from 'react';
import styles from './styles';
import { View, ScrollView } from 'react-native';
import Heart from '../../../../../assets/ui/heart_filled.png';
import HeartEmpty from '../../../../../assets/ui/heart.png';
import Shopping from '../../../../../assets/mf_ui/handla-ikon-cc4132.png';
import {
  IState,
  IAlteredSections,
  IngredientsById,
} from '../../../../model/state';
import {
  Recipe,
  ShoppingList,
  ShoppingIngredient,
  RawShoppingList,
  Nutrition,
} from '../../../../model';
import { IContainer } from '../../../../interface';
import { connect } from 'react-redux';
import CheckButton from '../../../../components/check_button';
import {
  setRecipeAmount,
  setAlteredSections,
  setCurrentSectionsDone,
  setCurrentIngredients,
  setCurrentAlteredIngredients,
  updateCurrentRecipeData,
} from '../../../../redux/actions/recipes';
import AdjustModal from './adjust_modal';
import { IAlternatives } from './adjust_modal/adjust_modal';
import InfoModal from './info_modal';
import {
  addToFavorites,
  removeFromFavorites,
  addShoppingList,
  addToShoppingList,
} from '../../../../redux/actions';
import AddShoppingListModal from './add_shopping_list_modal';
import { calculateNutritions } from '../../../../helpers/nutritions_handlers';
import NutritionModal from './nutrition_modal/';
import FavoriteAnimation from '../../../../components/favorite_animation';
import AnimatedScroll from './animated_scroll';
import { IS_TABLET } from '../../../../helpers/device';
import QuantityHandler from '../../../../helpers/quantity_handler';
import { IS_WEB } from '../../../../config/style/web_helper';
import { CheckedSections } from '../../recipe_instructions/recipe_instructions_container/recipe_instructions_container';
import {
  getIngredients,
  getPortionsText,
  getSortedIngredients,
} from '../../../../helpers/recipeOverview';
import ButtonsContainer from '../buttons_container';
import Description from '../description';
import { IngredientKeys } from '../../../../helpers/constants';
import RecipeIngredientList from '../../../../components/recipe_ingredient_list';

interface ReduxProps {
  currentRecipe: Recipe;
  currentAlteredSections: IAlteredSections;
  favorites: number[];
  shoppingLists: ShoppingList[];
  nutritions: Nutrition[];
  currentAmount: number;
  ingredients: IngredientsById;
  alteredIngredients: number[];
}
interface DispatchProps {
  setAmount: Function;
  setSectionsDone: Function;
  setAlteredSections: Function;
  addToFavorites: Function;
  addShoppingList: (key: RawShoppingList) => void;
  addToShoppingList: (id: number, s: ShoppingIngredient[]) => void;
  removeFromFavorites: Function;
  setCurrentIngredients: (ingredients: IngredientsById) => void;
  setCurrentAlteredIngredients: (alteredIngredients: number[]) => void;
  updateCurrentRecipe: (recipe: Recipe) => void;
}
export interface ReactProps extends IContainer {
  onModalClose: Function;
  showModal: boolean;
}
type Props = ReduxProps & ReactProps & DispatchProps;
interface State {
  scrollPosition: number;
  showAdjustModal: boolean;
  showShoppingModal: boolean;
  alteredSections: IAlternatives;
  showNutritionModal: boolean;
  showLongDescription: boolean;
  initialIngredients: IngredientsById;
}

class RecipeOverviewContainer extends Component<Props, State> {
  constructor(p: Props) {
    super(p);

    const { ingredients, alteredIngredients } = getIngredients(
      p.currentRecipe.sections,
      p.currentAlteredSections
    );

    p.setCurrentIngredients(ingredients);
    p.setCurrentAlteredIngredients(alteredIngredients);

    this.state = {
      scrollPosition: 0,
      showAdjustModal: false,
      showShoppingModal: false,
      alteredSections: this.findAlteredSections(),
      showNutritionModal: false,
      showLongDescription: false,
      initialIngredients: ingredients,
    };
  }
  async componentDidMount() {
    const { currentRecipe, setAmount } = this.props;
    await setAmount(currentRecipe.minServings);
    this.changePortions(currentRecipe.minServings);
  }

  componentWillUnmount() {
    this.props.setSectionsDone({});
  }

  getNutritions(nuts: Nutrition[], ings: IngredientsById) {
    return calculateNutritions(
      ings,
      nuts,
      this.props.currentRecipe.minServings
    );
  }

  async changePortions(amount: number) {
    const isIncreasing = amount > this.props.currentAmount;
    const ingredients = Object.keys(this.props.ingredients).reduce(
      (acc, key) => {
        const ingredient = this.props.ingredients[key];
        const newValues = QuantityHandler.getQuantityAndMeasurement(
          ingredient.measure,
          (ingredient.quantity / this.props.currentAmount) * amount,
          isIncreasing,
          this.state.initialIngredients[key]
        );
        return {
          ...acc,
          [key]: {
            ...ingredient,
            ...newValues,
            [IngredientKeys.MEASURE_SHOP]:
              newValues[IngredientKeys.QUANTITY] > 1
                ? ingredient.measurePluralShop
                : ingredient.measureShop,
          },
        };
      },
      {}
    );

    const newCurrentRecipeSections = this.props.currentRecipe.sections.map(
      (section) => {
        const steps = section.steps.map((step) => {
          const ingredients = step.ingredients.map((ingredient) => {
            const quantity = ingredient.useAll
              ? ingredient.quantityAll
              : ingredient.quantityUse;
            const {
              quantity: newQantity,
              measure,
              measurePlural,
            } = QuantityHandler.getQuantityAndMeasurement(
              ingredient.measure,
              (quantity / this.props.currentAmount) * amount,
              isIncreasing,
              this.state.initialIngredients[ingredient.id]
            );

            return {
              ...ingredient,
              measure: measure ?? ingredient.measure,
              measurePlural: measurePlural ?? ingredient.measurePlural,
              [ingredient.useAll ? 'quantityAll' : 'quantityUse']: newQantity,
            };
          });

          return {
            ...step,
            ingredients,
          };
        });
        return {
          ...section,
          steps,
        };
      }
    );

    await this.props.setAmount(amount);
    this.props.setCurrentIngredients(ingredients);

    this.props.updateCurrentRecipe({
      ...this.props.currentRecipe,
      sections: newCurrentRecipeSections,
    });
  }

  findAlteredSections() {
    const { sections } = this.props.currentRecipe;
    const { currentAlteredSections } = this.props;
    const altered: IAlternatives = {};
    sections.forEach((section) => {
      if (section.isAlternative) {
        const currentChoice =
          section.altId in currentAlteredSections
            ? currentAlteredSections[section.altId]
            : section.altId;
        if (section.altId in altered) {
          altered[section.altId].alternatives.push(section);
          altered[section.altId].alteredSection = currentChoice;
        } else {
          altered[section.altId] = {
            title: section.altTitle,
            alteredSection: currentChoice,
            alternatives: [section],
          };
        }
      }
    });
    return altered;
  }

  toggleAdjustModal() {
    this.setState((prevState) => ({
      showAdjustModal: !prevState.showAdjustModal,
    }));
  }

  async variationChange(id: number, alt: number) {
    const cas = { ...this.props.currentAlteredSections };
    const del = id === alt;
    if (del) {
      delete cas[id];
    } else {
      cas[id] = alt;
    }
    await this.props.setAlteredSections(cas);

    const { ingredients, alteredIngredients } = getIngredients(
      this.props.currentRecipe.sections,
      this.props.currentAlteredSections
    );

    const { currentAmount } = this.props;
    Object.keys(ingredients).forEach((obj) => {
      ingredients[obj].quantity =
        ingredients[obj].quantity * (currentAmount / 2);
    });

    await this.props.setCurrentIngredients(ingredients);
    await this.props.setCurrentAlteredIngredients(alteredIngredients);

    this.setState(
      {
        alteredSections: this.findAlteredSections(),
      },
      () => this.props.setSectionsDone({})
    );
  }

  addRecipeToFavorites() {
    this.props.addToFavorites(this.props.currentRecipe.id);
  }
  removeFromFavorites() {
    this.props.removeFromFavorites(this.props.currentRecipe.id);
  }
  recipeIsFavorite() {
    const { favorites, currentRecipe } = this.props;
    return favorites.indexOf(currentRecipe.id) > -1;
  }
  addNewShoppingList(title: string) {
    this.props.addShoppingList({
      title: title,
      ingredients: this.getShoppingIngredients(),
    });
  }

  addIngredientsToShoppingList(id: number) {
    const { shoppingLists } = this.props;
    const s = shoppingLists.find((sl) => sl.id === id);
    if (s) {
      this.props.addToShoppingList(id, this.getShoppingIngredients());
    } else {
      console.log('didnt add to shopping list');
    }
  }
  // WARNING: Ändra quantityShop om det behövs i framtiden.
  getShoppingIngredients() {
    const { ingredients } = this.props;
    const { currentRecipe, currentAmount } = this.props;
    const shoppingIngredients: ShoppingIngredient[] = Object.values(
      ingredients
    ).map((ingredient) => {
      return {
        ...(ingredient as ShoppingIngredient),
        quantityShop:
          (ingredient.quantityShop / currentRecipe.minServings) * currentAmount,
      };
    });
    return shoppingIngredients;
  }

  renderDescriptionText() {
    const { shortDescription, longDescription } = this.props.currentRecipe;
    const { showLongDescription } = this.state;
    return (
      <Description
        shortDesc={shortDescription}
        longDesc={longDescription}
        showLongDesc={showLongDescription}
        onShowMorePress={() =>
          this.setState({
            showLongDescription: !showLongDescription,
          })
        }
      />
    );
  }

  renderPortionOptions() {
    const { minServings, maxServings } = this.props.currentRecipe;
    return (
      <View style={styles.portionContainer}>
        <View style={styles.portionButtonContainer}>
          <CheckButton
            checked={
              this.props.currentAmount === this.props.currentRecipe.minServings
            }
            title={getPortionsText(true, this.props.currentRecipe)}
            onPress={() => this.changePortions(minServings)}
          />
          <CheckButton
            checked={
              this.props.currentAmount !== this.props.currentRecipe.minServings
            }
            title={getPortionsText(false, this.props.currentRecipe)}
            onPress={() => this.changePortions(maxServings)}
          />
        </View>
      </View>
    );
  }

  renderButtons() {
    return (
      <ButtonsContainer
        hasAlteredSections={
          Object.entries(this.state.alteredSections).length > 0
        }
        onLeftButtonPress={() => this.toggleAdjustModal()}
        onRightButtonPress={() => this.props.onNavigate()}
      />
    );
  }

  onShowNutritions() {
    this.props.onModalClose();
    this.setState({ showNutritionModal: true });
  }

  getActions() {
    const isFavorite = this.recipeIsFavorite();
    return [
      {
        icon: isFavorite ? { uri: Heart } : { uri: HeartEmpty },
        onPress: isFavorite
          ? () => this.removeFromFavorites()
          : () => this.addRecipeToFavorites(),
        component: (
          <FavoriteAnimation
            onAdd={() => this.addRecipeToFavorites()}
            onRemove={() => this.removeFromFavorites()}
            isFavorite={isFavorite}
          />
        ),
      },
      {
        icon: {
          uri: Shopping,
        },
        onPress: () => this.setState({ showShoppingModal: true }),
      },
    ];
  }

  render() {
    const { currentRecipe } = this.props;
    const actions = this.getActions();
    return (
      <View style={IS_WEB ? styles.containerWeb : styles.container}>
        <AddShoppingListModal
          onClose={() => this.setState({ showShoppingModal: false })}
          onCreate={(title: string) => this.addNewShoppingList(title)}
          onAdd={(id: number) => this.addIngredientsToShoppingList(id)}
          shoppingLists={this.props.shoppingLists}
          visible={this.state.showShoppingModal}
        />
        <NutritionModal
          nutritions={this.getNutritions(
            this.props.nutritions,
            this.props.ingredients
          )}
          measurement={currentRecipe.servingsLabelSingular}
          visible={this.state.showNutritionModal}
          onClose={() => this.setState({ showNutritionModal: false })}
        />
        <InfoModal
          tips={currentRecipe.tips}
          onShowNutritions={() => this.onShowNutritions()}
          visible={this.props.showModal}
          onClose={() => this.props.onModalClose()}
        />
        <AdjustModal
          visible={this.state.showAdjustModal}
          onClose={() => this.toggleAdjustModal()}
          itemOnPress={(id: number, alt: number) =>
            this.variationChange(id, alt)
          }
          buttonOnPress={() => this.toggleAdjustModal()}
          sections={this.state.alteredSections}
        />
        {IS_TABLET ? (
          <View style={styles.tabletContainer}>
            <View style={styles.tabletLeft}>
              <AnimatedScroll
                actions={actions}
                image={{ uri: currentRecipe.largeImage }}
                disableScroll={true}
                tablet={true}
              >
                {this.renderDescriptionText()}
                {IS_WEB && <View style={{ marginBottom: 100 }} />}
              </AnimatedScroll>
              {this.renderButtons()}
            </View>
            <View style={styles.tabletRight}>
              <ScrollView>
                {this.renderPortionOptions()}
                <RecipeIngredientList
                  recipe={currentRecipe}
                  amount={this.props.currentAmount}
                  showAlteredItem={!this.state.showAdjustModal}
                  ingredients={getSortedIngredients(this.props.ingredients)}
                  alteredIngredients={this.props.alteredIngredients}
                />
              </ScrollView>
            </View>
          </View>
        ) : (
          <View style={!IS_WEB && styles.container}>
            <AnimatedScroll
              actions={actions}
              image={{ uri: currentRecipe.largeImage }}
              tablet={false}
            >
              <View>
                {this.renderDescriptionText()}
                {this.renderPortionOptions()}
                <RecipeIngredientList
                  recipe={currentRecipe}
                  amount={this.props.currentAmount}
                  showAlteredItem={!this.state.showAdjustModal}
                  ingredients={getSortedIngredients(this.props.ingredients)}
                  alteredIngredients={this.props.alteredIngredients}
                />
              </View>
            </AnimatedScroll>
            {this.renderButtons()}
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    currentRecipe: state.recipeState.currentRecipe,
    currentAmount: state.recipeState.currentAmount,
    favorites: state.favoriteState.favorites,
    currentAlteredSections: state.recipeState.currentAlteredSections,
    shoppingLists: state.shoppingListState.shoppingLists,
    nutritions: state.nutritionState.nutritions,
    ingredients: state.recipeState.currentIngredients,
    alteredIngredients: state.recipeState.currentAlteredIngredients,
  } as ReduxProps;
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setAmount: (amount: number) => dispatch(setRecipeAmount(amount)),
    setAlteredSections: (sections: IAlteredSections) =>
      dispatch(setAlteredSections(sections)),
    addToFavorites: (id: number) => dispatch(addToFavorites(id)),
    removeFromFavorites: (id: number) => dispatch(removeFromFavorites(id)),
    addShoppingList: (s: RawShoppingList) => dispatch(addShoppingList(s)),
    addToShoppingList: (id: number, s: ShoppingIngredient[]) =>
      dispatch(addToShoppingList(id, s)),
    setSectionsDone: (b: CheckedSections) =>
      dispatch(setCurrentSectionsDone(b)),
    setCurrentIngredients: (ingredients: IngredientsById) =>
      dispatch(setCurrentIngredients(ingredients)),
    setCurrentAlteredIngredients: (alteredIngredients: number[]) =>
      dispatch(setCurrentAlteredIngredients(alteredIngredients)),
    updateCurrentRecipe: (recipe: Recipe) =>
      dispatch(updateCurrentRecipeData(recipe)),
  };
};

export default connect<ReduxProps, DispatchProps, ReactProps, IState>(
  mapStateToProps,
  mapDispatchToProps
)(RecipeOverviewContainer);
