import React from 'react';
import { Screen } from '../../../interface';
import { Platform } from 'react-native';
import RecipeInstructionsContainer from './recipe_instructions_container';
import InformationButton from '../../../components/nav_buttons/information_button';
import { NavigationScreenProps } from 'react-navigation';
import routes from '../../../navigation/routes';

class RecipeInstructions extends Screen {
  state = {
    showModal: false,
  };
  static navigationOptions = ({
    navigation,
  }: NavigationScreenProps) => {
    return {
      headerRight: (
        <InformationButton
          onPress={navigation.getParam('buttonOnPressTwo')}
        />
      ),
      headerTitle: navigation.getParam('title'),
    };
  };

  componentDidMount() {
    super.componentDidMount();
    if (Platform.OS !== 'web') {
      this.props.navigation.setParams({
        buttonOnPressTwo: () => this.toogleModal(),
      });
    } else {
      this.props.webNavigation.setHeaderRight(
        <InformationButton
          onPress={() => this.toogleModal()}
        />
      );
    }
  }

  toogleModal = () => {
    this.setState({ showModal: true });
  };

  onModalClose = () => {
    this.setState({ showModal: false });
  };

  navigate() {
    if (Platform.OS !== 'web') {
      this.props.navigation.popToTop();
    } else {
      this.props.webNavigate(routes.start.name, 'Matfröjd');
    }
  }

  render() {
    return (
      <RecipeInstructionsContainer
        showModal={this.state.showModal}
        onModalClose={this.onModalClose}
        setTitle={() => {}}
        onNavigate={() => this.navigate()}
      />
    );
  }
}

export default RecipeInstructions;
