import {StyleSheet} from 'react-native'
import { TEXT_COLORS } from '../../config/style';

export default StyleSheet.create({
    container: {
        minHeight: 60,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: 'grey',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    textContainer: {
        paddingLeft: 10,
        justifyContent: 'center'
    },
    actionContainer: {
        paddingRight: 10
    },
    title: {
        fontFamily: 'clan-pro-narr-book',
        fontSize: 23,
        color: TEXT_COLORS.normal,
    }
    
})