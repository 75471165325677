import React from 'react';
import { Component } from 'react';
import {
  View,
  SafeAreaView,
  ScrollView,
  Image,
  ImageSourcePropType,
  ImageBackground,
  FlatList,
} from 'react-native';
import styles from './styles';
import MfModal, { animationTypes } from '../../../../components/mf_modal';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { getText } from '../../../../helpers/text/translation';
import close from '../../../../../assets/ui/close.png';
import Touchable from '../../../../components/touchable';
import Card from '../../../../components/card';
import { FilterResult } from '../pantry_container';
import wallTablet from '../../../../../assets/wallpappers/pantry_tablet.jpg';
import wallPhone from '../../../../../assets/wallpappers/pantry.jpg';
import { IS_TABLET } from '../../../../helpers/device';
import CardSeparator from '../../../../components/card_separator';
import { Recipe } from '../../../../model';
import { getNumOfColumns } from '../../../../helpers/flatListCardHelper';

interface IFindRecipeModal {
  visible: boolean;
  onClose: () => void;
  filterResult: FilterResult;
  onSelected: (id: number, name: string) => void;
}
class FindRecipeModal extends Component<IFindRecipeModal> {
  onClose() {
    this.props.onClose();
  }

  cardPressed = async (id: number, name: string) => {
    this.props.onSelected(id, name);
  };

  render() {
    const { visible, filterResult } = this.props;
    const { recipes } = filterResult;
    const wall = IS_TABLET ? wallTablet : wallPhone;
    return (
      <MfModal
        animationType={animationTypes.SLIDE}
        transparent={true}
        visibile={visible}
        onClose={() => {}}
      >
        <SafeAreaView style={styles.container}>
          <ImageBackground
            style={styles.contentContainer}
            source={wall as ImageSourcePropType}
          >
            <View style={styles.headerContainer}>
              <Touchable onPress={() => this.onClose()}>
                <View>
                  <Image
                    style={styles.image}
                    source={close as ImageSourcePropType}
                  />
                </View>
              </Touchable>
              <MfText textStyle={TEXT_STYLES.HEADER} style={{ color: 'white' }}>
                {getText('Recept')}
              </MfText>
              <View style={styles.headerRight}></View>
            </View>
            <FlatList
              contentContainerStyle={styles.containerFlatList}
              style={styles.flatList}
              data={recipes}
              keyExtractor={(item) => item.id.toString()}
              numColumns={getNumOfColumns()}
              renderItem={({ item }: { item: Recipe }) => (
                <Card
                  title={item.name}
                  key={item.id}
                  image={{ uri: item.smallImage }}
                  onPress={() => this.cardPressed(item.id, item.name)}
                />
              )}
              ItemSeparatorComponent={CardSeparator}
              ListEmptyComponent={() => (
                <View style={styles.emptyResult}>
                  <MfText textStyle={TEXT_STYLES.SUB_HEADER}>
                    {getText('Inga recept hittades')}
                  </MfText>
                </View>
              )}
            />
          </ImageBackground>
        </SafeAreaView>
      </MfModal>
    );
  }
}

export default FindRecipeModal;
