import React, { useState, useEffect } from 'react'
import {View, ScrollView} from 'react-native'
import Button, { BUTTON_VARIANTS } from '../../../components/button/button'
import { getText } from '../../../helpers/text/translation'
import DownloadContainer from '../../../components/download_container'
import styles from './styles'
import MfContainer from '../../../components/mf_container'
import { backgrounds } from '../../../components/mf_container/mf_container'
import CollapsibleCard from '../../../components/collapsible_card'
import { PRIMARY_THEME_COLORS } from '../../../config/style'
import MfText, { TEXT_STYLES } from '../../../components/mf_text'
import { shouldFetch, fetchData } from '../../../helpers/version_handler'

interface Collapsed {
    [key:string]: boolean
}

const SettingsContainer = () => {

    const [showDownload, setShowDownload] = useState(false)
    const [hasUpdate, setHasUpdate] = useState(false)

    useEffect(() => {
        const sf = async () => setHasUpdate(await shouldFetch())
        sf()
    }, [])

    const [collapsed, setCollapsed] = useState<Collapsed>({
        offline: true,
        update: true
    })

    const collapse = (key: string) => {
        if (key in collapsed && collapsed[key]) {
            setCollapsed(Object.assign({}, collapsed, collapsed[key] = false))
        } else {
            setCollapsed(Object.assign({}, collapsed, collapsed[key] = true))
        }
    }

    const onOfflinePress = () => {
        setShowDownload(true)
    }

    const onOfflineClose = () => {
        setShowDownload(false)
    }

    const onUpdatePress = async () => {
        //TODO Update container
        await fetchData()
    }

    const onUpdateClose = () => {

    }
    return (
        <MfContainer backgroundImage={backgrounds.RECIPE}>
            <ScrollView contentContainerStyle={styles.container}>
                <CollapsibleCard 
                collapsed={collapsed['offline']} 
                onHeaderPress={() => collapse('offline')} 
                title={getText("Offlineläge")}
                headerColor={PRIMARY_THEME_COLORS.tips} 
                customComponent={
                    <View style={styles.offlineContainer}>
                        <MfText style={styles.offlineText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                            {getText("Om du vill kunna använda appen utan att ha tillgång till internet kan du ladda ner alla recept och bilder till din telefon. Se till att du har tillgång till internet och klicka på Ladda ner")}
                        </MfText>
                        <View style={styles.buttonContainer}>
                            <Button 
                            style={styles.downloadButton} 
                            title={getText("Ladda ner")} 
                            onPress={onOfflinePress} />
                        </View>
                    </View>
                }
                />
                <CollapsibleCard 
                collapsed={collapsed['update']} 
                onHeaderPress={() => collapse('update')} 
                title={getText("Uppdatera recept")}
                headerColor={PRIMARY_THEME_COLORS.tips} 
                customComponent={
                    <View style={styles.offlineContainer}>
                        <MfText style={styles.offlineText} textStyle={TEXT_STYLES.PLAIN_TEXT}>
                            {hasUpdate ? getText("Det finns en uppdatering") : getText("Ingen nyare version hittades")}
                        </MfText>
                        {hasUpdate &&
                        <View style={styles.buttonContainer}>
                            <Button 
                            style={styles.downloadButton} 
                            title={getText("Uppdatera")} 
                            onPress={onUpdatePress} />
                        </View>
                        }
                    </View>
                }
                />
            </ScrollView>
            <DownloadContainer 
            visible={showDownload}
            onClose={onOfflineClose}
            onDeny={onOfflineClose}
            onDownloadSuccess={() => {}} />
        </MfContainer>
    )
}

export default SettingsContainer
