import { StyleSheet } from "react-native";
import { PRIMARY_THEME_COLORS } from "../../../config/style";

export default StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        flexDirection: 'row',
        paddingBottom: 20
   },
    downloadButton: {
        // backgroundColor: PRIMARY_THEME_COLORS.green,
    },
    offlineText: {
        minWidth: '70%',
        marginBottom: 10
    },
    offlineContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: 10,
        flex: 1
    },
    buttonContainer: {
        marginBottom: 10,
        marginTop: 10,
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row'
    }
})