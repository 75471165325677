import { Action, ActionCreator, Dispatch } from 'redux';
import { PantryItem } from '../../components/search/types';
import { Types } from './types';

export interface IAddAction extends Action {
  type: Types.ADD_TO_PANTRY;
  pantryItems: PantryItem[];
}

export interface IRemoveAction extends Action {
  type: Types.REMOVE_FROM_PANTRY;
  id: number;
}

export interface IClearAction extends Action {
  type: Types.CLEAR_PANTRY;
}

export type IPantryAction = IAddAction | IRemoveAction | IClearAction;

const addItemsToPantry: ActionCreator<IAddAction> = (
  pantryItems: PantryItem[]
) => {
  return {
    type: Types.ADD_TO_PANTRY,
    pantryItems,
  };
};

export function addToPantry(pantryItems: PantryItem[]) {
  return async (dispatch: Dispatch) => {
    dispatch(addItemsToPantry(pantryItems));
  };
}

const removeIngredientFromPantry: ActionCreator<IRemoveAction> = (
  id: number
) => {
  return {
    type: Types.REMOVE_FROM_PANTRY,
    id: id,
  };
};

export const clearPantry: ActionCreator<IClearAction> = () => {
  return {
    type: Types.CLEAR_PANTRY,
  };
};

export function removeFromPantry(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(removeIngredientFromPantry(id));
  };
}
