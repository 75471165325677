import { ShoppingIngredient } from '../model';

const shoppingListDetailCatOrder: { [key: number]: number } = {
  12: 0, // Frukt, bär och fruktkonserver
  11: 1, // Grönsaker, rotfrukter och grönsakskonserver
  10: 2, // Potatis, Ris, pasta, gryn och spannmål
  21: 3, // Bönor, linser och vegetariska alternativ
  14: 4, // Nötter och frön
  7: 5, // Bröd, mjöl och socker
  15: 6, // Kött och korv
  5: 7, // Fisk och skaldjur
  16: 8, // Fågel och ägg
  6: 9, // Mejerivaror
  9: 10, // Matfett
  31: 11, // Olja
  32: 12, // Sås, dressing, majonnäs
  8: 13, // Kryddor och smaksättningar
  25: 14, // Sylt, marmelad, gelé
  22: 15, // Drycker
  19: 16, // Övrigt
  18: 17, // Vätska
  28: 18, // Glass
};

const sortShoppingIngByCat = (ingredients: ShoppingIngredient[]) => {
  const sortedIngredients = [...ingredients];
  sortedIngredients.sort((a, b) => {
    if (
      shoppingListDetailCatOrder[a.categoryId] >
      shoppingListDetailCatOrder[b.categoryId]
    )
      return 1;
    if (
      shoppingListDetailCatOrder[a.categoryId] <
      shoppingListDetailCatOrder[b.categoryId]
    )
      return -1;
    return 0;
  });
  return sortedIngredients;
};

export const sortShoppingListDetail = (ingredients: ShoppingIngredient[]) => {
  const cats: { [key: string]: ShoppingIngredient[] } = {};
  const sortedIngredients = sortShoppingIngByCat(ingredients);
  sortedIngredients.forEach((ingredient) => {
    if (ingredient.category in cats) {
      cats[ingredient.category].push(ingredient);
    } else {
      cats[ingredient.category] = [ingredient];
    }
  });
  return Object.keys(cats).map((cat) => {
    return {
      title: cat,
      ingredients: cats[cat].sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      }),
    };
  });
};
