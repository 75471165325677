/* eslint-disable react/prop-types */
import React from 'react';
import { Platform, View } from 'react-native';
import Button, { BUTTON_VARIANTS } from '../../../../components/button/button';
import { IS_TABLET } from '../../../../helpers/device';
import { getText } from '../../../../helpers/text/translation';
import styles from './styles';

interface IButtonsContainer {
  hasAlteredSections: boolean;
  onLeftButtonPress: () => void;
  onRightButtonPress: () => void;
}

const ButtonsContainer: React.FC<IButtonsContainer> = ({
  hasAlteredSections,
  onRightButtonPress,
  onLeftButtonPress,
}) => {
  const isWeb = Platform.OS === 'web';
  const leftStyle = IS_TABLET
    ? [styles.buttonLeft, styles.buttonLeftTablet]
    : styles.buttonLeft;
  const radiusLeft = IS_TABLET && styles.buttonLeftTablet;
  const radiusRight = IS_TABLET && [
    styles.buttonRightTablet,
    !hasAlteredSections && styles.singleButtonRightTablet,
  ];
  const rightStyle = IS_TABLET
    ? [
        styles.buttonRight,
        hasAlteredSections
          ? styles.buttonRightTablet
          : styles.singleButtonRightTablet,
      ]
    : hasAlteredSections
    ? styles.buttonRight
    : styles.singleButtonRight;
  const container = IS_TABLET
    ? [styles.buttonContainer, styles.buttonContainerTablet]
    : styles.buttonContainer;
  const webContainer = IS_TABLET
    ? [{ marginBottom: 10 }, styles.buttonContainerWeb]
    : styles.buttonContainerWeb;
  return (
    <View style={isWeb ? webContainer : container}>
      {hasAlteredSections ? (
        <Button
          variant={BUTTON_VARIANTS.NO_RADIUS}
          style={[leftStyle, isWeb && styles.buttonWidthWeb]}
          borderRadiusStyle={radiusLeft}
          title={getText('Alternativ')}
          onPress={onLeftButtonPress}
        />
      ) : null}
      <Button
        variant={BUTTON_VARIANTS.NO_RADIUS}
        style={[rightStyle, isWeb && styles.buttonWidthWeb]}
        borderRadiusStyle={radiusRight}
        title={getText('Laga mat')}
        onPress={onRightButtonPress}
      />
    </View>
  );
};

export default ButtonsContainer;
