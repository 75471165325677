exports = module.exports = require("../../node_modules/@expo/webpack-config/node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/@expo/webpack-config/node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../assets/fonts/ClanPro-Bold.otf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../assets/fonts/ClanPro-Book.otf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../assets/fonts/ClanPro-Medium.otf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../assets/fonts/ClanPro-NarrBlack.otf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../assets/fonts/ClanPro-NarrBold.otf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../assets/fonts/ClanPro-NarrBook.otf"));

// Module
exports.push([module.id, "@font-face {\n    font-family: clan-pro-bold;\n    src: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n@font-face {\n    font-family: clan-pro-book;\n    src: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n@font-face {\n    font-family: clan-pro-medium;\n    src: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n@font-face {\n    font-family: clan-pro-narr-black;\n    src: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n@font-face {\n    font-family: clan-pro-narr-bold;\n    src: url(" + ___CSS_LOADER_URL___4___ + ");\n}\n@font-face {\n    font-family: clan-pro-narr-book;\n    src: url(" + ___CSS_LOADER_URL___5___ + ");\n}", ""]);

