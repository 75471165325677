/* eslint-disable react/prop-types */
import React from 'react';
import { View } from 'react-native';
import styles from './styles';
import MfCollapsible from '../../../../components/mf_collapsible';
import MfText, { TEXT_STYLES } from '../../../../components/mf_text';
import { IS_TABLET } from '../../../../helpers/device';
import { getText } from '../../../../helpers/text/translation';
import Touchable from '../../../../components/touchable';

interface IDescription {
  longDesc: string;
  shortDesc: string;
  showLongDesc: boolean;
  onShowMorePress: () => void;
}

const Description: React.FC<IDescription> = ({
  longDesc,
  shortDesc,
  showLongDesc,
  onShowMorePress,
}) => {
  return IS_TABLET ? (
    <View style={styles.descriptionContainer}>
      <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{shortDesc}</MfText>
      <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{longDesc}</MfText>
    </View>
  ) : (
    <View style={styles.descriptionContainer}>
      <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{shortDesc}</MfText>
      <MfCollapsible collapsed={!showLongDesc}>
        <MfText textStyle={TEXT_STYLES.PLAIN_TEXT}>{longDesc}</MfText>
      </MfCollapsible>
      <Touchable onPress={onShowMorePress}>
        <MfText style={styles.showMore} textStyle={TEXT_STYLES.PLAIN_TEXT}>
          {getText(showLongDesc ? 'Visa mindre' : 'Visa mer')}
        </MfText>
      </Touchable>
    </View>
  );
};

export default Description;
