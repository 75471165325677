import React from 'react';
import { Component } from 'react';
import { View } from 'react-native';
import styles from './styles';
import Touchable from '../touchable';
import MfText, { TEXT_STYLES } from '../mf_text';
import MfImage from '../mf_image';
import ImageLoader from '../image_loader';

interface ICard {
  title: String;
  image: { uri: string };
  onPress: Function;
  small?: boolean;
}

class Card extends Component<ICard> {
  onPress() {
    this.props.onPress();
  }
  render() {
    const { small } = this.props;

    return (
      <View style={[styles.margin, styles.container]}>
        <Touchable onPress={() => this.onPress()}>
          <View
            style={[small ? styles.containerSmall : styles.containerStandard]}
          >
            <View
              style={small ? styles.imageContainerSMall : styles.imageContainer}
            >
              {this.props.image.uri !== '' ? (
                <MfImage
                  style={styles.image}
                  source={this.props.image.uri}
                  loader={<ImageLoader />}
                />
              ) : null}
            </View>
            <View style={styles.headerContainer}>
              <MfText
                style={[styles.headerText, small && styles.headerTextSmall]}
                textStyle={TEXT_STYLES.CARD_HEADER}
              >
                {this.props.title}
              </MfText>
            </View>
          </View>
        </Touchable>
      </View>
    );
  }
}

export default Card;
