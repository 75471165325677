import {StyleSheet, Platform} from 'react-native'
import { PRIMARY_THEME_COLORS, WINDOW_SIZE } from '../../../../config/style';

export default StyleSheet.create({
    container: {
        // height: '100%',
        // width: '100%',
        // overflow: 'hidden'
    },

    // varför påverkar inte detta webversion?
    contentContainer: {
        
        flex: 1,
    },
    // MarginTop: 60 behövs pga av navbar
    contentContainerWeb: {
        // marginTop: 60
    },
    actionButton: {
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList
    },
    buttonTablet: {
        height: 60,
        backgroundColor: PRIMARY_THEME_COLORS.shoppingList,
    },
    actionButtonLeft: {
        left: 30
    },
    tabletContainer: {
        flexDirection: 'row',
        height: '100%',
        width: '100%'
    },
    tabletLeft: {
        flex: 1,
        backgroundColor: 'white',
        borderRightColor: PRIMARY_THEME_COLORS.border,
        borderRightWidth: 1,
        shadowColor: PRIMARY_THEME_COLORS.shadowColor,
        shadowOpacity: 0.5,
        shadowRadius: 8,
        shadowOffset: {width: 0, height: 8},
        ...Platform.select({
            web: {
                boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.3)'
            },
            android: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.5,
                shadowRadius: 8,
                shadowOffset: {width: 0, height: 8},
                elevation: 3,
            },
            default: {
                shadowColor: PRIMARY_THEME_COLORS.shadowColor,
                shadowOpacity: 0.5,
                shadowRadius: 8,
                shadowOffset: {width: 0, height: 8}
            }
        }),
        zIndex: 10
    },
    tabletRight: {
        flex: 2
    },
    selectedListItem: {
        backgroundColor: PRIMARY_THEME_COLORS.lightGrey
    },
    emptyContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        margin: 20,
        padding: 20,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 1
    },
    emptyContainerTablet: {
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        margin: 20,
        padding: 20,
        borderRadius: 10,
        borderColor: 'grey',
        borderWidth: 1
    },
    deleteButton: {
        backgroundColor: PRIMARY_THEME_COLORS.red,
        borderRadius: 10,
        paddingLeft: 8
    }
})