import { PRIMARY_THEME_COLORS } from '../../config/style';
import { headerStyle, headerTintColor, headerTitleStyle } from '../styles';
import { IStack } from '../';
import routes from '../routes';
import PrivacyPolicyPage from '../../screens/privacy_policy';

const privacyPolicyStack: IStack = {
  [routes.privacyPolicy.name]: {
    screen: PrivacyPolicyPage,
    navigationOptions: {
      title: routes.privacyPolicy.title,
      webRoute: routes.privacyPolicy.route,
      headerBackTitle: null,
      headerTitleStyle: headerTitleStyle,
      headerTintColor: headerTintColor,
      headerStyle: {
        ...headerStyle,
        backgroundColor: PRIMARY_THEME_COLORS.recipes,
      },
    },
    isRoot: false,
    isStackRoot: true,
  },
};

export default privacyPolicyStack;
