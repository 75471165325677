import { Action, ActionCreator, Dispatch } from 'redux'
import fetch from 'cross-fetch'
import { Nutrition } from '../../model'
import { ThunkAction } from 'redux-thunk'
import { Types } from './types';
import { IState } from '../../model/state'
import * as config from '../../config';

interface ICheckAction extends Action {
    type: Types.CHECK_NUTRITION
}

interface IRequestAction extends Action {
    type: Types.REQUEST_NUTRITION
}

interface IReceiveAction extends Action {
    type: Types.RECIEVE_NUTRITION,
    nutritions: Nutrition[]
}

interface IErrorAction extends Action {
    type: Types.ERROR_NUTRITION
    error: Error
}


export type INutritionAction =  IRequestAction | IReceiveAction | IErrorAction | ICheckAction

const checkNutrition: ICheckAction = {
    type: Types.CHECK_NUTRITION
}

const requestNutritions: IRequestAction = {
    type: Types.REQUEST_NUTRITION
}

const receiveNutritions: ActionCreator<IReceiveAction> = (nutritions: Nutrition[]) => {
    return {
        type: Types.RECIEVE_NUTRITION,
        nutritions: nutritions
    }
}

const errorNutritions: ActionCreator<IErrorAction> = (error: Error) => {
    return {
        type: Types.ERROR_NUTRITION,
        error: error
    }
}


export function fetchNutritions(): ThunkAction<Promise<INutritionAction>, IState, undefined, INutritionAction> {
    // console.log("Fetching")
    return async (dispatch) => {
        dispatch(requestNutritions)
        try {
            const response = await fetch(config.NUTRITION_URL, { mode: 'cors' });
            const json = await response.json()
            return dispatch(receiveNutritions(json))
        } catch(error) {
            return dispatch(errorNutritions(error))
        }
    }
}

export function getNutritions(): ThunkAction<Promise<INutritionAction>, IState, undefined, INutritionAction> {
    // console.log("Getting categories")
    return async (dispatch, getState) => {
        const check = dispatch(checkNutrition)
        if ((!getState().nutritionState.isFetching && !getState().nutritionState.dataLoaded) || config.TESTING) {
            return dispatch(fetchNutritions())
        } else {
            return check
        }
    }
}

