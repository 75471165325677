import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  container: {
    // height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...Platform.select({
      web: {
        // height:
      },
      default: {},
    }),
  },

  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    margin: 20,
    padding: 20,
    borderRadius: 10,
    borderColor: 'grey',
    borderWidth: 1,
  },
  flatList: {
    alignItems: 'center',
    paddingVertical: 20,
  },
});
