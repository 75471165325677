import React, { Children } from 'react'
import {Component} from 'react'
import styles from './styles'
import {View, Animated} from 'react-native'
import {IMfModal} from './i_mf_modal'
import AnimatedOpacity from '../animated_opacity'
import './modal.css'
import { generateId } from '../../helpers/id'
import Modal from 'react-modal'

export enum animationTypes {
    NONE = 'none',
    SLIDE = 'slide',
    FADE = 'fade'
}

// Modal.setAppElement('#root')

Modal.setAppElement('#root')
const MfModal : React.FunctionComponent<IMfModal> = (props) => {
    const style = {
        content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: 'none',
            padding: 0,
            backgroundColor: 'rgba(0,0,0,0.5)'
        }
    }
    return (
        <Modal isOpen={props.visibile} onRequestClose={props.onClose} style={style}>
            <div className='mf_modal_css'>
                {props.children}
            </div>
        </Modal>
    )
} 

export default MfModal
